import React from 'react';
import { TypeaheadItems } from './TypeaheadItems';
import { basePaths } from 'app-constants';
import styled from 'styled-components';

function getTypes(typeUrl) {
  switch (typeUrl) {
    case basePaths.secondarySources:
      return [
        'SPELLING',
        'NGRAM',
        'ONLAW_OUTLINE',
        'ONLAW_SEARCH_CASE',
        'CODE_OUTLINE',
        'ONLAW_SEARCH_CODE_SECTION',
        'CASE_DOCUMENT',
        'CODE_SECTION_DOCUMENT',
      ];
    case basePaths.cases:
      return [
        'SPELLING',
        'NGRAM',
        'CASE_DOCUMENT',
        'ONLAW_SEARCH_CASE',
        'CODE_OUTLINE',
        'CODE_SECTION_CITATOR',
        'ONLAW_OUTLINE',
      ];
    case basePaths.statutes:
      return [
        'SPELLING',
        'NGRAM',
        'CODE_OUTLINE',
        'CODE_SECTION_DOCUMENT',
        'ONLAW_SEARCH_CODE_SECTION',
        'CODE_SECTION_CITATOR',
        'ONLAW_OUTLINE',
        'CASE_DOCUMENT',
      ];
    case basePaths.courtRules:
      return [
        'SPELLING',
        'NGRAM',
        'CODE_OUTLINE',
        'CODE_SECTION_DOCUMENT',
        'ONLAW_SEARCH_CODE_SECTION',
        'CODE_SECTION_CITATOR',
        'ONLAW_OUTLINE',
        'CASE_DOCUMENT',
      ];
    case basePaths.practitioner:
      return [
        'SPELLING',
        'NGRAM',
        'PRACTITIONER_DOCUMENT',
        'CODE_OUTLINE',
        'CASE_DOCUMENT',
        'CODE_SECTION_DOCUMENT',
      ];
    case basePaths.news:
      return [
        'SPELLING',
        'NGRAM',
        'CURRENT_AWARENESS',
        'CODE_OUTLINE',
        'CASE_DOCUMENT',
        'CODE_SECTION_DOCUMENT',
      ];
    case basePaths.learning:
      return [
        'SPELLING',
        'NGRAM',
        'COURSE',
        'COURSES_COLLECTION',
        'CODE_OUTLINE',
        'CASE_DOCUMENT',
        'CODE_SECTION_DOCUMENT',
      ];
    case basePaths.courses:
      return [
        'SPELLING',
        'NGRAM',
        'COURSE',
        'COURSES_COLLECTION',
        'CODE_OUTLINE',
        'CASE_DOCUMENT',
        'CODE_SECTION_DOCUMENT',
      ];
    case basePaths.collections:
      return [
        'SPELLING',
        'NGRAM',
        'COURSE',
        'COURSES_COLLECTION',
        'CODE_OUTLINE',
        'CASE_DOCUMENT',
        'CODE_SECTION_DOCUMENT',
      ];
    default:
      return [];
  }
}

const urlOverrides = {
  [basePaths.learning]: `${basePaths.learning}/courses`,
};

export const TypeaheadQuery = ({ query, type, onBlur, onClick, visible }) =>
  query ? (
    <StyledTypeaheadQuery>
      <React.Suspense fallback={<React.Fragment />}>
        <TypeaheadItems
          query={query}
          typeUrl={type}
          overrideUrl={urlOverrides[type]}
          types={getTypes(type)}
          visible={visible}
          onClick={onClick}
          onBlur={onBlur}
        />
      </React.Suspense>
    </StyledTypeaheadQuery>
  ) : null;

const StyledTypeaheadQuery = styled.div`
  left: -10px;
  position: absolute;
  top: 34px;
  z-index: 20;
`;
