import React from 'react';
import styled from 'styled-components';
import { CreateFolderButton } from './CreateFolderButton';

export function FolderMenuHeader({ folders, onCreate, children }) {
  return (
    <StyledFolderMenuHeader>
      {children && <h5>{children}</h5>}
      <CreateFolderButton folders={folders} onCreate={onCreate} />
    </StyledFolderMenuHeader>
  );
}

const StyledFolderMenuHeader = styled.header`
  margin-bottom: 6px;
  > h5 {
    padding: 15px 20px 12px;
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    font-weight: bold;
  }
`;
