import React from 'react';

function PrimaryLaw({ fill = '#185CAD' }) {
  return (
    <svg width="20px" height="18px" id="Layer_2" viewBox="0 0 20 18.67">
      <g id="Layer_1-2">
        <path
          className="cls-1"
          fill={fill}
          d="M10,0l10,4v1.33h-1.33c0,.18-.07,.34-.21,.47-.14,.13-.31,.2-.51,.2H2.05c-.19,0-.36-.07-.51-.2-.14-.13-.21-.29-.21-.47H0v-1.33L10,0Zm9.28,16.67c.19,0,.36,.07,.51,.2,.14,.13,.21,.29,.21,.47v1.33H0v-1.33c0-.18,.07-.34,.21-.47,.14-.13,.31-.2,.51-.2H19.28ZM2.67,6.67h2.67V14.67h1.33V6.67h2.67V14.67h1.33V6.67h2.67V14.67h1.33V6.67h2.67V14.67h.61c.19,0,.36,.07,.51,.2,.14,.13,.21,.29,.21,.47v.67H1.33v-.67c0-.18,.07-.34,.21-.47,.14-.13,.31-.2,.51-.2h.61V6.67Z"
        />
      </g>
    </svg>
  );
}

export default PrimaryLaw;
