import React from 'react';
import { HeaderActions } from 'components/common/HeaderActions';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const StatuteHeader = ({ title, subtitle, children, hideActions }) => {
  return (
    <StyledStatuteHeader>
      <section>
        <div className="header-content">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        {children}
      </section>
      {hideActions ? null : <HeaderActions />}
    </StyledStatuteHeader>
  );
};

const StyledStatuteHeader = styled.header`
  background-color: ${CEB_COLOR('DESERT_STORM')};
  border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 0 0 auto;
  padding: 19px 22px 0;
  section {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  div.header-content {
    flex: 1 1 auto;
    display: flex;
    margin-bottom: 8px;
    h1 {
      font-size: 23px;
      line-height: 1;
    }
    h2 {
      margin-left: 0.22em;
      line-height: 1;
      font-size: 23px;
      font-weight: normal;
    }
  }

  @media only print {
    display: none;
  }
`;
