import React from 'react';
import { useWrappedIntercom } from './useWrappedIntercom';
import { AppContext } from '../app-context/index';

export default function UpdateIntercomUser() {
  const intercom = useWrappedIntercom();
  const { user } = React.useContext(AppContext);

  React.useEffect(() => {
    intercom.update({
      email: user ? user.email : undefined,
      name: user ? user.fullName : undefined,
      engagement_level: user ? user.engagementLevel : undefined,
      purchased_product_types: user ? user.productTypesPurchased : undefined,
      purchased_practice_areas: user
        ? user.productPracticeAreasPurchased
        : undefined,
      secondary_practice_areas: user
        ? user.secondaryPracticeAreas?.selected
        : undefined,
      organization_type: user ? user.organizationType?.value : undefined,
      user_role: user ? user.userRole?.value : undefined,
    });
  }, [user, intercom]);

  return null;
}
