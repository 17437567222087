import React from 'react';
import Loading from 'components/common/Loading';
import Header from 'components/header';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export const PreviewLayout = ({ hideNav, showFooter, ...props }) => {
  return (
    <Loading>
      <StyledPreviewLayout className="preview-layout">
        <Header hideNav={true}>
          <PreviewHeaderContent />
        </Header>
        {props.children}
      </StyledPreviewLayout>
    </Loading>
  );
};

const StyledPreviewLayout = styled.div`
  width: 100%;

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    > div {
      display: flex;
      flex: 1 0 auto;
    }
  }
`;

const PreviewHeaderContent = () => {
  return (
    <StyledPreviewHeaderContent>
      <ul>
        <li>Continuing Education of the Bar</li>
        <li>California</li>
      </ul>
      <div>
        Already a member? <Link to="/login">Log In</Link>{' '}
        <Link className="button" to="/register">
          Join Free
        </Link>
      </div>
    </StyledPreviewHeaderContent>
  );
};

const StyledPreviewHeaderContent = styled.section`
  background-color: ${CEB_COLOR('CEB_HEADER_BLUE')};

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
    display: flex;
    flex: 1 1 auto;
    background: transparent;
  }

  ul,
  div {
    padding: 10px 20px;
    @media screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
      flex: 1 1 auto;
      padding: 0;
    }
  }
  div {
    flex: 0 1 auto;
    a,
    a:visited {
      font-weight: bold;
      color: white;
    }
    .button {
      background: ${CEB_COLOR('DANUBE')};
      border-radius: 3px;
      display: inline-block;
      font-size: 15px;
      font-weight: normal;
      font-family: 'BasisGrotesque', sans-serif;
      margin: 11px 24px 10px 20px;
      padding: 12px 20px 11px;
      &:visited {
        color: inherit;
      }
      &:focus {
        border-bottom: none;
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.75);
      }
    }
  }
  ul {
    list-style-type: none;
    margin-top: 0;
    @media screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
      margin-top: 20px;
    }
    li {
      display: inline-block;
      + li {
        margin-left: 5px;
        color: rgba(255, 255, 255, 0.7);
        &:before {
          content: '•';
          color: ${CEB_COLOR('SAFFRON')};
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
`;

export default PreviewLayout;
