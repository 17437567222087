import React from 'react';
import { useRoutes } from 'react-router';
import FrameLayout from 'components/layouts/FrameLayout';
import { basePaths, CEB_ONLAW_URL } from 'app-constants';
import { Show404 } from './Show404';
import Loading from 'components/common/Loading';
import mapRoutesForUse from './mapRoutesForUse';
import { ResearchLayout } from 'components/layouts/research-layout';
import { PublicTwentyThree } from 'components/layouts/public';
import { RedirectWithState } from './RedirectWithState';

export const routes = [
  {
    path: '/',
    componentImport: () => import('components/daily-news/Landing'),
    layoutName: 'research-layout/index',
    layoutProps: { showFooter: false, hideNav: true },
    requireAuth: true,
  },
  {
    path: '/news/search',
    componentImport: () => import('components/daily-news/DailyNewsSearch'),
    layoutName: 'research-layout/index',
    layoutProps: { showFooter: false, hideNav: true },
    requireAuth: true,
  },
  {
    path: '/news/*',
    componentImport: () => import('components/daily-news/index'),
    layoutName: 'research-layout/index',
    layoutProps: { showFooter: false, hideNav: true },
    requireAuth: true,
  },
  {
    path: '/climatebrief/*',
    componentImport: () => import('components/climatebrief/index'),
    layoutName: 'research-layout/index',
    requireAuth: true,
  },
  {
    path: '/login',
    children: [
      {
        path: '',
        componentImport: () => import('components/login/index'),
        componentProps: { title: 'Login' },
      },
      {
        path: 'interstitial',
        componentImport: () => import('components/login/interstitial/index'),
        requireAuth: true,
      },
      {
        path: 'interstitial/update',
        componentImport: () =>
          import('components/login/login-email-update/index'),
        requireAuth: true,
      },
    ],
  },
  {
    path: '/forgotpassword',
    componentImport: () => import('components/login/forgot-password/index'),
  },
  {
    path: '/resetpassword',
    children: [
      {
        path: ':token',
        componentImport: () => import('components/login/reset-password/index'),
      },
      {
        path: '',
        componentImport: () => import('components/login/reset-password/index'),
        requireAuth: true,
      },
    ],
  },
  {
    path: '/updateaccount',
    componentImport: () => import('components/login/update-account-info/index'),
    componentProps: { title: 'Update Account Info' },
  },
  {
    path: '/register/*',
    componentImport: () => import('components/register/index'),
    componentProps: { title: 'Register' },
  },
  {
    path: '/ipAccess',
    componentImport: () => import('components/ipaccess/index'),
  },
  {
    path: '/checkout/academic',
    componentImport: () => import('components/checkout/academic/index'),
  },
  {
    path: '/checkout/:slug/*',
    componentImport: () => import('components/checkout/index'),
  },
  {
    path: '/invite',
    componentImport: () => import('components/invite/index'),
    componentProps: { title: 'Invite' },
  },
  {
    path: '/home',
    element: <RedirectWithState to="/" replace />,
  },
  {
    path: '/research/:section',
    componentImport: () => import('components/research-redirector/index'),
  },
  {
    path: '/onlaw',
    element: <FrameLayout src={`${CEB_ONLAW_URL}`} />,
    requireAuth: true,
  },

  {
    path: '/learning/*',
    componentImport: () => import('components/learning/index'),
    layoutName: 'research-layout/index',
    layoutProps: { showFooter: true },
    requireAuth: { noIpSessions: true },
  },
  {
    path: '/learning/print/course-cert/:salesForceId',
    componentImport: () =>
      import('components/learning/certification-of-completion/index'),
    layoutName: 'HeadlessLayout',
    requireAuth: true,
  },
  {
    path: '/account/*',
    componentImport: () => import('components/account/index'),
    layoutName: 'research-layout/index',
    requireAuth: { noIpSessions: true },
  },
  {
    path: '/raw',
    componentImport: () => import('components/layouts/RawLayout'),
    children: [
      {
        path: 'account/invoice/firm/:invoiceId',
        componentImport: () =>
          import('components/account/billing/InvoiceDetails'),
        componentProps: { firm: true },
        requireAuth: true,
      },
      {
        path: 'account/invoice/:invoiceId',
        componentImport: () =>
          import('components/account/billing/InvoiceDetails'),
        requireAuth: true,
      },
      {
        path: 'learning/courses/:slug/play',
        componentImport: () =>
          import('components/learning/cle-courses/CoursePlayer'),
        requireAuth: true,
      },
      {
        path: 'primary-law',
        children: [
          {
            path: 'cases/*',
            componentImport: () => import('components/cases/index'),
          },
          {
            path: 'statutes/*',
            componentImport: () => import('components/statutes/index'),
            componentProps: { title: 'Browse Statutes' },
          },
          {
            path: 'rules/*',
            componentImport: () => import('components/court-rules/index'),
            componentProps: { title: 'Browse Rules of Court' },
          },
        ],
      },
    ],
  },
  {
    path: '/secondary-sources/*',
    requireAuth: { allowPreview: true, renderIn: ResearchLayout },
    componentImport: () => import('components/secondary-sources/index'),
    componentProps: { title: 'Browse Secondary Sources' },
  },
  {
    path: '/secondary-sources/table-of-contents/:productCode',
    componentImport: () =>
      import('components/secondary-sources/table-of-contents-route/index'),
    layoutName: 'RawLayout',
    layoutProps: { noAssets: true },
  },
  {
    path: '/primary-law',
    element: <RedirectWithState to={basePaths.cases} replace />,
  },
  {
    path: '/primary-law/cases/search',
    componentImport: () => import('components/cases/case/case-search/index'),
    layoutName: 'research-layout/index',
    requireAuth: true,
  },
  {
    path: '/primary-law/cases/*',
    componentImport: () => import('components/cases/index'),
    layoutName: 'research-layout/index',
    requireAuth: true,
  },
  {
    path: '/primary-law/statutes/*',
    componentImport: () => import('components/statutes/index'),
    componentProps: { title: 'Browse Statutes' },
    layoutName: 'research-layout/index',
    requireAuth: true,
  },
  {
    path: '/primary-law/rules/*',
    componentImport: () => import('components/court-rules/index'),
    componentProps: { title: 'Browse Rules of Court' },
    layoutName: 'research-layout/index',
    requireAuth: true,
  },
  {
    path: '/practitioner/*',
    requireAuth: {
      allowPreview: true,
      renderIn: ResearchLayout,
      previewLayout: PublicTwentyThree,
    },
    componentImport: () => import('components/practitioner/index'),
    componentProps: { title: 'Practitioner' },
  },
  {
    path: '/purchase',
    componentImport: () => import('components/purchase-route/index'),
    componentProps: { title: 'Purchase' },
    layoutName: 'research-layout/index',
    layoutProps: { hideNav: true },
    requireAuth: true,
  },
  {
    path: '/support/*',
    layoutName: 'research-layout/index',
    componentProps: { hideNav: true },
    requireAuth: true,
    componentImport: () => import('components/support/index'),
  },
  {
    path: '/court-forms/:slug',
    layoutName: 'research-layout/index',
    componentImport: () => import('components/court-forms/redirect'),
  },
  {
    path: '/law-alert',
    layoutName: 'research-layout/index',
    componentProps: { showFooter: true },
    requireAuth: true,
    children: [
      {
        path: ':slug',
        componentImport: () => import('components/law-alert/redirect'),
      },
      {
        path: '',
        element: <RedirectWithState to="/news" replace />,
      },
    ],
  },
  {
    path: '/posts/*',
    componentImport: () => import('components/posts/index'),
    layoutName: 'research-layout/index',
    layoutProps: { hideNav: true },
  },
  {
    path: '/logout',
    componentImport: () => import('components/logout/index'),
  },
  {
    path: '/matters/*',
    componentImport: () => import('components/matters/index'),
    componentProps: { title: 'Client Matters' },
    layoutName: 'research-layout/index',
    layoutProps: {},
    requireAuth: true,
  },
  {
    path: '/documents_and_forms/*',
    componentImport: () => import('components/docs-and-forms/index'),
    componentProps: { title: 'Documents & Forms' },
    layoutName: 'research-layout/index',
    layoutProps: {},
    requireAuth: true,
  },
  {
    path: '*',
    componentImport: () => import('components/not-found/index'),
    layoutName: 'research-layout/index',
    layoutProps: { hideNav: true, showFooter: true },
  },
];

const mappedRoutes = mapRoutesForUse(routes);

export default function Routes() {
  const element = useRoutes(mappedRoutes);
  return (
    <Show404>
      <Loading>{element}</Loading>
    </Show404>
  );
}
