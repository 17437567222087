import React from 'react';
const path = 'var(--COLOR_LUCKY_POINT)';

const SignOut = ({ height = 64, width = 64, ...props }) => (
  <svg
    height={height}
    width={width}
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16.33"
  >
    <g id="Layer_1-2">
      <path
        fill={props.path}
        d="M8.16,15.1s0,.12,.01,.26,.01,.25,0,.34c0,.09-.02,.19-.04,.3-.02,.11-.06,.19-.13,.25-.06,.05-.15,.08-.26,.08H3.67c-1.01,0-1.88-.36-2.6-1.08-.72-.72-1.08-1.58-1.08-2.6V3.67C0,2.66,.36,1.8,1.08,1.08,1.8,.36,2.66,0,3.67,0H7.75c.11,0,.21,.04,.29,.12s.12,.18,.12,.29c0,.03,0,.12,.01,.25s.01,.25,0,.34c0,.09-.02,.19-.04,.3-.02,.11-.06,.19-.13,.25-.06,.06-.15,.08-.26,.08H3.67c-.56,0-1.04,.2-1.44,.6s-.6,.88-.6,1.44V12.65c0,.56,.2,1.04,.6,1.44s.88,.6,1.44,.6h3.98s.07,0,.15,.01c.07,0,.12,.02,.15,.04,.03,.02,.06,.04,.1,.07,.04,.03,.07,.07,.09,.11,.02,.05,.03,.1,.03,.17Zm11.84-6.94c0,.22-.08,.41-.24,.57l-6.94,6.94c-.16,.16-.35,.24-.57,.24s-.41-.08-.57-.24-.24-.35-.24-.57v-3.67H5.71c-.22,0-.41-.08-.57-.24-.16-.16-.24-.35-.24-.57V5.71c0-.22,.08-.41,.24-.57,.16-.16,.35-.24,.57-.24h5.71V1.22c0-.22,.08-.41,.24-.57s.35-.24,.57-.24,.41,.08,.57,.24l6.94,6.94c.16,.16,.24,.35,.24,.57Z"
      />
    </g>
  </svg>
);

SignOut.defaultProps = {
  path,
};

export default SignOut;
