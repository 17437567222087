import React from 'react';
import { useLocation } from 'react-router';
import { TabContext } from 'components/app-context/TabContextProvider';
import { getLocationFromPath } from 'util/getLocationFromPath';
import { intersectionBy } from 'lodash/fp';

export function TabFilterer() {
  const location = useLocation();
  const { tabs } = location.state?.tabState || {};
  const { dispatch } = React.useContext(TabContext);

  React.useEffect(() => {
    removeCurrentFromTabs(tabs, location, dispatch);
    removeClosedFromTabs(tabs, dispatch);
  }, [tabs, dispatch, location.pathname]);

  React.useEffect(() => {
    const matchingTabs =
      tabs?.filter(({ uri }) => {
        const { pathname } = getLocationFromPath(uri);

        return pathname === location.pathname;
      }) || [];
    matchingTabs.map(tab => dispatch({ type: 'REMOVE_TAB', tab }));
  }, [tabs, dispatch, location.pathname]);

  return null;
}

function removeClosedFromTabs(tabs, dispatch) {
  const recentlyClosedTabs = JSON.parse(
    sessionStorage.getItem('RECENTLY_CLOSED_TABS') || '[]',
  );
  const matchingTabs = intersectionBy(JSON.stringify)(recentlyClosedTabs)(tabs);
  matchingTabs.map(tab => dispatch({ type: 'REMOVE_TAB', tab }));
}

function removeCurrentFromTabs(tabs, location, dispatch) {
  const matchingTabs =
    tabs?.filter(({ uri }) => {
      const { pathname } = getLocationFromPath(uri);
      return pathname === location.pathname;
    }) || [];
  matchingTabs.map(tab => dispatch({ type: 'REMOVE_TAB', tab }));
}
