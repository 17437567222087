import React, { useCallback } from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { LoggedOutNav } from './LoggedOutNav';
import { LoggedInNav } from './LoggedInNav';
import { LoggedIn } from './LoggedIn';
import { AppContext } from 'components/app-context';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { useNavigate } from 'react-router';

/**
 * Header Stateless Component
 */

export interface HeaderProps {
  children?: React.ReactNode;
  hideNav?: boolean;
  hideHistoryAndBookmarks?: boolean;
}

const Header = ({
  children,
  hideNav,
  hideHistoryAndBookmarks,
}: HeaderProps) => {
  const navigate = useNavigate();
  const logout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);
  const { localUser } = React.useContext(AppContext);

  return (
    <StyledHeader>
      <nav role="navigation">
        <Link className="logo-link" to="/">
          &nbsp;
        </Link>
        <div className="header-nav">
          {children ? (
            <>{children}</>
          ) : localUser?.authorized ? (
            <>
              {hideNav ? null : <LoggedInNav />}
              <LoggedIn
                logout={logout}
                hideHistoryAndBookmarks={hideHistoryAndBookmarks}
              />
            </>
          ) : (
            <LoggedOutNav />
          )}
        </div>
      </nav>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background: ${CEB_COLOR('CARARRA')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  min-height: 60px;
  padding: 0;
  position: relative;
  z-index: 20;

  > nav {
    background: ${CEB_COLOR('CEB_HEADER_BLUE')};
    color: #fff;
    display: flex;
    flex: 1 1 auto;
    height: 60px;

    .logo-link {
      flex: 1 1 auto;
      max-width: 173px;
    }

    .header-nav {
      display: flex;
      flex: 1 1 auto;
    }
  }

  .logo-link {
    background: url('/images/ceb-logo-white-2024.svg') no-repeat center center;
    background-size: auto 36px;
    display: block;
    height: 60px;
    margin-left: 24px;
    margin-right: 28px;
    width: 173px;
    &:focus {
      outline: 1px solid ${CEB_COLOR_RGBA('DANUBE', 0.6)};
    }
  }

  .header-nav {
    overflow: visible;
  }

  @media only print {
    display: none;
  }
`;

export default Header;
