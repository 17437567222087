import React, { UIEvent } from 'react';
import debounce from 'lodash/fp/debounce';
import { ScrollCacheContext } from 'components/app/ScrollCache';

export interface ScrollPanelContextValue {
  resetScrollPosition?: () => void;
  restoreScrollPosition?: () => void;
}
export const ScrollPanelContext = React.createContext<ScrollPanelContextValue>(
  {},
);

export interface RestorableScrollProps
  extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  onScroll?: any;
  exclude?: boolean;
  as?: string;
  scrollId?: string;
  id?: string;
}

export const RestorableScroll = React.forwardRef<
  HTMLElement,
  RestorableScrollProps
>(
  (
    {
      children,
      onScroll,
      exclude,
      as = 'section',
      scrollId = 'restorable-scroll',
      ...props
    },
    forwardedRef,
  ) => {
    const [scrollRestorerEl, setScrollRestorerEl] =
      React.useState<HTMLElement>();

    const selector = `[data-scroll-id="${props.id ? props.id : scrollId}"]`;

    const { setScrollCache, getScrollCache } =
      React.useContext(ScrollCacheContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cacheScrollPosition = React.useCallback<
      (event?: UIEvent<HTMLDivElement>) => void
    >(
      debounce(50)(() => {
        if (scrollRestorerEl) {
          setScrollCache!(selector, scrollRestorerEl.scrollTop);
        }
      }),
      [setScrollCache, selector, scrollRestorerEl],
    );

    const handleScroll = React.useCallback<
      (event: UIEvent<HTMLDivElement>) => void
    >(
      event => {
        cacheScrollPosition(event);

        onScroll && onScroll(event);
      },
      [onScroll, cacheScrollPosition],
    );

    const restoreScrollPosition = React.useCallback(() => {
      const scrollTop = getScrollCache!(selector);
      if (scrollRestorerEl) {
        scrollRestorerEl.scrollTop = scrollTop;
      }
    }, [getScrollCache, selector, scrollRestorerEl]);

    const resetScrollPosition = React.useCallback(() => {
      if (scrollRestorerEl) {
        scrollRestorerEl.scrollTop = 0;
      }
      cacheScrollPosition();
    }, [cacheScrollPosition, scrollRestorerEl]);

    const setRef = React.useCallback(
      (ref: HTMLElement) => {
        setScrollRestorerEl(ref);
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') {
            forwardedRef(ref);
          } else {
            forwardedRef.current = ref;
          }
        }
        return ref;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setScrollRestorerEl],
    );

    return (
      <ScrollPanelContext.Provider
        value={{
          resetScrollPosition,
          restoreScrollPosition,
        }}
      >
        {React.createElement(
          as,
          {
            ...props,
            ref: setRef,
            'data-scroll-id': props.id ? props.id : scrollId,
            onScroll: handleScroll,
          },
          children,
        )}
      </ScrollPanelContext.Provider>
    );
  },
);

RestorableScroll.displayName = 'RestorableScroll';
