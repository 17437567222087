import React from 'react';
import { NavigationType } from 'react-router';
import { ScrollCacheContext } from 'components/app/ScrollCache';
import { ScrollPanelContext } from './RestorableScroll';

export function ResetRestoreScroll() {
  const context = React.useContext(ScrollCacheContext);

  switch (context?.navState?.type) {
    case NavigationType.Push:
      return <ResetScroll />;
    case NavigationType.Pop:
      return <RestoreScroll />;
    default:
      return null;
  }
}

function RestoreScroll() {
  const { restoreScrollPosition } = React.useContext(ScrollPanelContext);
  React.useLayoutEffect(() => {
    restoreScrollPosition();
  }, [restoreScrollPosition]);

  return null;
}

function ResetScroll() {
  const { resetScrollPosition } = React.useContext(ScrollPanelContext);
  React.useLayoutEffect(() => {
    resetScrollPosition();
  }, [resetScrollPosition]);

  return null;
}
