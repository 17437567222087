import { gql } from '@apollo/client';

const CREATE_BOOKMARK_FOLDER = gql`
  mutation createBookmarkFolder($name: String!) {
    createBookmarkFolder(name: $name) {
      id
      name
    }
  }
`;

export default CREATE_BOOKMARK_FOLDER;
