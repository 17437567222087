import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { SearchContext } from 'components/layouts/research-layout/SearchContext';
import { PillButtonDropdown } from 'components/common/pill-button-dropdown';
import caseUrl, { caseCitatorUrl } from 'util/caseUrl';
import styled from 'styled-components';
import { TrueCiteLogo } from 'components/common/TrueCiteLogo';
import { TrueCiteHelpModalButton } from 'components/common/TrueCiteHelpModalButton';
import qs from 'util/qs';

function getExampleTreatingCaseUrl(exampleTreatingCase, queryString) {
  const { judicataId: originalJudicataID } = exampleTreatingCase;
  const [judicataId, originalQs] = originalJudicataID.split('?');
  const caseUrlBase = caseUrl(judicataId);
  const parsed = originalQs ? qs.parse(originalQs) : {};
  const search = qs.stringify({ ...(queryString || {}), ...parsed });
  return Object.keys(search).length > 0
    ? `${caseUrlBase}?${search}`
    : caseUrlBase;
}

export const CasePillButton = ({
  case: {
    judicataId,
    treatmentAttitudeName,
    treatmentDescription,
    citationCount,
    lastCitedOn,
    exampleTreatingCase,
  },
}) => {
  const { queryString } = React.useContext(SearchContext);
  const exampleTreatingCaseUrl = exampleTreatingCase
    ? getExampleTreatingCaseUrl(exampleTreatingCase, queryString)
    : null;
  const citatorUrl = caseCitatorUrl(judicataId);
  return (
    <PillButtonDropdown
      title={treatmentDescription}
      attitude={treatmentAttitudeName}
      count={citationCount}
    >
      <StyledPillButtonContent>
        <dl>
          {exampleTreatingCase ? (
            <>
              <dt>{exampleTreatingCase.treatmentDescription}:</dt>
              <dd>
                <Link to={exampleTreatingCaseUrl}>
                  {exampleTreatingCase.name}
                </Link>
              </dd>
            </>
          ) : null}
          <dt>Citations:</dt>
          <dd>
            <Link to={citatorUrl}>{citationCount} cases</Link>
          </dd>
          {lastCitedOn ? (
            <>
              <dt>Last Cited:</dt>
              <dd>{lastCitedOn}</dd>
            </>
          ) : null}
        </dl>
        <div className="true-cite-badge">
          <TrueCiteLogo />
          <TrueCiteHelpModalButton />
        </div>
      </StyledPillButtonContent>
    </PillButtonDropdown>
  );
};

export const StyledPillButtonContent = styled.div`
  .true-cite-badge {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
  dl {
    margin: 0;
  }
  dt,
  dd {
    display: inline-block;
    float: left;
  }

  dt {
    clear: left;
    margin-right: 0.25em;
    font-weight: bold;
  }

  dd {
    margin: 0;
    font-weight: normal;
    a {
      font-weight: bold;
    }
  }
`;
