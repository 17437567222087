import React from 'react';
import { CopyPanel } from './CopyPanel';
import { getSelectionData } from 'util/cite/getSelectionData';
import { LINE_SEP } from 'util/cite/';
import { getCitation } from 'util/getCitation';
import { finder } from 'util/finder';
import { LayoutContext } from 'components/layouts/LayoutContext';

async function setClipboard(text: string, html: string) {
  const data = [
    new ClipboardItem({
      'text/plain': new Blob([text], { type: 'text/plain' }),
      'text/html': new Blob([html], { type: 'text/html' }),
    }),
  ];

  await navigator.clipboard.write(data);
}

interface CitableDocumentProps extends React.HTMLProps<HTMLDivElement> {
  contentRef: React.RefObject<HTMLElement>;
  selectableSelector: string;
  unspannableSelector: string;
  unspannableParent: string;
  californiaCitationFormat: string[];
  standardCitationFormat: string[];
  citeSearch: string;
  citeReplace: string;
}

export const CitableDocument = ({
  className,
  children,
  contentRef,
  ...props
}: CitableDocumentProps) => {
  const citableRef = React.useRef<HTMLDivElement>(null);
  const [showCopy, setShowCopy] = React.useState(false);
  const [copyOffset, setCopyOffset] = React.useState(0);
  const [selection, setSelection] = React.useState<Selection | null>(null);

  const { raw } = React.useContext(LayoutContext);

  const onContentSelect = React.useCallback(() => {
    if (raw) return;
    const sel = document.getSelection();
    setSelection(sel);
    if (!sel || !sel.anchorNode) return;

    if (sel.focusNode) {
      const reverse =
        sel.focusNode.compareDocumentPosition(sel.anchorNode) &
        Node.DOCUMENT_POSITION_FOLLOWING;

      const focusNode =
        !reverse && sel.focusNode.nodeType !== 3 && sel.focusOffset === 0
          ? sel.focusNode.previousSibling
          : sel.focusNode.parentNode;

      if (focusNode === citableRef.current) {
        sel.modify('extend', 'backward', 'character');
      }

      if (
        isDescendant(citableRef.current!, sel.anchorNode as HTMLElement) &&
        isDescendant(citableRef.current!, focusNode as HTMLElement)
      ) {
        if (sel.toString()) {
          setCopyOffset((focusNode as HTMLElement)?.offsetTop);
          setShowCopy(true);
        } else {
          setShowCopy(false);
        }
      } else {
        setShowCopy(false);
      }
    }
  }, [raw]);

  const onCopyClick = React.useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      format: 'standard' | 'california',
    ) => {
      e.preventDefault();
      const selection = document.getSelection();
      const container = document.createElement('div');
      if (selection) {
        for (const el of Array(selection.rangeCount)
          .fill(null)
          .map((_, i) => selection.getRangeAt(i).cloneContents())) {
          container.appendChild(el);
        }
        finder(container)('mark').map(unwrapNode);
        const selectionData = getSelectionData();

        const citation = getCitation(
          props[`${format}CitationFormat`],
          format,
          selectionData,
          props.citeSearch,
          props.citeReplace,
        );

        const text = `${selection.toString()}\n\n${citation.text}`;

        const html = `${container.innerHTML.replace(
          /<a\s+([^>]*)href="[^"]*"([^>]*)>/gi,
          '<a $1$2>',
        )}<br/><br/>${citation.html}`;

        setClipboard(
          text.replace(/\n/g, LINE_SEP),
          html.replace(/\n/g, '<br />'),
        );
      }
    },
    [props],
  );

  useSelectionChange(onContentSelect);

  const onMouseUp = React.useCallback((e: MouseEvent) => {
    setTimeout(() => {
      if (
        document.getSelection()?.type !== 'Range' &&
        citableRef?.current?.contains(e.target as HTMLDivElement)
      ) {
        setShowCopy(false);
      }
    }, 0);
  }, []);

  React.useLayoutEffect(() => {
    if (!raw) document.addEventListener('mouseup', onMouseUp);
    return () => {
      if (!raw) document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseUp, raw]);

  return (
    <div className={className || 'citable-document'} ref={citableRef}>
      {showCopy ? (
        <CopyPanel
          top={copyOffset}
          onCopyClick={onCopyClick}
          selection={selection}
          selectableSelector={props.selectableSelector}
          unspannableSelector={props.unspannableSelector}
          unspannableParent={props.unspannableParent}
          containerSelector={`.${className || 'citable-document'}`}
        />
      ) : null}
      {children}
    </div>
  );
};

function useSelectionChange(
  onContentSelect: (this: Document, event: Event) => any,
) {
  React.useLayoutEffect(() => {
    document.addEventListener('selectionchange', onContentSelect);
    return () => {
      document.removeEventListener('selectionchange', onContentSelect);
    };
  }, [onContentSelect]);
}

function isDescendant(parent: HTMLElement, child: HTMLElement) {
  if (!child) {
    return false;
  }
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

const unwrapNode = function (node: HTMLElement) {
  [...node.childNodes].map(content =>
    node.parentNode?.insertBefore(content, node),
  );
  node.parentNode?.removeChild(node);
  return node;
};
