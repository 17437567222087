import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export default function getFriendlyDate(timestamp, format = 'dddd, MMMM D') {
  const dj = !timestamp.isDayjs ? dayjs(timestamp) : timestamp;

  return dj.isToday()
    ? 'Today'
    : dj.isYesterday()
    ? 'Yesterday'
    : dj.format(format);
}
