import React from 'react';
import { BookmarkPanelContext } from './';
import { TYPE_MAP } from './BookmarkFolder';
import { MoveBookmark } from './MoveBookmark';
import { DeleteBookmark } from './DeleteBookmark';
import classSet from 'react-classset';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { useNavigateWithState } from 'components/routes/RedirectWithState';

function activeToolitemReducer(state, action) {
  switch (action.type) {
    case 'OPEN_DELETE':
      return { move: false, delete: true };
    case 'OPEN_MOVE':
      return { move: true, delete: false };
    default:
      return { move: false, delete: false };
  }
  return state;
}

export function BookmarkListItem({ bookmark, folders, folderId }) {
  const { close } = React.useContext(BookmarkPanelContext);
  const navigate = useNavigateWithState();
  const handleClick = React.useCallback(
    e => {
      close(e);
      navigate(bookmark?.path);
    },
    [navigate, bookmark?.path],
  );

  const [toolitemState, dispatch] = React.useReducer(activeToolitemReducer, {
    move: false,
    delete: false,
  });

  const toolsetActive = Object.values(toolitemState).filter(Boolean).length > 0;

  const type = TYPE_MAP[bookmark.type];

  return (
    <StyledBookmarkListItem className={classSet({ toolsetActive })}>
      <header>
        <section>
          <ul>
            <li>
              <MoveBookmark
                bookmark={bookmark}
                folderId={folderId}
                folders={folders}
                onToggle={isOpen => {
                  dispatch({ type: isOpen ? 'OPEN_MOVE' : 'CLOSE' });
                }}
                open={toolitemState.move}
              />
            </li>
            <li>
              <DeleteBookmark
                bookmark={bookmark}
                folderId={folderId}
                open={toolitemState.delete}
                onToggle={isOpen => {
                  dispatch({ type: isOpen ? 'OPEN_DELETE' : 'CLOSE' });
                }}
              />
            </li>
          </ul>
        </section>
        <section>
          <h3>{bookmark.subtype || type.name}</h3>
        </section>
      </header>
      <Link to={bookmark.path} onClick={handleClick}>
        {bookmark.title}
      </Link>
      <p>{bookmark.text.description}</p>
    </StyledBookmarkListItem>
  );
}

const StyledBookmarkListItem = styled.div`
  margin-bottom: 18px;
  &.toolsetActive > header,
  &:hover > header {
    section:first-child {
      display: block;
    }
  }

  > header {
    display: flex;
    section:first-child {
      display: none;
    }

    > section > ul {
      list-style-type: none;
      &,
      > li {
        margin: 0;
        padding: 0;
      }
      > li {
        display: inline-block;
        position: relative;
        i {
          font-weight: normal;
        }
        .move-bookmark {
          .select {
            margin: 0;
            line-height: inherit;
          }
        }
        + li {
          margin-left: 10px;
        }
      }
    }

    > section {
      flex: 1 1 auto;
      &:first-child {
        flex: 0 1 auto;
        margin-right: 18px;
      }
    }
  }

  > a,
  > a:visited {
    color: ${CEB_COLOR('NICE_BLUE')};
    display: block;
    font-family: 'Zilla Slab', serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  > p {
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    font-weight: normal;
  }

  h3 {
    color: #343434;
    font-family: 'Zilla Slab', serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.83;
    opacity: 0.4;
    text-transform: uppercase;
  }
`;
