import { gql } from '@apollo/client';

const UPDATE_BOOKMARK_FOLDER = gql`
  mutation updateBookmarkFolder($id: ID!, $name: String!) {
    updateBookmarkFolder(id: $id, name: $name) {
      id
    }
  }
`;
export default UPDATE_BOOKMARK_FOLDER;
