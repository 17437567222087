import { findParents } from 'util/finder';

export function elementIsInView(element) {
  if (!element) return false;
  return elementIsInViewport(element) && elementIsScrolledIntoView(element);
}

export function elementIsInViewport(element) {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function elementIsScrolledIntoView(element) {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  const scrollableParentRects = findParents(element)('*')
    .filter(el => el.scrollHeight > el.clientHeight)
    .map(el => el.getBoundingClientRect());
  return (
    scrollableParentRects.reduce(
      (inView, parentRect) => inView && rect.top >= parentRect.top,
      true,
    ) &&
    scrollableParentRects.reduce(
      (inView, parentRect) => inView && rect.bottom <= parentRect.bottom,
      true,
    )
  );
}
