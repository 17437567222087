import { basePaths } from 'app-constants';

let prefix;

export const setStatuteUrlPrefix = slug => {
  prefix = slug;
};

export const statuteURL = (
  jurisdictionId,
  codeJudicataId,
  sectionBegin,
  sectionEnd,
) =>
  jurisdictionId &&
  [
    prefix,
    basePaths.statutes.replace(/^\//, ''),
    jurisdictionId,
    'codes',
    codeJudicataId,
    sectionBegin,
    sectionEnd,
  ]
    .join('/')
    .replace(/\/+$/, '');

export const constURL = (article = '', section = '') =>
  [
    prefix,
    basePaths.statutes.replace(/^\//, ''),
    'ca',
    'const',
    article,
    section,
  ]
    .join('/')
    .replace(/\/+$/, '');

export const getConstitutionLinkForNode = ({
  jurisdictionId,
  codeJudicataId,
  article,
  judicataId,
}) =>
  [
    prefix,
    basePaths.statutes.replace(/^\//, ''),
    jurisdictionId,
    codeJudicataId,
    article,
    judicataId,
  ]
    .join('/')
    .replace(/\/+$/, '');

export const getConstitutionUri = el => {
  const {
    statuteJdx,
    statuteCode,
    statuteArticle,
    statuteSection,
  } = el.dataset;

  return [
    prefix,
    basePaths.statutes.replace(/^\//, ''),
    statuteJdx,
    statuteCode,
    statuteArticle,
    statuteSection,
  ]
    .join('/')
    .replace(/\/+$/, '');
};
