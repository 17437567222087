import styled from 'styled-components';

export const StyledCitingPillPrint = styled.span`
  background: white;
  border-radius: 1.5em;
  border: 1px solid black;
  display: none;
  font-family: HelveticaNeue, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  left: 50%;
  margin: 0 auto;
  margin-top: -1em;
  padding: 0.2em 0.5em 0.15em;
  position: absolute;
  transform: translateX(-50%);
  @media only print {
    display: inline-block;
  }
`;
