import React from 'react';

function OnLawIcon({ title = 'OnLAW®' }) {
  return (
    <svg width="94px" height="18px" viewBox="0 0 94 18" version="1.1">
      <title>{title}</title>
      <defs>
        <polygon id="path-1" points="0 0 18.2811042 0 18.2811042 18 0 18" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-32.000000, -381.000000)">
          <g id="Group-14-Copy-9" transform="translate(32.000000, 381.000000)">
            <g id="Group-3">
              <mask id="mask-2" fill="white">
                <use href="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path
                d="M18.1199094,8.90447218 C17.0934849,14.7232757 12.5976818,18 7.53990318,18 C2.17275836,18 -0.826575476,14.6761114 0.199849003,8.85730781 C1.14553449,3.49096238 5.39512368,0 10.7143047,0 C16.8168939,0 18.9153084,4.39268109 18.1199094,8.90447218 M5.79562109,8.90447218 C5.4111116,11.0900209 5.5933739,13.8207576 8.27694631,13.8207576 C10.6991162,13.8207576 12.0173202,11.7311365 12.5161434,8.90447218 C12.9262335,6.57822978 12.4114225,4.10809611 9.92929786,4.17924235 C7.38881734,4.17924235 6.17613359,6.74370476 5.79562109,8.90447218"
                id="Fill-1"
                fill="#FFC727"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M20.181232,5.10534263 L24.6938224,5.10534263 L24.3500822,7.05267132 C25.7298397,5.55700138 27.0840165,4.7496114 28.9593996,4.7496114 C32.2361238,4.7496114 33.4408136,6.67215881 32.838069,10.0927743 L31.5230626,17.5495403 L26.584394,17.5495403 L27.7427188,10.97211 C27.9617533,9.73544433 27.821859,8.64426877 26.4205178,8.64426877 C24.900866,8.64426877 24.3253009,9.61793312 24.0447129,11.2087312 L22.9263579,17.5495403 L17.9876893,17.5495403 L20.181232,5.10534263 Z"
              id="Fill-4"
              fill="#FFC727"
            />
            <polygon
              id="Fill-6"
              fill="#001B8C"
              points="33.1086646 17.7457121 36.1231869 0.648230226 41.7277524 0.648230226 39.4158991 13.756726 47.1100857 13.756726 46.4066172 17.7457121"
            />
            <path
              d="M47.0076031,17.7457121 L56.1494959,0.648230226 L62.2440911,0.648230226 L65.3561398,17.7457121 L59.7755562,17.7457121 L59.5077586,15.4890172 L53.9279744,15.4890172 L52.8887596,17.7457121 L47.0076031,17.7457121 Z M58.4381667,5.80033752 L55.6250922,11.9269086 L59.1632189,11.9269086 L58.4381667,5.80033752 Z"
              id="Fill-8"
              fill="#001B8C"
            />
            <polygon
              id="Fill-10"
              fill="#001B8C"
              points="79.6558156 11.2146467 79.70298 11.2146467 83.4897189 0.64743083 88.9040281 0.64743083 81.6383177 17.7457121 76.1288804 17.7457121 75.9090465 7.81881245 75.8610827 7.81881245 72.1638762 17.7457121 66.6552383 17.7457121 65.4185726 0.64743083 70.8328818 0.64743083 70.8928365 11.2146467 70.9408003 11.2146467 74.8458498 0.64743083 79.4767509 0.64743083"
            />
            <path
              d="M90.2634809,2.03861971 C90.2634809,1.18006839 90.9629524,0.480596882 91.8223031,0.480596882 C92.6816539,0.480596882 93.380326,1.18006839 93.380326,2.03861971 C93.380326,2.89797042 92.6816539,3.59744193 91.8223031,3.59744193 C90.9629524,3.59744193 90.2634809,2.89797042 90.2634809,2.03861971 L90.2634809,2.03861971 Z M93.0006129,2.03861971 C93.0006129,1.30957055 92.4738109,0.781969179 91.8223031,0.781969179 C91.169996,0.781969179 90.643194,1.30957055 90.643194,2.03861971 C90.643194,2.76846827 91.169996,3.29527024 91.8223031,3.29527024 C92.4738109,3.29527024 93.0006129,2.76846827 93.0006129,2.03861971 L93.0006129,2.03861971 Z M91.2211573,1.14969134 L91.8782609,1.14969134 C92.3011414,1.14969134 92.5297686,1.29598081 92.5297686,1.66769996 C92.5297686,1.96987165 92.3578985,2.11696052 92.0677177,2.14254119 L92.5433584,2.91555713 L92.2068126,2.91555713 L91.7487587,2.15533153 L91.5497091,2.15533153 L91.5497091,2.91555713 L91.2211573,2.91555713 L91.2211573,1.14969134 Z M91.5497091,1.90512058 L91.8398899,1.90512058 C92.0341431,1.90512058 92.2020163,1.87874051 92.2020163,1.64131989 C92.2020163,1.43427632 92.0125594,1.3999023 91.8438868,1.3999023 L91.5497091,1.3999023 L91.5497091,1.90512058 Z"
              id="Fill-12"
              fill="#2A2D7C"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default OnLawIcon;
