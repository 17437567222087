import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { statuteURL } from 'util/statuteURL';
import { CodeContent } from './CodeContent';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const CodeSection = props => {
  return (
    <StyledCodeSection>
      <header>
        <Link
          to={statuteURL(
            props.jurisdictionId,
            props.codeJudicataId,
            props.abbreviatedSectionTitle.replace(/§ /, ''),
          )}
        >
          {props.abbreviatedSectionTitle}
        </Link>
      </header>
      <CodeContent
        content={props.content}
        californiaCitationFormat={props.californiaCitationFormat}
        standardCitationFormat={props.standardCitationFormat}
        citeSearch={props.citeSearch}
        citeReplace={`, ${props.abbreviatedSectionTitle}`}
        history={props.history}
      />
    </StyledCodeSection>
  );
};

const StyledCodeSection = styled.div`
  border-top: 1px solid ${CEB_COLOR('ALTO')};
  padding-top: 12px;
  max-width: 720px;
  margin: 25px auto;
  overflow: hidden;

  header {
    margin-bottom: 15px;
    a,
    a:visited {
      font-weight: bold;
      font-size: 18px;
      @media only print {
        color: black;
      }
    }
  }
`;
