import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { ElementRenderer } from 'components/common/element-renderer';
import { constURL } from 'util/statuteURL';
import styled from 'styled-components';

export const SectionContent = React.forwardRef(
  ({ article, section, afterUpdate, id, index }, ref) => {
    const indexedAfterUpdate = React.useCallback(() => {
      afterUpdate && afterUpdate(index);
    }, [afterUpdate, index]);
    return (
      <StyledSectionContent ref={ref} id={id}>
        <div>
          <h2>
            <Link to={constURL(article, section.id)}>{section.title}</Link>
          </h2>
          <ElementRenderer
            content={section.element}
            className="article-content"
            afterUpdate={indexedAfterUpdate}
          />
          {section.history ? <cite>{section.history}</cite> : null}
        </div>
      </StyledSectionContent>
    );
  },
);

const StyledSectionContent = styled.section`
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  &:last-child {
    border-bottom: none;
  }

  > div {
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    line-height: 1.7;
    overflow: hidden;
    margin-bottom: 25px;
    h2 {
      font-size: 15px;
      margin-bottom: 12px;
    }

    cite {
      font-style: italic;
      font-size: 17px;
      line-height: 1.53;
      margin-bottom: 3px;
      display: block;
    }
  }

  .article-content {
    p {
      margin-bottom: 35px;
      &:last-child {
        margin-bottom: 25px;
      }
    }
    .statute-section {
      padding-left: 26px;
      .specifier {
        margin-left: -26px;
        float: left;
      }
    }
  }
`;
