import { finder } from 'util/finder';
import flattenDeep from 'lodash/fp/flattenDeep';
import { elementToHeading } from 'util/elementToHeading';

export function contentElToSections(el) {
  const find = finder(el);
  const headers = find('.case-header').map(elementToHeading('Header'));
  const counsel = find('.case-body .counsel').map(elementToHeading('Counsel'));
  const opinions = find('.case-body .opinion').map(elementToHeading(undefined));
  const notes = find('.case-body .notes').map(elementToHeading('Footnotes'));
  return flattenDeep([headers, counsel, opinions, notes]);
}
