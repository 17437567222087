import React from 'react';
import styled from 'styled-components';

export const SocialMedia = () => (
  <StyledSocialMediaList>
    <li>
      <a href="http://twitter.com/CEB_CA" title="Twitter">
        <img src="/images/social/Twitter.svg" alt="Twitter Logo" />
      </a>
    </li>
    <li>
      <a
        href="http://www.facebook.com/cebcalifornia#!/cebcalifornia?sk=wall"
        title="Facebook"
      >
        <img src="/images/social/Facebook.svg" alt="Facebook Logo" />
      </a>
    </li>
    <li>
      <a href="http://www.linkedin.com/company/929485" title="LinkedIn">
        <img src="/images/social/LinkedIn.svg" alt="LinkedIn Logo" />
      </a>
    </li>
    <li>
      <a href=" http://www.youtube.com/user/cebcle" title="Youtube">
        <img src="/images/social/YouTube.svg" alt="YouTube Logo" />
      </a>
    </li>
  </StyledSocialMediaList>
);

const StyledSocialMediaList = styled.ul`
  li {
    display: inline-block;
    margin: 0 6px;
    a {
      display: block;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
