import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import uriStringify from 'util/uriStringify';
import { getContentUri } from 'util/getContentUri';
import { TabContext } from 'components/app-context/TabContextProvider';
import { getContentRoute } from 'components/content-renderer/getContentRoute';
import { useSandboxedLocation } from 'components/preview-tabs/PreviewPanel';
import unique from 'unique-selector';
import { findParent } from 'util/finder';

function scrollNote(el, className) {
  if (typeof window !== 'undefined') {
    const anchor = document.querySelector(
      `.${className}[ref='${el.getAttribute('ref')}']`,
    );
    if (anchor) {
      anchor.scrollIntoView();
    }
  }
}

function handleNotes(el) {
  const className = el.classList.contains('noteanchor')
    ? 'notemarker'
    : 'noteanchor';
  scrollNote(el, className);
}

function useTabs() {
  const context = React.useContext(TabContext);
  return context ? context : {};
}

export default function useContentClick(query, onClick, noNavigation = false) {
  const navigate = useNavigate();
  const location = useLocation();
  const sandboxedLocation = useSandboxedLocation();

  const { dispatch } = useTabs();

  return React.useCallback(
    (event, options = {}) => {
      switch (event.target.tagName) {
        case 'SUP':
          handleNotes(event.target);
          break;
        case 'A':
        case 'I':
        case 'B':
          const link = event.target.href
            ? event.target
            : findParent(event.target)('a');
          const uri = getContentUri(link);

          if (link && uri && !uri.match(/^(http|mailto)/)) {
            if (!event.ctrlKey) {
              const pathname = uriStringify(uri, { query });
              event.preventDefault();
              const canBeTabbed = getContentRoute(pathname).match;
              if (canBeTabbed && dispatch) {
                if (sandboxedLocation) {
                  dispatch({ type: 'MINIMIZE_TAB' });
                  navigate(pathname, {
                    ...options,
                    state: {
                      ...location.state,
                      from: sandboxedLocation,
                    },
                  });
                } else {
                  dispatch({
                    type: 'ADD_TAB',
                    tab: {
                      uri: pathname,
                      initiator: unique(link),
                    },
                  });
                }
              } else {
                !noNavigation &&
                  navigate(pathname, {
                    state: { ...location.state, from: location },
                    ...options,
                  });
              }
            }
            onClick && onClick(event);
          }
          break;
        default:
          break;
      }
    },
    [location, dispatch, navigate, onClick, query, sandboxedLocation],
  );
}
