import React from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { finder, findParents } from 'util/finder';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const CopyPanel = props => {
  const preventDefault = React.useCallback(e => e.preventDefault());
  const [copied, setCopied] = React.useState(false);
  const [copyCount, setCopyCount] = React.useState(0);
  const [format, setFormat] = React.useState('california');
  const [disabled, setDisabled] = React.useState(true);
  const { focusNode, anchorNode } = props.selection;

  React.useEffect(() => {
    if (document.getSelection().type !== 'Range') {
      setDisabled(true);
      return;
    }

    const withinSelectable =
      [
        ...findParents(focusNode)(
          props.selectableSelector,
          props.containerSelector,
        ),
        ...findParents(anchorNode)(
          props.selectableSelector,
          props.containerSelector,
        ),
      ].filter(Boolean).length === 2 || !props.selectableSelector;

    const withinUnspannableParent =
      withinSelectable &&
      [
        ...findParents(focusNode)(
          props.unspannableSelector,
          props.unspannableParent,
        ),
        ...findParents(anchorNode)(
          props.unspannableSelector,
          props.unspannableParent,
        ),
      ].filter(Boolean).length === 2;

    const withinUnspannable =
      withinUnspannableParent &&
      findParents(focusNode)(
        props.unspannableSelector,
        props.unspannableParent,
      )[0] ===
        findParents(anchorNode)(
          props.unspannableSelector,
          props.unspannableParent,
        )[0];

    const valid =
      withinUnspannable || (!withinUnspannableParent && withinSelectable);

    setDisabled(!valid);
  }, [focusNode, anchorNode]);

  const onClick = React.useCallback(e => {
    if (document.getSelection().type !== 'Range') {
      setDisabled(true);
      return;
    }
    if (disabled) return;
    setCopied(true);
    setCopyCount(copyCount + 1);
    props.onCopyClick(e, format);
  });

  const resetCopied = React.useCallback(e => {
    setCopied(false);
  });

  const selectInput = React.useCallback(e => {
    const input =
      e.target.tagName.toLowerCase() !== 'input'
        ? finder(e.target)('input')[0]
        : e.target;
    input.checked = true;
    setFormat(input.value);
  });

  return disabled ? null : (
    <StyledCopyPanel
      tabIndex={0}
      onMouseDown={preventDefault}
      role="presentation"
      className={copied ? 'active' : copyCount > 0 ? 'copied' : ''}
      top={props.top}
    >
      <button onClick={onClick} onMouseLeave={resetCopied} disabled={disabled}>
        {copied ? (
          <Tooltip
            visible
            inline
            title={
              <span className="tooltip">
                <i className="fas fa-check" /> Copied
              </span>
            }
          >
            <CopyLabel />
          </Tooltip>
        ) : (
          <CopyLabel />
        )}
      </button>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label onMouseUp={selectInput}>
        <input
          type="radio"
          name="format"
          id="california"
          value="california"
          defaultChecked={true}
        />
        California
      </label>

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label onMouseUp={selectInput}>
        <input type="radio" name="format" id="standard" value="standard" />
        Standard
      </label>
    </StyledCopyPanel>
  );
};

const CopyLabel = props => (
  <span>
    <i className="far fa-copy" /> Copy
  </span>
);

const StyledCopyPanel = styled.div.attrs(({ top }) => ({ top }))`
  line-height: normal;
  position: absolute;
  right: 8px;
  top: ${({ top }) => (top ? top : '0')}px;
  width: 50px;
  z-index: 1;
  width: 100px;
  &.active {
    color: ${CEB_COLOR('DUSTY_TEAL')};
    button {
      color: ${CEB_COLOR('DUSTY_TEAL')};
    }
  }

  &.copied button {
    color: ${CEB_COLOR('SEA_FOAM')};
  }

  .tooltip {
    white-space: nowrap;
  }

  label {
    cursor: pointer;
    display: block;
    margin: 0 0 14px;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 13px;
    font-weight: bold;
    input {
      margin-right: 5px;
      vertical-align: -1px;
    }
  }
  button {
    background-color: transparent;
    color: ${CEB_COLOR('NICE_BLUE')};
    cursor: pointer;
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 3px;
    padding: 10px;
    padding-left: 0px;
    text-align: left;
    width: 100%;
    &:disabled {
      cursor: default;
      color: gray;
    }
  }
  i {
    margin-right: 2px;
    vertical-align: -2px;
  }
`;
