import findIndex from 'lodash/fp/findIndex';

export default function expandSectionToIndex(list, index) {
  const selected = findIndex(item => item.index === index)(list);
  const selectedItem = list[selected];
  let lastDepth = selectedItem ? parseInt(selectedItem.depth) : 0;
  const expanded = selectedItem ? [selectedItem.index] : [];

  if (selected !== null && selected < list.length) {
    for (let i = selected; i > 0; i--) {
      const item = list[i];
      const depth = parseInt(item.depth);

      if (depth < lastDepth) {
        lastDepth = depth;
        expanded.unshift(item.index);
        if (depth === 0) {
          break;
        }
      }
    }
  }

  const tracked = {};

  return list.filter(item => {
    const depth = parseInt(item.depth);
    const itemExpanded = expanded.includes(item.index);
    tracked[depth] = itemExpanded;
    return depth === 0 || itemExpanded || tracked[depth - 1];
  });
}
