import React from 'react';
import { BookmarkContext } from 'components/app-context/BookmarkContextProvider';

function shorten(str, maxLen, separator = ' ') {
  if (str?.length <= maxLen) return str;
  return str?.substr(0, str.lastIndexOf(separator, maxLen));
}

export default function useBookmark() {
  const { bookmarkRef } = React.useContext(BookmarkContext) || {};

  return function bookmark({ type, title, path, selector, text, subtype }) {
    if (bookmarkRef) {
      bookmarkRef.current = {
        type,
        title,
        text,
        path,
        selector,
        subtype,
      };
    }
  };
}

export function useBookmarkVariables() {
  const { bookmarkRef } = React.useContext(BookmarkContext) || {};

  if (bookmarkRef?.current) {
    const { type, title, text, path, selector, subtype } = bookmarkRef.current;

    return {
      type,
      title,
      text: {
        description: shorten(
          selector ? document.querySelector(selector)?.innerText : text,
          125,
        ),
      },
      path,
      subtype,
    };
  }

  return null;
}
