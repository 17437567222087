import React from 'react';
import { CodeContent } from './CodeContent';
import { StyledCitingPillPrint } from 'styles/CitingPillPrint';
import { WithFooter } from 'components/layouts/WithFooter';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export const StatuteCodeContent = props => {
  return (
    <StyledStatuteCodeContentLayout
      className={`statute-section-content ${props.code.judicataId}-${
        props.codeSection.judicataNumericId
      }`}
    >
      <StyledStatuteCodeContent>
        <header className="statute-section-content-header">
          <h1>California {props.code && props.code.name}</h1>
          <h2>{props.codeSection && props.codeSection.title}</h2>
        </header>
        <hr />
        <StyledCitingPillPrint>
          Citing Cases {props.codeSection.citationCount}
        </StyledCitingPillPrint>
        <CodeContent
          terms={props.terms}
          content={props.content}
          californiaCitationFormat={props.codeSection.californiaCitationFormat}
          standardCitationFormat={props.codeSection.standardCitationFormat}
          history={props.codeSection.history}
        />
      </StyledStatuteCodeContent>
    </StyledStatuteCodeContentLayout>
  );
};

const StyledStatuteCodeContentLayout = styled(WithFooter)`
  > footer {
    display: none;
    margin: 0;
    @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      display: block;
    }
  }
  @media only print {
    padding: 25px 88px;
  }
`;

const StyledStatuteCodeContent = styled.div`
  margin: 0 auto;
  max-width: 675px;
  padding: 25px 80px 100px 80px;
  position: relative;
  flex: 1 1 auto;

  header {
    text-align: center;
    margin-bottom: 40px;
  }
  h1 {
    font-style: italic;
    font-size: 22px;
    margin-bottom: 8px;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
  }
  hr {
    border-bottom: 1px solid ${CEB_COLOR('MERCURY')};
    max-width: 55%;
    margin: 0 auto 28px;
    height: 1px;
    @media only print {
      margin-bottom: 0;
      max-width: 100%;
      border-color: black;
    }
  }
  .statutes-content {
    margin: 0 auto;
    padding-top: 12px;
    max-width: 720px;
    @media only print {
      margin-top: 2em;
    }
  }
`;
