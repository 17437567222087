import React from 'react';
import { StatuteSidebar } from 'components/statutes/StatuteSidebar';
import { WithSidebar } from 'components/layouts/WithSidebar';
import { CodeSection } from 'components/statutes/codes/CodeSection';
import {
  ContentRendererContext,
  TabbableLocationContext,
} from '../preview-tabs/PreviewPanel';
import useHistory from 'hooks/useHistory';
import { HISTORY_MAP, HISTORY_TYPE_MAP } from 'components/history-panel';
import { sectionTitle } from 'components/statutes/codes/CodeHeading';
import { useLocation } from 'react-router';

export function StatutesPreview() {
  const { data } = React.useContext(ContentRendererContext);
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);
  const { location: referrer } = useLocation();

  const { title } = React.useMemo(() => {
    if (!data) return {};
    const { code, codeHeading } = data || {
      code: { name: '' },
      codeHeading: { title: '' },
    };

    return {
      code,
      codeHeading,
      text: `${codeHeading.title} ${sectionTitle(codeHeading)}`,
      title: `${code.name} ${
        params.judicataIdBegin && params.judicataIdEnd
          ? `(Section ${params.judicataIdBegin}-${params.judicataIdEnd})`
          : ''
      }`,
    };
  }, [data, params]);

  const { mutateHistory, recentHistoryLoading } = useHistory(referrer);
  React.useEffect(() => {
    if (!title || recentHistoryLoading) return;

    mutateHistory({
      variables: {
        text: title,
        type: HISTORY_TYPE_MAP.STATUTE,
        action: 'BROWSE',
        subtype: HISTORY_MAP.BROWSE_STATUTE_RULE,
      },
    });
  }, [title, recentHistoryLoading]);

  return (
    <WithSidebar collapsible defaultCollapsed={true}>
      <StatuteSidebar
        judicataIdBegin={params.judicataIdBegin}
        judicataIdEnd={params.judicataIdEnd}
        jurisdictionId={params.jurisdictionId}
        codeJudicataId={params.codeJudicataId}
        statute={data.statute}
        code={data.code}
        codeSection={data.codeSection}
      />
      <div>
        {data.codeSection && (
          <CodeSection
            {...data.codeSection}
            jurisdictionId={params.jurisdictionId}
            codeJudicataId={params.codeJudicataId}
            californiaCitationFormat={data.codeSection.californiaCitationFormat}
            standardCitationFormat={data.codeSection.standardCitationFormat}
            citeSearch={`, § ${params.judicataIdBegin}`}
          />
        )}
        {data.codeHeading?.codeSections &&
          data.codeHeading.codeSections.map((item, i) => (
            <CodeSection
              key={i}
              {...item}
              jurisdictionId={params.jurisdictionId}
              codeJudicataId={params.codeJudicataId}
              californiaCitationFormat={
                data.codeSection.californiaCitationFormat
              }
              standardCitationFormat={data.codeSection.standardCitationFormat}
              citeSearch={`, § ${params.judicataIdBegin}`}
            />
          ))}
      </div>
    </WithSidebar>
  );
}
