import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { statuteURL } from 'util/statuteURL';
import { getNextSection } from './getNextSection';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

const ParentTableOfContentsItem = ({
  parent,
  jurisdictionId,
  codeJudicataId,
  sections,
}) => {
  const next = getNextSection(parent.index, sections) || {};
  return (
    <Link
      to={statuteURL(
        jurisdictionId,
        codeJudicataId,
        parent.sectionBegin || next.sectionBegin,
        parent.sectionEnd || next.sectionEnd,
      )}
    >
      {parent.title}
    </Link>
  );
};

export const ParentTableOfContents = props => {
  return (
    <StyledParentTableOfContents>
      {props.parents.slice(1).map((parent, i) => (
        <li data-depth={i} key={i}>
          <ParentTableOfContentsItem
            jurisdictionId={props.jurisdictionId}
            codeJudicataId={props.codeJudicataId}
            parent={parent}
            sections={props.sections}
          />
        </li>
      ))}
      <li data-depth={props.parents.length - 2} data-selected>
        <b>{props.codeSection.abbreviatedSectionTitle}</b>
      </li>
    </StyledParentTableOfContents>
  );
};

const StyledParentTableOfContents = styled.ol`
  list-style-type: none;

  li {
    padding: 7px 0 6px 0;
    position: relative;
    display: flex;
    ${[...Array(19)].map((_, i) => {
      return `&[data-depth='${i + 1}'] {
        padding-left: ${(i + 1) * 16}px;
      
    }`;
    })}

    &[data-selected='true'] {
      &:before {
        background-color: ${CEB_COLOR('ORANGEY_YELLOW')};
        content: ' ';
        display: block;
        height: 100%;
        left: -25px;
        position: absolute;
        top: 0;
        width: 15px;
      }
    }
  }
`;
