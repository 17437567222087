import React from 'react';
import { useQuery } from '@apollo/client';
import GET_CODE_HEADING from 'graphql/getCodeHeading';
import {
  StickyContentHeader,
  StickyContentHeaderContext,
} from '../StickyContentHeader';
import { HeaderActions } from 'components/common/HeaderActions';
import { CodeSection } from './CodeSection';
import { LoadingMessage } from 'components/common/LoadingMessage';
import useBookmark from 'hooks/useBookmark';
import { useLocation } from 'react-router';
import useHistory from 'hooks/useHistory';
import { HISTORY_MAP, HISTORY_TYPE_MAP } from 'components/history-panel';
import styled from 'styled-components';

function useHistoryAndBookmark(data, variables) {
  const bookmark = useBookmark();
  const { pathname: path } = useLocation();
  const { mutateHistory, recentHistoryLoading } = useHistory();

  const { title, text } = React.useMemo(() => {
    if (!data) return {};
    const { code, codeHeading } = data || {
      code: { name: '' },
      codeHeading: { title: '' },
    };

    return {
      code,
      codeHeading,
      text: `${codeHeading.title} ${sectionTitle(codeHeading)}`,
      title: `${code.name} ${
        variables.judicataIdBegin && variables.judicataIdEnd
          ? `(Section ${variables.judicataIdBegin}-${variables.judicataIdEnd})`
          : ''
      }`,
    };
  }, [data, variables]);

  React.useEffect(() => {
    bookmark({
      title,
      text,
      path,
      type: 'STATUTE_RULE',
      subtype: 'California Codes',
    });
  }, [title, text, path]);

  React.useEffect(() => {
    if (!title || recentHistoryLoading) return;

    mutateHistory({
      variables: {
        text: title,
        type: HISTORY_TYPE_MAP.STATUTE,
        action: 'BROWSE',
        subtype: HISTORY_MAP.BROWSE_STATUTE_RULE,
      },
    });
  }, [title, recentHistoryLoading]);
}

export const CodeHeading = ({ variables }) => {
  const { loading, data } = useQuery(GET_CODE_HEADING, {
    variables,
  });

  useHistoryAndBookmark(data, variables);

  const { code, codeHeading, codeSection } = data || {
    code: { name: '' },
    codeHeading: { title: '' },
  };

  if (loading) return <LoadingMessage />;

  return (
    <StyledCodeHeading className="code-heading">
      <StickyContentHeader context={StickyContentHeaderContext}>
        <StyledHeaderContent className="header">
          <div>
            <h1>{code.name}</h1>
            <h2>
              {codeHeading.title}
              <br />
              {sectionTitle(codeHeading)}
            </h2>
          </div>
          <HeaderActions />
        </StyledHeaderContent>
      </StickyContentHeader>
      <header>
        <h1>{code.name}</h1>
        <h2>
          {codeHeading.title}
          <br />
          {sectionTitle(codeHeading)}
        </h2>
      </header>
      {codeHeading.codeSections.map((item, i) => (
        <CodeSection
          key={i}
          {...item}
          jurisdictionId={variables.jurisdictionId}
          codeJudicataId={variables.codeJudicataId}
          californiaCitationFormat={codeSection.californiaCitationFormat}
          standardCitationFormat={codeSection.standardCitationFormat}
          citeSearch={`, § ${variables.judicataIdBegin}`}
        />
      ))}
    </StyledCodeHeading>
  );
};

const StyledHeaderContent = styled.div`
  display: flex;
  padding: 18px 33px;
  @media print {
    display: none;
  }
  div {
    flex: 1 1 auto;
  }
  div:first-child {
    visibility: hidden;
  }
  .scrolled & div:first-child {
    visibility: visible;
  }
  .scrolled & {
    display: flex;
    position: static;

    h1 {
      font-style: normal;
      text-align: left;
      margin-bottom: 2px;
    }
    h2 {
      font-size: 16px;
      br {
        content: ' ';
        &:after {
          content: ' ';
        }
      }
    }
  }
`;

const StyledCodeHeading = styled.div`
  flex: 1 1 auto;
  padding: 25px;
  padding-bottom: 125px;
  position: relative;

  .statutes-content {
    position: static;
  }

  .sticky-header {
    position: fixed !important;
  }

  h1 {
    font-style: italic;
    font-size: 22px;
    margin-bottom: 8px;
    @media print {
      text-align: center;
    }
  }

  h2 {
    font-weight: normal;
  }

  > header {
    text-align: center;
  }
`;

export function sectionTitle({ sectionBegin, sectionEnd }) {
  return `Section${
    sectionBegin === sectionEnd
      ? ` ${sectionBegin}`
      : `s ${sectionBegin}-${sectionEnd}`
  }`;
}
