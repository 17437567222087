import React from 'react';
import { last } from 'lodash/fp';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ENABLE_HISTORY_FEATURE } from 'app-constants';
import { useLocation } from 'react-router-dom';
import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import { AppContext } from 'components/app-context';
import { getLocationFromPath } from 'util/getLocationFromPath';
import { useSandboxedLocation } from 'components/preview-tabs/PreviewPanel';
import {
  CreateHistoryItemDocument,
  CreateHistoryItemMutation,
  GetHistoryDatesDocument,
  GetHistoryItemsDocument,
  GetHistoryItemsQuery,
  GetPractitionerHistoryDocument,
  UpdateHistoryItemDocument,
  UpdateHistoryItemMutation,
} from '__generated__/graphql';

dayjs.extend(utc);

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const DATE_MAP = {
  today: {
    startDate: dayjs().format('YYYY-MM-DD'),
  },
};

type HistoryAction = {
  type: 'LOADING' | 'RESPONSE';
  errors?: any;
  data?: any;
};

interface HistoryState {
  loading?: boolean;
  errors?: any;
  data?: any;
}

export function HistoryReducer(state: HistoryState, action: HistoryAction) {
  switch (action.type) {
    case 'LOADING':
      return {
        loading: true,
      };
    case 'RESPONSE':
      return {
        loading: false,
        errors: action.errors,
        data: action.data,
      };
    default:
  }
  return state;
}

export function useHistoryMutations() {
  const [createHistoryItem] = useMutation(CreateHistoryItemDocument);
  const [updateHistoryItem] = useMutation(UpdateHistoryItemDocument);

  return React.useMemo(
    () => ({
      CREATE: createHistoryItem,
      UPDATE: updateHistoryItem,
    }),
    [createHistoryItem, updateHistoryItem],
  );
}

export function useHistoryParent() {
  const [historyParent, setHistory] = React.useState(
    JSON.parse(sessionStorage.getItem('historyParent')!),
  );
  const setHistoryParent = React.useCallback(
    (historyParent: any) => {
      sessionStorage.setItem(
        'historyParent',
        JSON.stringify(historyParent || ''),
      );
      setHistory(historyParent);
    },
    [setHistory],
  );
  return [historyParent, setHistoryParent];
}

export function isParentPath(pathname?: string) {
  return (
    HISTORY_PARENT_PATHS.filter(str => {
      const re = new RegExp(str, 'gi');
      const [match] = pathname?.match(re) || [];
      return match === pathname;
    }).length > 0
  );
}

export function isOldHistory(historyParent: any) {
  return (
    (historyParent &&
      dayjs(historyParent.createdAt).diff(dayjs().format('YYYY-MM-DD'), 'd') >
        1) ||
    !historyParent
  );
}

export function isParentItem(location: any, recentHistory: any) {
  const referrerPath = getReferrerPath(location);
  const referrerIsTracked =
    recentHistory?.historyItemsByDate?.length &&
    getReferrerItem(recentHistory, location)?.id !== undefined;
  const isParent = isParentPath(location.pathname);
  const referrerIsParent = isParentPath(referrerPath);

  if (
    [location.pathname, referrerPath].filter(path => {
      return (
        path &&
        new RegExp(
          '/primary-law/statutes/ca/codes/([^/]+)/[0-9.]+/?[0-9.]*/?',
          'gi',
        ).test(path)
      );
    }).length === 2
  ) {
    return (
      isParent &&
      getCodeSection(location.pathname) !== getCodeSection(referrerPath)
    );
  }
  return isParent || (!referrerIsTracked && referrerIsParent);
}

export function getCodeSection(path?: string) {
  const re = new RegExp(
    '/primary-law/statutes/ca/codes/([^/]+)/[0-9.]+/?[0-9.]*/?',
    'gi',
  );
  const [match, codeSection] = (path && re.exec(path)) || [null, undefined];
  return codeSection;
}

export function getReferrerPath({
  state,
  ...location
}: any): string | undefined {
  const link = getLocationFromPath(location);

  return (
    (state?.from
      ? [state.from.pathname, state.from.search, state.from.hash]
          .filter(Boolean)
          .join()
      : typeof window !== 'undefined'
        ? document.referrer
        : ''
    )
      .replace(/&*page=\d+/gi, '')
      .replace(new RegExp(`https?://${link.host}:?\\d*`, 'gi'), '') || undefined
  );
}

export function getReferrerItem(
  recentHistory: any,
  location: any,
  refPath?: string | null,
) {
  const referrerPath = refPath || getReferrerPath(location);
  return [
    recentHistory?.historyItemsByDate[0],
    ...(recentHistory?.historyItemsByDate[0]?.children || []),
  ]
    .filter(Boolean)
    .filter(item => item.path === referrerPath)[0];
}

export function findRecentSimilarHistory(recentHistory: any, variables: any) {
  const lastParent = recentHistory?.historyItemsByDate[0];
  const lastParentsChild = last(
    recentHistory?.historyItemsByDate[0]?.children || [],
  );
  return [lastParent, lastParentsChild]
    .filter(Boolean)
    .filter(
      item =>
        item.text === variables.text &&
        item.type === variables.type &&
        item.subtype === variables.subtype,
    )[0];
}

export function getParentId(historyParent: any, referrerHistoryItem: any) {
  const parentId =
    historyParent?.parentId ||
    historyParent?.id ||
    referrerHistoryItem?.parentId ||
    referrerHistoryItem?.id;

  return parentId ? parseInt(parentId, 10) : undefined;
}

function useRecentHistory() {
  const { localUser } = React.useContext(AppContext);
  const historyEnabled =
    ENABLE_HISTORY_FEATURE && !localUser.ipSession && localUser.authorized;

  const { data: recentHistory, loading: recentHistoryLoading } = useQuery(
    GetHistoryItemsDocument,
    {
      skip: !historyEnabled,
      variables: {
        ...DATE_MAP.today,
        endDate: '',
      },
    },
  );

  const recentHistoryRef = React.useRef<GetHistoryItemsQuery | undefined>();

  React.useEffect(() => {
    recentHistoryRef.current = recentHistory;
  }, [recentHistory]);

  return { recentHistoryRef, recentHistoryLoading };
}

export interface MutateAction {
  type?: string;
  variables: any;
}

export default function useHistory(referrerLocation?: any) {
  const { localUser } = React.useContext(AppContext);
  const historyEnabled =
    ENABLE_HISTORY_FEATURE && !localUser.ipSession && localUser.authorized;

  const [{ errors, loading, data }, dispatch] = React.useReducer(
    HistoryReducer,
    {},
  );

  const [historyParent, setHistoryParent] = useHistoryParent();

  const HISTORY_MUTATION = useHistoryMutations();

  const location = useLocation();
  const sandboxedLocation = useSandboxedLocation();

  const { pathname, search } = sandboxedLocation || location;

  const { recentHistoryRef, recentHistoryLoading } = useRecentHistory();

  const mutateHistory = React.useCallback(
    async ({ type, variables }: MutateAction) => {
      if (!historyEnabled) return;
      dispatch({ type: 'LOADING' });

      const currentLocation = sandboxedLocation || location;

      try {
        const isParent =
          isParentItem(currentLocation, recentHistoryRef.current) ||
          isOldHistory(historyParent);
        const referrerHistoryItem = getReferrerItem(
          recentHistoryRef.current,
          currentLocation,
          referrerLocation
            ? `${referrerLocation.pathname}${referrerLocation.search}${referrerLocation.hash}`
            : null,
        );
        const similarHistoryItem = findRecentSimilarHistory(
          recentHistoryRef.current,
          variables,
        );

        const parentId = !isParent
          ? getParentId(historyParent, referrerHistoryItem)
          : undefined;

        const mutationType = type || similarHistoryItem ? 'UPDATE' : 'CREATE';

        const mutation = HISTORY_MUTATION[mutationType];

        const { errors, data } = await mutation({
          variables: {
            ...variables,
            path: `${pathname}${search}`,
            parentId:
              !isParent && parentId !== parseInt(similarHistoryItem?.id, 0)
                ? parentId
                : undefined,
            id: mutationType === 'UPDATE' ? similarHistoryItem.id : undefined,
          },
          refetchQueries: [
            {
              query: GetHistoryItemsDocument,
              variables: { ...DATE_MAP.today },
            },
            ...(variables.type === 'PRACTITIONER'
              ? [{ query: GetPractitionerHistoryDocument }]
              : []),
            ...(recentHistoryRef.current?.historyItemsByDate &&
            recentHistoryRef.current.historyItemsByDate.length > 0
              ? []
              : [{ query: GetHistoryDatesDocument }]),
          ],
        });

        if (isParent) {
          setHistoryParent(
            (data as CreateHistoryItemMutation).createHistoryItem ||
              (data as UpdateHistoryItemMutation).updateHistoryItem,
          );
        }

        dispatch({ type: 'RESPONSE', errors, data });
      } catch (error: any) {
        console.error({ error });
        dispatch({
          type: 'RESPONSE',
          errors: error.graphQLErrors || error.networkError,
        });
      }
    },
    [location, historyParent?.id],
  );

  return {
    mutateHistory,
    errors,
    loading,
    currentHistoryItem: data,
    dispatch,
    recentHistoryRef,
    recentHistoryLoading,
    historyParent,
    setHistoryParent,
  };
}

export const HISTORY_PARENT_PATHS = [
  '/',
  '/news/?',
  '/news/[^/]+/?',
  '/climatebrief/?',
  '/climatebrief/topics/[^/]+/?',
  '/posts/?', // (vs an individual post, which generates history)
  '/law-alert/?', // (vs an individual law alert, which generates history)
  '/primary-law/?',
  '/news/search/?',
  '/primary-law/cases/search/?',
  '/primary-law/statutes/search/?', // (vs individual codes)
  '/primary-law/statutes/?', // (vs individual codes)
  '/primary-law/rules/?',
  '/primary-law/rules/ca/?',
  '/primary-law/rules/search/?',
  '/primary-law/statutes/ca/codes/?', // (vs individual codes)
  '/primary-law/statutes/ca/const/?', // (vs articles/sections)
  '/primary-law/statutes/ca/codes/[^/]+/[0-9.]+/[0-9.]+/?', // (vs browsing an individual title)
  '/secondary-sources/?',
  '/secondary-sources/search/?',
  '/secondary-sources/area/[^/]*/?', // (vs browsing an individual title)
  '/practitioner/area/[^/]*/?',
  '/practitioner/?',
  '/practitioner/search/?',
  '/learning/?.*',
  '/account/?.*',
];
