import { gql } from '@apollo/client';

const GET_CASE = gql`
  query GetCase($judicataId: String!, $fromQuery: String) {
    case(judicataId: $judicataId, fromQuery: $fromQuery) {
      name
      cite
      date
      courtName
      content
      treatmentAttitudeName
      treatmentDescription
      citationCount
      lastCitedOn
      judicataId
      exampleTreatingCase {
        name
        judicataId
        treatmentDescription
      }
      searchHighlightTerms
      californiaCitationFormat: citationTemplate(format: CALIFORNIA) {
        format
        text
        typeName
      }
      standardCitationFormat: citationTemplate(format: STANDARD) {
        format
        text
        typeName
      }
    }
  }
`;

export default GET_CASE;
