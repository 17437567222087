import React from 'react';

export default function useScrollThreshold(threshold) {
  const [scrolled, setScrolled] = React.useState(false);
  const onScroll = React.useCallback(
    ({ target: { scrollTop } }) => {
      if (scrolled && scrollTop <= threshold) setScrolled(false);
      if (!scrolled && scrollTop > threshold) setScrolled(true);
    },
    [scrolled, threshold],
  );
  return [scrolled, onScroll];
}
