import React from 'react';
import useHistory from 'hooks/useHistory';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { useNavigateWithState } from 'components/routes/RedirectWithState';

export function HistoryTable({ historyItems, close, ...props }) {
  const { setHistoryParent } = useHistory();
  const navigate = useNavigateWithState();
  const onClick = React.useCallback(
    (e, i, item, parent) => {
      if (i > 0) setHistoryParent();
      e.stopPropagation();
      close(e);
      navigate(
        item.path,
        parent
          ? {
              state: {
                from: { pathname: parent.path },
              },
            }
          : undefined,
      );
    },
    [setHistoryParent, close, navigate],
  );
  return (
    <StyledHistoryTable>
      {(historyItems || [])
        .filter(item => !item.parentId)
        .filter(item => item.path)
        .map((item, i) => {
          return (
            <tbody key={i}>
              <tr>
                <td>
                  <time dateTime={dayjs(item.createdAt)}>
                    {dayjs(item.createdAt).format('h:mm A')}
                  </time>
                </td>
                <td>{item.subtype}</td>
                <td>
                  <Link
                    to={item.path}
                    title={item.text}
                    onClick={e => onClick(e, i, item)}
                  >
                    {item.text}
                  </Link>
                </td>
              </tr>
              {item.children
                .filter(child => child.path)
                .map((child, j) => {
                  return (
                    <tr key={j}>
                      <td>
                        <time dateTime={dayjs(child.createdAt)}>
                          {dayjs(child.createdAt).format('h:mm A')}
                        </time>
                      </td>
                      <td>{child.subtype}</td>
                      <td>
                        <Link
                          to={{
                            pathname: child.path,
                          }}
                          state={{
                            from: { pathname: item.path },
                          }}
                          title={child.text}
                          onClick={e => onClick(e, i, child, item)}
                        >
                          {child.text}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          );
        })}
    </StyledHistoryTable>
  );
}

const StyledHistoryTable = styled.table.attrs(() => ({
  timelineColor: '#ececeb',
}))`
  border-collapse: collapse;
  font-family: 'Zilla Slab', serif;
  color: ${CEB_COLOR('BLACK')};
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: 100%;
  font-size: 16px;
  tbody + tbody {
    border-top: 2px solid ${CEB_COLOR('VERY_LIGHT_PINK')};
  }
  tbody tr:first-child td {
    background-color: ${({ timelineColor }) => timelineColor};
    font-size: 16px;
    font-weight: bold;
    padding: 9px 5px 9px 5px;
    time {
      color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
      font-size: 16px;
      &:before,
      &:after {
        display: none;
      }
    }
  }
  tbody tr:last-child td time:before {
    bottom: 50%;
    max-height: 17px;
  }
  tr {
    &:hover td {
      background: rgba(86, 152, 202, 0.08);
    }
  }
  td,
  th {
    padding: 8px 7px 8px;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    a,
    a.active {
      background: none;
    }
    &:last-child {
      a {
        max-width: 440px;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        font-weight: bold;
      }
    }
    &:first-child {
      padding-left: 12px;
      position: relative;
    }
  }

  time {
    color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
    font-size: 14px;
    padding-left: 8px;
    &:before {
      border-left: 3px solid ${({ timelineColor }) => timelineColor};
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 7px;
    }
    &:after {
      border-radius: 5px;
      border: 5px solid ${({ timelineColor }) => timelineColor};
      content: '';
      left: 3px;
      position: absolute;
      top: 14px;
    }
  }
`;
