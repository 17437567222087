import { gql } from '@apollo/client';

const GET_BOOKMARK_FOLDERS = gql`
  query getBookmarkFolders {
    bookmarkFolders {
      id
      name
    }
  }
`;

export default GET_BOOKMARK_FOLDERS;
