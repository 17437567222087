import styled from 'styled-components';

export const UnstyledUnordedList = styled.ul`
  list-style: none;
`;

export const ResetUnorderedList = styled(UnstyledUnordedList)`
  padding: 0;
  margin: 0;
  > li {
    padding: 0;
    margin: 0;
  }
`;
