import React from 'react';
import { StopWatchIcon } from 'components/common/icons';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const FreeTrialNav = ({ days = 13 }) => (
  <StyledFreeTialNavItem className="free-trial-nav" days={days}>
    {days >= 0 && (
      <div>
        <StopWatchIcon />
      </div>
    )}
    <div>
      <h4>Free Trial</h4>
      <h6>
        {days < 1 ? (
          <b>Expired</b>
        ) : (
          <em>
            {days} Day{days !== 1 ? 's' : ''} Left
          </em>
        )}
      </h6>
    </div>
  </StyledFreeTialNavItem>
);

const StyledFreeTialNavItem = styled.li.attrs(({ days }) => ({
  days,
}))`
  &.free-trial-nav {
    color: white;
    display: flex;
    font-family: 'BasisGrotesque', sans-serif;
    padding: 12px 30px 16px 14px;
    text-align: left;
    white-space: nowrap;
    ${({ days }) => {
      if (days >= 4) {
        return `background: ${CEB_COLOR('DUSTY_TEAL')};`;
      }
      if (days >= 1) {
        return `background: ${CEB_COLOR('DI_SERRIA')};`;
      }
      if (days === 0) {
        return `background: ${CEB_COLOR('TUSCANY')};`;
      }
    }}
  }

  svg {
    margin-right: 10px;
    margin-top: 2px;
  }

  h4 {
    font-size: 15px;
    margin: 0;
  }

  h6 {
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    b {
      font-size: 16px;
      font-weight: bold;
    }
  }

  div {
    flex: 1 1 auto;
  }
`;
