import React from 'react';

export function useArticleSectionData(data) {
  const {
    content,
    title,
    parentTableOfContents,
    citationCount,
    history,
    text,
    sectionTitle,
    historyTitle,
  } = React.useMemo(() => {
    if (!data) return {};

    const {
      caConstitutionSection: {
        content,
        title,
        parentTableOfContents,
        citationCount,
        history,
      },
    } = data;

    const text = parentTableOfContents[1].title;
    const [, sectionTitle] = title.split(',');

    return {
      content,
      title,
      parentTableOfContents,
      citationCount,
      history,
      text,
      sectionTitle,
      historyTitle: `${text} ${sectionTitle}`,
    };
  }, [data]);

  return {
    content,
    title,
    parentTableOfContents,
    citationCount,
    history,
    text,
    sectionTitle,
    historyTitle,
  };
}
