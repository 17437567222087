import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export const LoggedOutNav = () => (
  <StyledLoggedOutNavList>
    <li>
      <Link to="/login" replace>
        Log In
      </Link>
    </li>
    <li>
      <Link to="/register" replace>
        Register
      </Link>
    </li>
  </StyledLoggedOutNavList>
);

const StyledLoggedOutNavList = styled.ul`
  background-color: ${CEB_COLOR('CEB_HEADER_BLUE')};
  list-style: none;
  li {
    display: block;
    a,
    a:visited {
      border-bottom-color: transparent;
      border-bottom-style: solid;
      border-bottom-width: 4px;
      box-sizing: border-box;
      color: #fff;
      display: block;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      height: 60px;
      letter-spacing: 0.05em;
      margin: 0 10px;
      padding: 20px 2px 19px;
    }

    a[aria-current='page'] {
      border-bottom-color: rgb(245, 192, 35);
      font-weight: bold;
    }
  }

  @media only screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
    margin-left: auto;
    margin-right: 20px;
    text-align: right;
    li {
      display: inline-block;
    }
  }
`;
