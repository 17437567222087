import React from 'react';
import { useLocation } from 'react-router';
import { CircleIcon } from './CircleIcon';
import history from 'history/browser';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

const flashMessages = {
  inviteAccepted: (
    <>
      <b>Congratulations!</b> You have been added to your organization's CEB
      account.
    </>
  ),
};

export function TopFlash({ icon = 'info', ...props }) {
  const location = useLocation();

  const [showFlashMessage, setShowFlashMessage] = React.useState(
    location.state?.flashMessage,
  );

  const onClose = React.useCallback(() => {
    setShowFlashMessage(false);
    history.replace(location, { flashMessage: undefined });
  }, [location]);

  return !showFlashMessage ? null : (
    <StyledTopFlash className="top-flash">
      <div>
        <CircleIcon
          size={22}
          color={'var(--COLOR_DUSTY_TEAL)'}
          bgcolor="white"
        />
      </div>
      <div>{flashMessages[location.state?.flashMessage]}</div>
      <div>
        <button onClick={onClose}>
          Dismiss <i className="fa fa-times" />
        </button>
      </div>
    </StyledTopFlash>
  );
}

const StyledTopFlash = styled.section`
  background: ${CEB_COLOR('DUSTY_TEAL')};
  color: white;
  display: flex;
  font-family: 'Zilla Slab', serif;
  font-size: 16px;
  padding: 10px 24px 6px 24px;
  grid-area: layout-banner;
  > div {
    flex: 1 1 auto;
    line-height: 24px;
    &:first-child {
      padding-right: 24px;
    }
    &:first-child,
    &:last-child {
      flex: 0 1 auto;
      line-height: 1;
    }
    > button {
      display: inline-block;
      font-family: inherit;
      font-size: 16px;
      font-weight: bold;
      i {
        vertical-align: -2px;
        margin-left: 4px;
      }
    }
  }
`;
