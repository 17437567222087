import { getLocationFromPath } from 'util/getLocationFromPath';
import { matchPath } from 'react-router';
import { CONTENT_PATHS } from './CONTENT_PATHS';

export function getContentRoute(uri) {
  const location = getLocationFromPath(uri);
  const match = matchPaths(CONTENT_PATHS, location.pathname)[0];
  return { location, match };
}

export function matchPaths(paths, pathname) {
  return paths.map(path => matchPath(path, pathname)).filter(Boolean);
}
