import React from 'react';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';
import { SearchTypeAhead } from 'components/layouts/research-layout/SearchTypeahead';

export function HeaderSearchInput({ type, query, onQueryChange, placeholder }) {
  const queryRef = React.useRef();

  return (
    <StyledHeaderSearchInput>
      <section>
        <label>
          <section>
            <i className="fas fa-search" />
          </section>
          <section>
            <SearchTypeAhead
              placeholder={placeholder}
              queryRef={queryRef}
              query={query}
              type={type}
              onChange={onQueryChange}
            />
          </section>
        </label>
      </section>
      <section>
        <button type="submit">Search</button>
      </section>
    </StyledHeaderSearchInput>
  );
}
const StyledHeaderSearchInput = styled.div`
  background: white;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  max-width: 682px;
  padding: 3px;
  label {
    display: flex;
    i {
      font-size: 20px;
      margin-top: 3px;
      vertical-align: middle;
    }
    > section {
      flex: 1 1 auto;
      padding: 5px 0.5em;
      &:first-child {
        flex: 0 1 auto;
      }
    }
  }
  > section {
    flex: 1 1 auto;
    &:last-child {
      flex: 0 1 auto;
    }
    div > div {
      margin-bottom: 0;
      > input[type='text'] {
        border: 0;
        color: ${CEB_COLOR('DARK_GREY_BLUE')};
        font-family: 'BasisGrotesque', sans-serif;
        font-size: 16px;
        line-height: 1;
        height: 26px;
        margin: 0;
        padding: 5px 3px;
        width: 100%;
      }
    }
  }
  button {
    background: ${CEB_COLOR('CEB_HEADER_BLUE')};
    border-radius: 3px;
    color: white;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    padding: 11px 11px 9px 11px;
  }
`;
