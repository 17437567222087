import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { getContentRoute } from './getContentRoute';
import { contentUriToQuery } from './contentUriToQuery';

export function useContentQuery(uri) {
  const { CONTENT_QUERY, variables } = React.useMemo(() => {
    return uri ? contentUriToQuery(uri) : {};
  }, [uri]);

  const { data, loading } = useQuery(CONTENT_QUERY, {
    variables,
    skip: !CONTENT_QUERY,
  });

  const { location, match } = React.useMemo(() => {
    return getContentRoute(uri);
  }, [uri]);

  return {
    data,
    loading,
    type: match?.params?.type,
    variables,
    location,
    match,
  };
}
