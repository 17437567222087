import React from 'react';
import { ElementRenderer } from 'components/common/element-renderer';
import toElement from 'util/toElement';
import useContentClick from 'hooks/useContentClick';
import scrollContentToHash from 'util/scrollContentToHash';
import { markSearchTerms } from 'util/markSearchTerms';
import { applyHrefs } from 'util/applyHrefs';
import { useLocation } from 'react-router-dom';
import { LoadingMessage } from 'components/common/LoadingMessage';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

const { CitableDocument } =
  typeof window !== 'undefined'
    ? require('components/citable-document')
    : { CitableDocument: props => props.children };

export const CodeContent = props => {
  const location = useLocation();
  const onContentClick = useContentClick(props.query);

  const contentEl = React.useMemo(() => {
    const highlightedContent = markSearchTerms(props.terms, props.content);
    const el = toElement(highlightedContent);
    applyHrefs(el);
    return el;
  }, [props.content, props.terms]);

  const scrollToIt = React.useCallback(() => {
    scrollContentToHash(contentEl, location.hash);
  }, [contentEl, location.hash]);

  if (!contentEl) {
    return <LoadingMessage />;
  }

  return (
    <StyledCodeContent>
      <CitableDocument
        californiaCitationFormat={props.californiaCitationFormat}
        standardCitationFormat={props.standardCitationFormat}
        citeSearch={props.citeSearch}
        citeReplace={props.citeReplace}
      >
        <ElementRenderer
          content={contentEl}
          afterUpdate={scrollToIt}
          className="statutes-content"
          role="document"
          onClick={onContentClick}
        />
      </CitableDocument>
      {props.history ? <cite>{props.history}</cite> : null}
    </StyledCodeContent>
  );
};

const StyledCodeContent = styled.div`
  p {
    margin-bottom: 32px;
    line-height: 1.7;
    font-size: 17px;
    @media only print {
      font-size: 14px;
    }
    &:last-child {
      margin-bottom: 25px;
    }
  }
  a[data-statute-section] {
    cursor: pointer;
    @media only print {
      color: black;
      font-weight: bold;
    }
  }
  mark,
  span:focus,
  .focused {
    background-color: ${CEB_COLOR('CITRINE_WHITE')};
    outline: 0;
  }
  cite {
    font-style: italic;
    font-size: 17px;
    line-height: 1.53;
    margin-bottom: 3px;
    display: block;
  }
  .statute-section {
    padding: 0 36px;
    .specifier {
      margin-left: -21px;
    }
  }
`;
