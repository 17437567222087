import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { PreviewPanel } from './PreviewPanel';
import { useLocation } from 'react-router';
import { PreviewTabs } from './PreviewTabs';
import { PreviewTabOverflow } from './PreviewTabOverflow';

export function PreviewTabPanel() {
  const location = useLocation();
  const { current, minimized, overflow, limit } =
    location.state?.tabState || {};

  return ReactDOM.createPortal(
    <>
      {current && <PreviewPanel />}
      <StyledPreviewTabs className="tabs" limit={limit}>
        <PreviewTabs tabs={minimized} />
        <PreviewTabOverflow tabs={overflow} />
      </StyledPreviewTabs>
    </>,
    document.body,
  );
}

const StyledPreviewTabs = styled.ol`
  bottom: 0;
  display: flex;
  justify-content: end;
  pointer-events: none;
  position: absolute;
  right: 190px;
  width: calc(100vw - 190px);
  z-index: 10;
  @media print {
    display: none;
  }
  &,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  > li {
    flex: 0 1 auto;
    margin: 0 0 0 8px;
    pointer-events: all;
    position: relative;
    white-space: nowrap;
    width: calc(((100% - 140px) * 0.8) / ${({ limit }) => limit});
    &:first-child {
      &:after {
        border-left-width: 1px;
      }
    }

    &:before,
    &:after {
      background-color: #fafafa;
      border: solid 1px #e4e4e2;
      border-bottom-color: transparent;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
    }
    &:before {
      height: calc(100% - 6px);
      left: -4px;
      top: 4px;
      z-index: 2;
    }

    &:after {
      border-left-width: 0;
      height: calc(100% - 10px);
      left: -8px;
      top: 8px;
      z-index: 1;
    }
  }
`;
