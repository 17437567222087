import { gql } from '@apollo/client';

const GET_BOOKMARKS = gql`
  query getBookmarks($folderId: ID!) {
    bookmarks(bookmarkFolderId: $folderId) {
      id
      folderId
      type
      title
      text
      path
      subtype
    }
  }
`;

export default GET_BOOKMARKS;
