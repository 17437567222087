import React from 'react';
import useDropdown from 'hooks/useDropdown';
import styled, { css } from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { TrackedLink as Link } from '../common/TrackedLink';
import { LinkProps } from 'react-router-dom';

interface HeaderMenuProps extends React.HTMLAttributes<HTMLElement> {
  children: [React.ReactNode, React.ReactNode];
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  onClick?: (e: React.MouseEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  title?: string;
}

export function HeaderMenu({
  children,
  as: Component = 'nav',
  onClick,
  onBlur,
  title,
  ...props
}: HeaderMenuProps) {
  const [dropdownVisible, toggleDropdown, onDropdownBlur, containerRef] =
    useDropdown(false, 250, false);

  const handleBlur = React.useCallback(
    (e: React.FocusEvent) => {
      onBlur?.(e);
      onDropdownBlur?.(e);
    },
    [onBlur, onDropdownBlur],
  );

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      onClick?.(e);
      toggleDropdown(e);
    },
    [onClick, toggleDropdown],
  );

  return (
    <Component
      {...props}
      onBlur={handleBlur}
      open={dropdownVisible}
      ref={containerRef}
    >
      <>
        <button onClick={handleClick} title={title}>
          {children[0]}
        </button>
        {dropdownVisible && children[1]}
      </>
    </Component>
  );
}

export interface StyledHeaderMenuListProps {
  padding?: number;
  anchor?: string;
}
export const StyledHeaderMenuList = styled.ul<StyledHeaderMenuListProps>`
  background: white;
  border-radius: 3px;
  ${({ anchor }) =>
    anchor === 'right'
      ? css`
          border-top-right-radius: 0;
        `
      : css`
          border-top-left-radius: 0;
        `}
  border: solid 1px #d2d2d2;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.15);
  color: ${CEB_COLOR('BLACK')};
  list-style-type: none;
  padding: ${({ padding = 10 }) => padding}px;
  position: absolute;
  text-align: left;
`;

export interface StyledHeaderMenuListItemProps {
  padding?: number;
  iconSize?: number;
}
export const StyledHeaderMenuListItem = styled.li.attrs(
  ({ padding = 10, iconSize = 30 }: StyledHeaderMenuListItemProps) => ({
    padding,
    iconSize,
  }),
)`
  position: relative;
  > button,
  > a {
    &:hover {
      background-color: rgba(24, 92, 173, 0.04);
    }
  }
  > span > span,
  > button,
  > a,
  > a:visited,
  > a:active {
    box-sizing: border-box;
    border-radius: 3px;
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    display: block;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1.29;
    padding: 9px ${({ padding }) => padding}px 9px
      ${({ padding, iconSize }) => padding + iconSize}px;
    position: relative;
    text-align: left;
    width: 100%;
    &.active {
      background-color: rgba(24, 92, 173, 0.08);
    }
    &.onlaw-item {
      height: 36px;
    }
    img,
    svg,
    i {
      position: absolute;
      margin-left: -${({ iconSize }) => iconSize}px;
    }
    svg {
      position: absolute;
      margin-top: -1px;
      margin-left: -${({ iconSize }) => iconSize}px;
    }
  }
  > span > span {
    color: ${CEB_COLOR('DARK_GREY_BLUE')};
  }

  > button {
    svg {
      margin-left: -${({ iconSize }) => iconSize - 2}px;
    }
  }

  > * > ul {
    list-style-type: none;
    margin-left: ${({ iconSize }) => iconSize}px;
    > li > {
      span > span,
      button,
      a,
      a:active,
      a:visited {
        padding-left: ${({ padding }) => padding}px;
        font-size: 13px;
      }
    }
  }
`;

export interface HeaderMenuListItemProps extends Omit<LinkProps, 'to'> {
  onClick?: () => void;
  to?: string;
  active?: boolean;
  padding?: number;
  iconSize?: number;
  href?: string;
  end?: boolean;
}

export function HeaderMenuListItem({
  onClick,
  to,
  children,
  className,
  active,
  padding,
  iconSize,
  href,
  ...props
}: HeaderMenuListItemProps) {
  const classNames = [
    active ? 'active' : undefined,
    ...(className || '').split(' '),
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <StyledHeaderMenuListItem padding={padding} iconSize={iconSize}>
      {href ? (
        <a href={href} onClick={onClick} className={classNames}>
          {children}
        </a>
      ) : to ? (
        <Link {...props} to={to} onClick={onClick} className={classNames}>
          {children}
        </Link>
      ) : onClick ? (
        <button onClick={onClick} className={classNames}>
          {children}
        </button>
      ) : (
        <span className={classNames}>{children}</span>
      )}
    </StyledHeaderMenuListItem>
  );
}
