export default function scrollContentToHash(contentEl, hash) {
  if (contentEl) {
    if (hash) {
      const el = contentEl.querySelector(`*[id="${hash.substr(1)}"]`);
      if (el) {
        el.scrollIntoView();
      }
    } else {
      contentEl.scrollTop = 0;
    }
  }
}
