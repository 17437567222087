import React from 'react';
import { useStickyContentHeader } from 'hooks/useStickyContentHeader';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const StickyContentHeaderContext = React.createContext();

export const StickyContentHeader = ({ children }) => {
  const { containerPadding } = React.useContext(StickyContentHeaderContext);
  const { placeholderRef, headerRef, headerWidth } = useStickyContentHeader();
  let topPadding = containerPadding,
    leftPadding = containerPadding;
  if (typeof containerPadding === 'object') {
    topPadding = containerPadding.top;
    leftPadding = containerPadding.left;
  }
  return (
    <>
      <div ref={placeholderRef} />
      <StyledStickyContentHeader
        headerWidth={headerWidth}
        leftPadding={leftPadding}
        topPadding={topPadding}
        className="sticky-header"
        ref={headerRef}
      >
        {children}
      </StyledStickyContentHeader>
    </>
  );
};

const StyledStickyContentHeader = styled.header.attrs(
  ({ headerWidth, leftPadding }) => ({ headerWidth, leftPadding }),
)`
  text-align: center;
  width: auto;
  position: absolute;
  width: ${({ headerWidth, leftPadding }) =>
    headerWidth ? headerWidth + leftPadding * 2 + 'px' : '100%'};
  margin-left: ${({ leftPadding }) => -leftPadding}px;
  margin-top: ${({ topPadding }) => -topPadding}px;
  .scrolled & {
    @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      display: block;
      background: white;
      border-bottom: 1px solid ${CEB_COLOR('ALTO')};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      position: fixed;
      text-align: left;
      z-index: 1;
    }
    @media print {
      display: none;
    }
  }
`;
