import React from 'react';
import styled from 'styled-components';
import { pick } from 'lodash/fp';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { sectionTitle } from 'components/statutes/codes/CodeHeading';
import { TabbableLocationContext } from './PreviewPanel';
import { StyledPillButton } from 'components/common/pill-button-dropdown/PillButton';
import colorForAttitude from 'util/colorForAttitude';
import { constURL, statuteURL } from 'util/statuteURL';
import { TrackedLink as Link } from 'components/common/TrackedLink';

export function StatutePreviewHeader({ data }) {
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);

  const subtitle = React.useMemo(() => {
    if (!data || !params) return;

    if (params.judicataIdEnd) {
      return params.judicataIdBegin && params.judicataIdEnd
        ? `(Section ${params.judicataIdBegin}-${params.judicataIdEnd})`
        : '';
    }
    return data?.codeSection?.abbreviatedSectionTitle;
  }, [data, params]);

  const statuteUri = statuteURL(
    params.jurisdictionId,
    params.codeJudicataId,
    params.judicataId,
  );
  const citationUri = `${statuteUri}/citator`;

  return (
    <>
      <StyledCitatorContainer>
        <StyledPillButton
          to={citationUri}
          as={Link}
          color={colorForAttitude('UNKNOWN')}
        >
          Citing Cases <span>{data?.codeSection?.citationCount || '0'}</span>
        </StyledPillButton>
      </StyledCitatorContainer>
      <SubtitledHeader
        to={statuteUri}
        title={`California ${data?.code?.name}`}
        subtitle={subtitle}
      />
    </>
  );
}

export function ConstitutionPreviewHeader({ data }) {
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);

  const subtitle = React.useMemo(() => {
    if (!data) return;
    if (data?.caConstitutionSection) {
      return data.caConstitutionSection.title;
    }

    return `${data.caConstitutionHeading?.title}, ${sectionTitle(
      pick(['sectionBegin', 'sectionEnd'], data.caConstitutionHeading),
    )}`;
  }, [data]);

  const articleUrl = constURL(params.article, params.judicataId);
  const citationUri = `${articleUrl}/citator`;

  return (
    <>
      <StyledCitatorContainer>
        <StyledPillButton
          to={citationUri}
          as={Link}
          color={colorForAttitude('UNKNOWN')}
        >
          Citing Cases{' '}
          <span>{data?.caConstitutionSection?.citationCount || '0'}</span>
        </StyledPillButton>
      </StyledCitatorContainer>
      <SubtitledHeader
        to={articleUrl}
        title="California Constitution"
        subtitle={subtitle}
      />
    </>
  );
}

function SubtitledHeader({ to, title, subtitle }) {
  return (
    <header>
      <h2>
        <Link to={to}>{title}</Link>
      </h2>
      {subtitle && (
        <h4>
          <Link to={to}>{subtitle}</Link>
        </h4>
      )}
    </header>
  );
}

const StyledCitatorContainer = styled.div`
  padding: 19px 12px 19px 23px;
  font-size: 14px;
  > a {
    padding: 9px 10px 7px;
  }
`;

export const StyledStatutesHeader = styled.section`
  display: flex;
  flex: 1 1 auto;
  > div {
    flex: 0 1 auto;
  }
  > header {
    box-shadow: none;
    border: 0;
    padding: 16px 12px;
    h2,
    h4 {
      padding: 0;
      margin: 0;
    }
    h2 {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
    }
    h4 {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
    }
    a,
    a:visited {
      color: inherit;
    }
  }
`;

export const StyledPreviewPanelHeader = styled.section`
  background: #fafafa;
  border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  flex-direction: row;

  .status-message {
    min-height: 0;
  }

  > header {
    background: transparent;
    border: none;
    box-shadow: none;
    flex: 1 1 auto;
    &:last-child {
      flex: 0 1 auto;
      font-size: 18px;
      padding: 19px 22px 22px 22px;
      white-space: nowrap;
      button,
      a,
      a:visited {
        color: inherit;
        display: inline-block;
        font-size: 18px;
        margin-left: 0.5em;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
`;
