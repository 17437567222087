import React from 'react';
import ReactDOM from 'react-dom';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { StyledPillButton } from 'components/common/pill-button-dropdown/PillButton';
import colorForAttitude from 'util/colorForAttitude';
import { CaseMeta } from './CaseMeta';
import pluralize from 'util/pluralize';
import { StyledPillButtonContent } from '../case-pill-button';
import { TrueCiteLogo } from 'components/common/TrueCiteLogo';
import { TrueCiteHelpModalButton } from 'components/common/TrueCiteHelpModalButton';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import caseUrl, { caseCitatorUrl } from 'util/caseUrl';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { TabContext } from 'components/app-context/TabContextProvider';
import {
  TabbableLocationContext,
  useSandboxedLocation,
} from 'components/preview-tabs/PreviewPanel';

const GET_CASE_CITATIONS_TOOLTIP = gql`
  query getCaseCitationTooltip($caseId: String!) {
    case(judicataId: $caseId) {
      judicataId
      name
      treatmentDescription
      treatmentAttitudeName
      citationCount
      lastCitedOn
      cite
      date
      courtName
    }
  }
`;

export function CaseCitationTooltip({
  caseId,
  top,
  left,
  frameSelector,
  close,
}) {
  const { loading, data } = useQuery(GET_CASE_CITATIONS_TOOLTIP, {
    variables: { caseId },
  });
  return loading
    ? null
    : ReactDOM.createPortal(
        <CaseTooltipContent
          caseData={data?.case}
          top={top}
          left={left}
          frameSelector={frameSelector}
          close={close}
        />,
        document.body,
      );
}

function CaseTooltipContent({ caseData, left, top, frameSelector, close }) {
  const tooltipContentRef = React.useRef();
  const [offset, setOffset] = React.useState(0);
  const { dispatch } = React.useContext(TabContext);
  const sandboxedLocation = useSandboxedLocation();

  React.useLayoutEffect(() => {
    if (!tooltipContentRef.current) return setOffset(0);

    const rect = tooltipContentRef.current?.getBoundingClientRect();
    const frameRect = document
      .querySelector(frameSelector || 'body')
      .getBoundingClientRect();
    const relativeX = left - frameRect.left - (rect.width + 20) / 2;
    const relativeXpercent = relativeX / frameRect.width;
    return setOffset(relativeXpercent.toFixed(2) * -100);
  }, [left, tooltipContentRef.current, frameSelector]);
  const citatorUrl = caseCitatorUrl(caseData.judicataId);
  const handleClick = React.useCallback(
    e => {
      if (!e.ctrlKey && !sandboxedLocation) {
        dispatch({
          type: 'ADD_TAB',
          tab: {
            uri: caseUrl(caseData.judicataId),
          },
        });
        e.preventDefault();
      }
      close && close(e);
    },
    [caseData],
  );
  return (
    <StyledCaseTooltip
      className="tooltip-container"
      top={top}
      left={left}
      offset={offset}
    >
      <div className="tooltip" ref={tooltipContentRef}>
        <section>
          <div>
            <StyledPillButton
              as={Link}
              to={citatorUrl}
              color={colorForAttitude(caseData.treatmentAttitudeName)}
            >
              {caseData.treatmentDescription}
              <span>{caseData.citationCount}</span>
            </StyledPillButton>
          </div>
          <div>
            <h3>
              <Link to={caseUrl(caseData.judicataId)} onClick={handleClick}>
                {caseData.name}
              </Link>
            </h3>
          </div>
        </section>
        <section className="meta">
          <div>
            <CaseMeta
              fontSize="12"
              cite={caseData.cite}
              date={caseData.date}
              courtName={caseData.courtName}
            />
          </div>
        </section>
        <section>
          <div>
            <StyledPillButtonContent>
              <dl>
                <dt>Citations:</dt>
                <dd>
                  <Link to={citatorUrl}>
                    {caseData.citationCount}{' '}
                    {pluralize(caseData.citationCount, 'case')}
                  </Link>
                </dd>
                <dt>Last Cited:</dt>
                <dd>{caseData.lastCitedOn}</dd>
              </dl>
            </StyledPillButtonContent>
          </div>
          <div>
            <div>
              <TrueCiteLogo />
              <TrueCiteHelpModalButton />
            </div>
          </div>
        </section>
      </div>
    </StyledCaseTooltip>
  );
}

const StyledCaseTooltip = styled.div`
  &.tooltip-container {
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 101;
    &:after,
    &:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      left: ${({ x }) => x}px;
      position: absolute;
      top: 100%;
      width: 0;
      z-index: 2;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 10px;
      border-bottom-width: 0;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: ${CEB_COLOR('MERCURY')};
      border-width: 12px;
      border-bottom-width: 0;
      margin-left: -12px;
    }
    .tooltip {
      background: white;
      border: solid 1px ${CEB_COLOR('MERCURY')};
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
      bottom: 100%;
      color: ${CEB_COLOR('BLACK')};
      font-family: 'Zilla Slab', serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 2.29;
      margin: 0;
      padding: 0 20px;
      position: absolute;
      text-align: left;
      transform-origin: top;
      transform: translate(
        ${({ offset }) =>
          (offset < -45 ? -45 : offset > 45 ? 45 : offset) - 50}%,
        1px
      );
      z-index: 1;

      border-radius: 8px;
      font-size: 12px;
      padding: 13px 12px 12px 12px;
      box-sizing: border-box;
      width: 600px;
      max-width: 33vw;
      a,
      a:visited {
        color: ${CEB_COLOR('NICE_BLUE')};
        text-decoration: none;
      }
      > section {
        display: flex;
        div {
          flex: 0 1 auto;
        }
      }

      > section:first-child {
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:first-child {
            a {
              color: white;
              line-height: 1;
              padding: 6px 7px 4px 8px;
              span {
                padding-left: 9px;
              }
            }
          }
        }

        h3 {
          font-size: 14px;
          font-family: 'Zilla Slab', serif;
          font-weight: bold;
          line-height: 1.07;
          margin: 7px 8px 2px;
        }
      }

      > section.meta {
        margin: 6px 0;
        dl {
          font-weight: normal;
          line-height: 15px;
          height: auto;
          margin: 0;
        }
      }

      > section:last-child {
        > div {
          &:first-child {
            flex: 1 1 auto;
            dl {
              font-family: 'Zilla Slab', serif;
              font-size: 14px;
              font-weight: bold;
              line-height: 15px;
            }

            dd {
              margin-bottom: 0.5em;
            }
            dd:last-child {
              font-weight: normal;
              margin-bottom: 0;
            }
          }
          &:last-child {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            justify-content: end;
            line-height: 1;
            div {
              flex: 0 1 auto;
            }
          }
        }
      }
    }
  }
`;
