import { isNull } from 'lodash/fp';
import { finder, findParents } from '../finder';
import { getPn } from './getPn';
import { getSubdivisions } from './getSubdivisions';

/*
  Returns an object with data determined from the active Selection. Keys
  are as follows:
  - startPage (string):
    The page of the Official Reporter that the selection begins on.
  - endPage (string):
    The page of the Official Reporter that the selection ends on.
  - text (string):
    The selected text (without emphasis). The empty string if there was
    no selection.
  - footnote (string or undefined):
    The footnote identifier the selection started in, or undefined if the
    selection wasn't from a footnote.
  */
export const getSelectionData = () => {
  let startPn;
  let endPn;
  let text;
  let footnoteNum;
  let subdivisions;
  const sel = window.getSelection();
  if (!isNull(sel.anchorNode) && !isNull(sel.focusNode)) {
    let startNode = sel.anchorNode;
    let endNode = sel.focusNode;
    // Check to see if the selection was in a footnote. If so, we indicate
    // the page number where the note anchor appears and add extra notation
    // in the cite to indicate that the selection was in the footnote
    // itself. We do this by setting footnoteNum and adjusting the
    // startNode and endNode.
    const noteParents = findParents(startNode)('.note');
    if (noteParents.length > 0) {
      let notemarker;
      noteParents.map(
        parent => (notemarker = finder(parent)('.notemarker')[0]),
      );
      footnoteNum = notemarker.getAttribute('val');
      const ref = notemarker.getAttribute('ref');
      const anchor = document.querySelectorAll(`.noteanchor[ref="${ref}"]`);

      if (anchor.length > 0) {
        startNode = anchor[0];
        endNode = anchor[0];
      }
    }

    startPn = getPn(startNode);
    endPn = getPn(endNode);
    subdivisions = getSubdivisions(startNode, endNode);
    text = sel.toString();
  } else {
    startPn = window.config.reporterPage;
    endPn = window.config.reporterPage;
    text = '';
  }

  return {
    startPage: startPn,
    endPage: endPn,
    text,
    subdivisions,
    footnote: footnoteNum,
  };
};
