import 'babel-polyfill';
import 'element-qsa-scope';
import 'element-matches-polyfill';
import 'whatwg-fetch';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'util/node.parentElement.polyfill';
// eslint-disable-next-line no-unused-vars
import registerServiceWorker from './registerServiceWorker';
import { client } from './apollo/client';
import { App } from 'components/app';
import { prepend, forEach } from 'cross-browser-polyfill';
import '@fortawesome/fontawesome-free/css/all.css';

prepend();
forEach();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App client={client} />);
