import React from 'react';
import { ElementRenderer } from 'components/common/element-renderer';
import useContentClick from 'hooks/useContentClick';
import { markSearchTerms } from 'util/markSearchTerms';
import toElement from 'util/toElement';
import { contentElToSections } from 'util/contentElToSections';
import scrollContentToHash from 'util/scrollContentToHash';
import replace from 'lodash/fp/replace';
import { applyFocusedClass } from 'util/applyFocusedClass';
import { applyHrefs } from 'util/applyHrefs';
import { WithFooter } from 'components/layouts/WithFooter';
import { LayoutContext } from 'components/layouts/LayoutContext';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';
import { useContentHover } from './useContentHover';
import { CaseCitationTooltip } from './CaseCitationTooltip';
import { useLocation } from 'react-router';
import { useNavigationType } from 'react-router-dom';
import { ResetRestoreScroll } from 'components/layouts/ResetRestoreScroll';

const { CitableDocument } =
  typeof window !== 'undefined'
    ? require('components/citable-document')
    : { CitableDocument: props => props.children };

const transformEmbeddedJpg = replace(
  new RegExp('<iframe src="(.*.jpg)".*?></iframe>', 'gi'),
  '<img src="$1" />',
);

function addCitingCasesSeparator(contentEl, caseData) {
  const citingSeparator = document.createElement('span');
  citingSeparator.textContent = `Citing Cases ${caseData.citationCount}`;
  const caseBody = contentEl.querySelector('.case-body');
  contentEl.querySelector('div').insertBefore(citingSeparator, caseBody);
}

export const CaseContentDocument = ({
  case: caseQueryData,
  case: {
    judicataId,
    searchHighlightTerms,
    californiaCitationFormat,
    standardCitationFormat,
    content,
  },
  onUpdateSections,
  onScroll,
  query,
}) => {
  const { raw } = React.useContext(LayoutContext);
  const { hash } = useLocation();
  const navigationType = useNavigationType();

  const contentRef = React.useRef();

  const contentEl = React.useMemo(() => {
    const highlightedContent = markSearchTerms(searchHighlightTerms, content);
    const el = toElement(transformEmbeddedJpg(highlightedContent));
    addCitingCasesSeparator(el, caseQueryData);
    applyFocusedClass(el, hash);
    applyHrefs(el);
    return el;
  }, [searchHighlightTerms, content, caseQueryData, hash]);

  React.useLayoutEffect(() => {
    const sections = contentElToSections(contentEl);
    onUpdateSections(sections);
  }, [contentEl, onUpdateSections]);

  const scrollToIt = React.useCallback(() => {
    scrollContentToHash(contentRef.current, hash);
  }, [navigationType, hash]);

  const [tooltipProps, onContentHover, closeTooltip] = useContentHover(
    onScroll,
  );

  const onContentClick = useContentClick(query, closeTooltip);

  return (
    <StyledCaseWithFooter
      className="case-content-with-footer"
      ref={contentRef}
      onScroll={closeTooltip}
      tabIndex={0}
      aria-label="Case Content"
      id={judicataId}
    >
      <StyledCaseContent
        className="case-content-container"
        raw={raw}
        onMouseLeave={closeTooltip}
      >
        <CitableDocument
          className="case-content"
          selectableSelector=".case-body"
          unspannableSelector=".note"
          unspannableParent=".notes"
          californiaCitationFormat={californiaCitationFormat}
          standardCitationFormat={standardCitationFormat}
        >
          {tooltipProps && (
            <CaseCitationTooltip
              {...tooltipProps}
              frameSelector=".case-body"
              close={closeTooltip}
            />
          )}
          <ElementRenderer
            content={contentEl}
            afterUpdate={scrollToIt}
            role="document"
            onClick={onContentClick}
            onMouseOver={onContentHover}
          />
          <ResetRestoreScroll />
        </CitableDocument>
      </StyledCaseContent>
    </StyledCaseWithFooter>
  );
};

const StyledCaseWithFooter = styled(WithFooter)`
  &:focus {
    outline: 0;
  }
`;

const StyledCaseContent = styled.div.attrs(({ raw = false }) => ({ raw }))`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);

  @media print {
    box-shadow: none;
  }

  &:focus {
    outline: 0;
  }

  @media screen and (min-width: 1440px) {
    .case-content {
      max-width: 48vw;
    }
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    &.case-content-container {
      flex: 1 1 auto;
    }

    .case-content {
      &-with-footer {
        footer {
          display: block;
        }
      }
    }
  }

  .case-content {
    color: ${CEB_COLOR('BLACK')};
    font-family: 'Zilla Slab', serif;
    margin: 64px auto;
    max-width: 675px;
    padding: 0 116px;
    position: relative;
    &-with-footer {
      overflow: auto;
    }
    @media only print {
      padding: 0 100px;
    }
    .hidden {
      display: none;
    }
    .case-header {
      margin-bottom: 2em;
      text-align: center;
      line-height: 1.5em;
      .publication {
        font-size: 0.8em;
      }
    }
    .parties {
      font-size: 22px;
      font-weight: bold;
      line-height: 2;
      margin-bottom: 1.1em;
      @media only print {
        font-size: 18px;
      }
      .party {
        line-height: 1.3;
        margin-bottom: 6px;
        > span {
          display: inline-block;
          margin-bottom: 6px;
        }
        .roles {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
        }
      }
    }
    hr {
      border-top: 1px solid ${CEB_COLOR('ALTO')};
      border: 0;
      margin: 0 auto;
      width: 75%;
      @media only print {
        border-top: 1px solid black;
        width: 100%;
      }
    }
    mark,
    span:focus,
    .focused {
      background-color: ${CEB_COLOR('CITRINE_WHITE')};
      outline: 0;
      @include selection {
        & {
          background-color: rgba(206, 232, 249, 0.999);
        }
      }
    }
    .case-body {
      font-size: 17px;
      padding-top: 2.5em;
      @media only print {
        font-size: 14px;
      }
      a,
      a:visited {
        color: inherit;
        &[data-case-url-id],
        &[data-statute-section] {
          color: ${CEB_COLOR('NICE_BLUE')};
          cursor: pointer;
          text-decoration: underline;
          @media only print {
            color: black;
            font-weight: bold;
          }
        }
      }
      blockquote {
        max-width: 80%;
        margin: 0 auto;
      }
      p,
      blockquote,
      pre {
        font-size: inherit;
        margin-bottom: 1.4em;
        line-height: 1.6em;
      }
      pre {
        overflow: auto;
      }
      .heading {
        font-size: 28px;
        text-align: center;
        margin-bottom: 17px;
        p {
          margin: 0;
          font-size: inherit;
        }
      }
      .counsel {
        p {
          margin-bottom: 1.8em;
        }
      }
      .opinionauthor {
        font-size: 17px;
        margin-bottom: 2em;
      }
      .notes {
        border-top: 1px solid ${CEB_COLOR('ALTO')};
        padding-top: 20px;
        p {
          font-size: 13px;
        }
      }
      .opinion,
      .appendix {
        .pagenumber {
          color: ${CEB_COLOR('BLACK')};
          font-weight: normal;
          @media only print {
            font-weight: bold;
          }
          &:before {
            opacity: 0.35;
            display: inline-block;
            content: attr(val);
            padding: 0 0.3em;
            color: ${CEB_COLOR('BLACK')};
            @media only print {
              color: black;
              content: '*' attr(margin);
              opacity: 1;
            }
          }
          &:after {
            color: rgba(${CEB_COLOR('BLACK')}, 0.35);
            ${({ raw }) =>
              !raw ? `background: ${CEB_COLOR('VERY_LIGHT_PINK')};` : ''}
            position: absolute;
            left: 28px;
            font-size: 17px;
            content: attr(margin);
            @media only print {
              display: none;
            }
          }
        }
      }
    }
    .notemarker,
    .noteanchor {
      cursor: pointer;
      font-weight: normal;
      line-height: 1;
      &:before {
        display: inline-block;
        content: attr(val);
        color: ${CEB_COLOR('BUTTERED_RUM')};
        padding: 0 0.25em;
        @media only print {
          color: black;
        }
      }
    }
    .notemarker:before {
      padding: 0 0.5em;
    }

    iframe {
      border: 0;
      width: 100%;
      height: 500px;
    }

    img {
      width: 100%;
    }
  }
`;
