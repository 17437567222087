import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { useSuspendableQuery } from 'hooks/useSuspendableQuery';
import React from 'react';
import styled from 'styled-components';
import GET_BOOKMARKS from './graphql/getBookmarks';
import { ResetUnorderedList } from 'components/common/UnordedList';

export const TYPE_MAP = {
  ALL: {
    name: 'All',
  },
  PRACTITIONER: {
    name: 'Practitioner',
    filter: bookmark => bookmark.type === 'PRACTITIONER',
  },
  SECONDARY_SOURCES: {
    name: 'Secondary Sources',
    filter: bookmark => bookmark.type === 'SECONDARY_SOURCES',
  },
  CASE: { name: 'Cases', filter: bookmark => bookmark.type === 'CASE' },
  STATUTE_RULE: {
    name: 'Statutes & Rules',
    filter: bookmark => bookmark.type === 'STATUTE_RULE',
  },
  CURRENT_AWARENESS: {
    name: 'Current Awareness',
    filter: bookmark => bookmark.type === 'CURRENT_AWARENESS',
  },
};

function BookmarkFilter({ type, name, selected, bookmarks, onFilter }) {
  const onClick = React.useCallback(() => {
    onFilter(type, bookmarks);
  });

  return (
    <StyledBookmarkFilter className={selected && 'selected'} onClick={onClick}>
      {name}
      <span>{bookmarks?.length || '0'}</span>
    </StyledBookmarkFilter>
  );
}

const StyledBookmarkFilter = styled.button`
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  font-family: 'BasisGrotesque', sans-serif;
  padding: 0px 6px;
  width: 100%;
  line-height: 25px;
  &.selected {
    background: ${CEB_COLOR('COOL_BLUE')};
    font-weight: bold;
    color: white;
  }
  span {
    flex: 1;
    text-align: right;
    font-size: 11px;
  }
`;

export function BookmarkFolder({ folderId, onChange }) {
  const {
    data: { bookmarks },
  } = useSuspendableQuery(GET_BOOKMARKS, { variables: { folderId } });

  const [filterType, setFilterType] = React.useState('ALL');

  const onFilter = React.useCallback((type, bookmarks) => {
    setFilterType(type);
    onChange && onChange(bookmarks);
  });

  React.useEffect(() => {
    onFilter('ALL', bookmarks);
  }, [bookmarks]);

  return (
    <ResetUnorderedList>
      {Object.keys(TYPE_MAP).map(type => {
        const { name, filter } = TYPE_MAP[type];
        return (
          <li key={type}>
            <BookmarkFilter
              selected={filterType === type}
              type={type}
              name={name}
              bookmarks={filter ? bookmarks.filter(filter) : bookmarks}
              onFilter={onFilter}
            />
          </li>
        );
      })}
    </ResetUnorderedList>
  );
}
