import React from 'react';
import { bugsnagClient } from 'util/bugsnagClient';

export function useBugsnagUser(user) {
  React.useEffect(() => {
    if (bugsnagClient) {
      bugsnagClient.user = user
        ? {
            name: user.fullName,
            email: user.email,
          }
        : undefined;
    }
  }, [user]);
}
