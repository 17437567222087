import React from 'react';
import { BookmarkContext } from 'components/app-context/BookmarkContextProvider';
import { BookmarkPanel } from 'components/bookmark-panel';
import styled, { css } from 'styled-components';
import colorForAttitude from 'util/colorForAttitude';

export function BookmarkButton() {
  const { bookmarkDropdown: dropdown, bookmarkToggle: toggle } =
    React.useContext(BookmarkContext) || {};

  const close = React.useCallback(e => dropdown && toggle(e), [
    dropdown,
    toggle,
  ]);

  return (
    <>
      <StyledBookmarkButton to="/" onClick={toggle} $open={dropdown}>
        <i className="fa fa-bookmark" />
      </StyledBookmarkButton>
      {dropdown ? <BookmarkPanel close={close} /> : null}
    </>
  );
}
const StyledBookmarkButton = styled.button`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  i {
    line-height: 1.2;
    opacity: 0.75;
    font-size: 16px;
  }
  ${({ $open }) =>
    $open &&
    css`
      background: white;
      z-index: 101;
      position: relative;
      &:focus {
        outline: 0 !important;
      }
      i {
        color: ${colorForAttitude('CAUTION')};
        opacity: 1;
      }
    `}
  &:hover {
    i {
      opacity: 1;
    }
  }
`;
