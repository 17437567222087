import React from 'react';
import styled from 'styled-components';

export const ArticleHeader = ({ title, subtitle }) => (
  <StyledArticleHeader>
    <h1>California Constitution</h1>
    <h2>
      {title}
      {subtitle ? <span>{subtitle}</span> : null}
    </h2>
  </StyledArticleHeader>
);

const StyledArticleHeader = styled.header`
  text-align: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 34px;
  margin-bottom: 8px;
  .sticky-header & {
    text-align: left;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
    h2 {
      font-size: 16px;
      font-weight: normal;
    }
  }

  h1 {
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 5px;
    line-height: 1.3;
  }

  h2 {
    font-size: 22px;
    line-height: 1.22;
    span {
      display: block;
    }
  }
`;
