import { useIsSandboxed } from 'components/preview-tabs/PreviewPanel';
import { useNavigateWithState } from 'components/routes/RedirectWithState';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

export const Section = ({ title, level, current, element, id, onScrollTo }) => {
  const isSandboxed = useIsSandboxed();
  const navigate = useNavigateWithState();
  const ref = React.useRef();
  const location = useLocation();

  const scrollToCurrent = React.useCallback(() => {
    ref.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }, []);

  const onClick = React.useCallback(() => {
    if (!isSandboxed) {
      navigate(
        { hash: id, ...(location.search && { search: location.search }) },
        {
          replace: true,
        },
      );
    }
    onScrollTo ? onScrollTo(element, id) : element.scrollIntoView();
  }, [onScrollTo, isSandboxed, element, id, navigate, location.search]);

  const onMouseUp = React.useCallback(e => e.target.blur(), []);

  React.useEffect(() => {
    current && scrollToCurrent && scrollToCurrent();
  }, [current, scrollToCurrent]);

  return (
    <StyledSectionItem ref={ref}>
      <button
        data-level={Number(level) + 1 || 1}
        onClick={onClick}
        onMouseUp={onMouseUp}
        className={current ? 'current' : ''}
      >
        {title}
      </button>
    </StyledSectionItem>
  );
};

const StyledSectionItem = styled.li`
  button {
    background-color: rgba(118, 103, 66, 0.05);
    border-left: 12px solid transparent;
    display: block;
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    margin-bottom: 2px;
    padding: 14px 5px 14px 10px;
    text-align: left;
    width: 100%;
    &:hover {
      border-left: 12px solid rgba(253, 186, 18, 0.3);
      cursor: pointer;
    }
    &.current {
      border-left: 12px solid rgba(253, 186, 18, 1);
    }
    &[data-level] {
      padding-left: 200px;
    }
    ${[...Array(19)].map((_, i) => {
      return `&[data-level='${i + 1}'] {
        padding-left: ${(i + 1) * 11}px;
      }`;
    })}
  }
`;
