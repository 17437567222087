import React from 'react';
import loadable from '@loadable/component';
import RequireAuth, { RequireAuthProps } from './RequireAuth';
import { RouteObject } from 'react-router-dom';

import Check404 from './Check404';

type RetryOptions = {
  retries: number;
  interval?: number;
  exponentialBackoff?: boolean;
};

function retry(
  fn: () => Promise<any>,
  { retries = 3, interval = 500, exponentialBackoff = true }: RetryOptions,
): Promise<any> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}

interface CEBRouteDefinition extends RouteObject {
  path: string;
  componentImport?: () => Promise<any>;
  componentProps?: Record<string, any>;
  layoutName?: string;
  layoutProps?: Record<string, any>;
  layoutComponent?: React.ComponentType<any>;
  requireAuth?: boolean | Record<string, any>;
  children?: CEBRouteDefinition[];
  element?: React.ReactNode;
  notFoundCheck?: boolean;
}

export default function mapRoutesForUse(routes: CEBRouteDefinition[]) {
  const elements = routes.map((args: CEBRouteDefinition): RouteObject => {
    const {
      path,
      componentImport,
      componentProps = {},
      layoutName,
      layoutProps = {},
      layoutComponent,
      requireAuth,
      children,
      element: providedElement,
      notFoundCheck,
    } = args;

    let element = providedElement;

    if (componentImport) {
      const Component = componentImport
        ? loadable(() =>
            retry(componentImport, {
              retries: 3,
            }),
          )
        : undefined;

      if (Component) {
        element = <Component {...componentProps} />;
      }
    }

    if (layoutComponent || layoutName) {
      const Layout = layoutComponent
        ? layoutComponent
        : layoutName
          ? loadable(() =>
              retry(() => import(`components/layouts/${layoutName}.js`), {
                retries: 3,
              }),
            )
          : undefined;

      element = Layout ? <Layout {...layoutProps}>{element}</Layout> : element;
    }

    if (requireAuth) {
      element = requireAuth ? (
        <RequireAuth {...(requireAuth as RequireAuthProps)}>
          {element}
        </RequireAuth>
      ) : (
        element
      );
    }

    if (notFoundCheck) {
      element = <Check404 notFoundCheck={notFoundCheck}>{element}</Check404>;
    }

    return {
      path,
      element,
      children: children && mapRoutesForUse(children),
    };
  });

  return elements;
}

export function ComponentFromPath(filePath: string) {
  return (
    filePath &&
    loadable(() =>
      retry(() => import(`components/${filePath}.js`), {
        retries: 3,
      }),
    )
  );
}
