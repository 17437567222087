import React from 'react';
import {
  StyledFormLabel,
  StyledValidatedButton,
  StyledValidatedInput,
} from 'components/common/StyledForm';
import { ValidatedForm } from 'components/common/validated-form';
import { useApolloClient, useMutation } from '@apollo/client';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';
import CREATE_BOOKMARK from './graphql/createBookmark';
import CREATE_BOOKMARK_FOLDER from './graphql/createBookmarkFolder';
import GET_BOOKMARKS from './graphql/getBookmarks';
import { FolderSelect } from './FolderSelect';
import { BlankSlateUnbookmarkable } from './BlankSlateUnbookmarkable';
import { useBookmarkVariables } from 'hooks/useBookmark';
import { EmptyFolder } from './EmptyFolder';
import { BookmarkHeader } from './BookmarkHeader';
import { BookmarkListItem } from './BookmarkListItem';
import { FolderMenuHeader } from './FolderMenuHeader';
import { BookmarkPanelContext } from '.';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

function CreateFolderAndBookmark() {
  const bookmarkVariables = useBookmarkVariables();
  const client = useApolloClient();

  const onSubmit = React.useCallback(async form => {
    const response = await client.mutate({
      mutation: CREATE_BOOKMARK_FOLDER,
      variables: { name: form.name },
      refetchQueries: [{ query: GET_BOOKMARK_FOLDERS }],
    });

    await client.mutate({
      mutation: CREATE_BOOKMARK,
      variables: {
        folderId: response.data.createBookmarkFolder.id,
        ...bookmarkVariables,
      },
      refetchQueries: [
        {
          query: GET_BOOKMARKS,
          variables: { folderId: response.data.createBookmarkFolder.id },
        },
      ],
    });
  }, []);

  return (
    <StyledCreateFolderAndBookmark>
      {bookmarkVariables ? (
        <ValidatedForm onSubmit={onSubmit}>
          <BookmarkHeader />
          <StyledFormLabel label="Add to Folder:">
            <StyledValidatedInput
              name="name"
              placeholder="New Folder Name"
              required
            />
          </StyledFormLabel>
          <StyledValidatedButton>Add Bookmark</StyledValidatedButton>
        </ValidatedForm>
      ) : (
        <BlankSlateUnbookmarkable />
      )}
    </StyledCreateFolderAndBookmark>
  );
}

const StyledCreateFolderAndBookmark = styled.div`
  text-align: left;
  button {
    font-size: 14px;
    font-weight: normal;
    padding: 6px 17px 3px;
  }
`;

function SelectFolderAndBookmark({ folders, bookmarks }) {
  const bookmarkVariables = useBookmarkVariables();
  const { folderId, setFolderId } = React.useContext(BookmarkPanelContext);
  const [mutate] = useMutation(CREATE_BOOKMARK);
  const addBookmark = React.useCallback(() => {
    mutate({
      variables: {
        folderId,
        ...bookmarkVariables,
      },
      refetchQueries: [
        {
          query: GET_BOOKMARKS,
          variables: { folderId },
        },
        { query: GET_BOOKMARK_FOLDERS },
      ],
    });
  });

  return (
    <StyledSelectFolderAndBookmarks>
      {bookmarkVariables && (
        <>
          <BookmarkHeader />
          <section>
            <FolderSelect
              className="folder-select"
              value={folderId}
              folders={folders}
              onChange={setFolderId}
              color="#343434"
            >
              <FolderMenuHeader folders={folders} onCreate={setFolderId}>
                Add to Folder:
              </FolderMenuHeader>
            </FolderSelect>
            <button onClick={addBookmark}>Add Bookmark</button>
          </section>
        </>
      )}
      {bookmarks.length ? (
        <div>
          {bookmarks.map(bookmark => (
            <BookmarkListItem
              folders={folders}
              folderId={folderId}
              key={bookmark.id}
              bookmark={bookmark}
            />
          ))}
        </div>
      ) : (
        <div className="empty-folder">
          <EmptyFolder />
        </div>
      )}
    </StyledSelectFolderAndBookmarks>
  );
}

const StyledSelectFolderAndBookmarks = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child:last-child {
    height: 100%;
  }
  > div {
    flex: 1 1 auto;
    height: auto;
    &.empty-folder {
      align-items: center;
      justify-content: center;
    }
    &:first-child {
      flex: 0 1 auto;
    }
  }

  > section {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    > div {
      margin: 0;
      flex: 1 1 auto;
      background: transparent;
      margin-right: 1em;
      > ol {
        top: 28px;
      }
      > button.select {
        background-color: ${CEB_COLOR_RGBA('BLACK', 0.1)};
        border-radius: 3px;
        max-height: 28px;
        line-height: 31px;
        margin: 0;
        padding: 0 10px;
      }
    }
    > button {
      flex: 0 1 auto;
      background: ${CEB_COLOR('NICE_BLUE')};
      border-radius: 3px;
      padding: 8px 17px 6px;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      color: white;
    }
  }

  .folder-select {
    background: ${CEB_COLOR_RGBA('BLACK', 0.1)};
  }
`;

export function BookmarkPage({ folders, bookmarks }) {
  return folders.length > 0 ? (
    <SelectFolderAndBookmark folders={folders} bookmarks={bookmarks} />
  ) : (
    <CreateFolderAndBookmark />
  );
}
