import React from 'react';
import { SocialMedia } from './SocialMedia';
import { CustomerSupport } from './CustomerSupport';
import { Information } from './Information';
import { CEBAddress } from './CEBAddress';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <StyledFooter className="main-footer" aria-label="Footer">
      <section>
        <div>
          <CEBAddress />
        </div>
        <div>
          <h4>
            <span>Information</span>
          </h4>
          <Information />
        </div>
        <div>
          <h4>
            <span>Customer Support</span>
          </h4>
          <CustomerSupport />
        </div>
      </section>
      <section>
        <div className="copyright">
          <p>Copyright &copy; {currentYear} CEB. All rights reserved.</p>
        </div>
        <div>
          <SocialMedia />
        </div>
      </section>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background: ${CEB_COLOR('CARARRA')};
  border-top: 1px solid ${CEB_COLOR('QUILL_GRAY')};
  color: ${CEB_COLOR('BLACK')};
  font-family: 'Zilla Slab', serif;
  font-size: 15px;
  padding: 36px 64px;
  @media print {
    display: none;
  }

  a,
  a:visited {
    color: inherit;
  }

  div {
    margin-bottom: 28px;
  }

  i {
    margin: 0 0.25em;
  }

  section:last-child div {
    margin-bottom: 12px;
  }

  h4 {
    font-size: 19px;
    margin-bottom: 19px;
  }

  .copyright {
    font-size: 12px;
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    section {
      display: flex;
      &:first-child {
        div:first-child {
          flex: 0.6 1 auto;
        }
      }
      div {
        flex: 1 1 auto;
        padding: 0 15px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          text-align: right;
          padding-right: 0;
        }
        &.copyright {
          display: flex;
          align-items: flex-end;
          flex-direction: initial;
          p {
            min-width: 100%;
            flex: 0 1 auto;
          }
        }
      }
    }
  }

  ul,
  li {
    list-style-type: none;
  }

  section div {
    flex-direction: column;
    h4,
    img {
      min-height: 36px;
      flex: 0 1 auto;
      align-items: flex-end;
    }
    h4 span {
      flex: 1 1 auto;
    }
  }
`;
