import React from 'react';
import { useMutation } from '@apollo/client';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';
import GET_BOOKMARKS from './graphql/getBookmarks';
import { FolderMenu } from './FolderMenu';
import { FolderList } from './FolderList';
import useDropdown from 'hooks/useDropdown';
import UPDATE_BOOKMARK from './graphql/updateBookmark';
import { FolderMenuHeader } from './FolderMenuHeader';
import MoveFolder from 'components/common/icons/MoveFolder';
import styled from 'styled-components';

export function MoveBookmark({ folders, bookmark, onToggle, open }) {
  const [moveDropdown, moveToggle, onMoveBlur, moveRef] = useDropdown();
  const [mutate] = useMutation(UPDATE_BOOKMARK);
  const moveBookmark = React.useCallback(
    (folderId, e) => {
      e.stopPropagation();
      mutate({
        variables: {
          id: bookmark.id,
          folderId,
        },
        refetchQueries: [
          {
            query: GET_BOOKMARKS,
            variables: { folderId },
          },
          {
            query: GET_BOOKMARKS,
            variables: { folderId: bookmark.folderId },
          },
          { query: GET_BOOKMARK_FOLDERS },
        ],
      });
      moveToggle(e);
    },

    [mutate, moveToggle],
  );

  const handleMoveToggle = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    onToggle(!moveDropdown, e);
  });

  React.useEffect(() => {
    if (!moveDropdown && open !== moveDropdown) {
      onToggle(moveDropdown);
    }
  }, [moveDropdown]);

  React.useEffect(() => {
    moveToggle(undefined, open);
  }, [open]);

  return (
    <>
      <StyledMoveBookmarkButton onClick={handleMoveToggle}>
        <MoveFolder />
      </StyledMoveBookmarkButton>
      {moveDropdown && (
        <FolderMenu folders={folders} ref={moveRef}>
          <FolderMenuHeader onCreate={moveBookmark} folders={folders}>
            Move To Folder
          </FolderMenuHeader>
          <FolderList
            folders={folders}
            excludeFolder={bookmark.folderId}
            onSelectFolder={moveBookmark}
          />
        </FolderMenu>
      )}
    </>
  );
}

const StyledMoveBookmarkButton = styled.button`
  display: block;
  svg {
    vertical-align: -1px !important;
  }
`;
