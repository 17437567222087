import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { TableOfContents } from './table-of-contents/';
import { ParentTableOfContents } from './table-of-contents/ParentTableOfContents';
import { statuteURL } from 'util/statuteURL';
import styled, { createGlobalStyle } from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { SidebarContext } from 'components/layouts/WithSidebar';

export const StatuteSidebar = props => {
  const currentYear = new Date().getFullYear();
  const { toggleSidebar } = React.useContext(SidebarContext);
  return (
    <StyledStatuteSidebar>
      <header>
        {props.statute ? (
          <h4>
            <button onClick={toggleSidebar}>
              <i className={'fas fa-list-ul'} />
            </button>
            <Link to={statuteURL(props.jurisdictionId)}>
              {props.statute.name} Code {currentYear}
            </Link>
          </h4>
        ) : null}
        {props.code ? (
          <h3>
            {props.codeSection ? (
              <Link to={statuteURL(props.jurisdictionId, props.codeJudicataId)}>
                {props.code.name}
              </Link>
            ) : (
              props.code.name
            )}
          </h3>
        ) : null}
      </header>
      {props.codeSection ? (
        <ParentTableOfContents
          jurisdictionId={props.jurisdictionId}
          codeJudicataId={props.codeJudicataId}
          codeSection={props.codeSection}
          parents={props.codeSection.parentTableOfContents}
          sections={(props.code && props.code.tableOfContents) || []}
        />
      ) : (
        <TableOfContents
          judicataIdBegin={props.judicataIdBegin}
          judicataIdEnd={props.judicataIdEnd}
          jurisdictionId={props.jurisdictionId}
          codeJudicataId={props.codeJudicataId}
          sections={(props.code && props.code.tableOfContents) || []}
        />
      )}
      <StyledStatuteSidebarGlobals />
    </StyledStatuteSidebar>
  );
};

const StyledStatuteSidebarGlobals = createGlobalStyle`
  [data-layout='with-sidebar'] {
    div {
      > button {
        margin-top: 28px !important;
      }
      header i {
        display: none;
        top: 16px;
      }
    }
  }
`;

export const StyledStatuteSidebar = styled.div`
  background: ${CEB_COLOR('CARARRA')};
  border-right: 1px solid ${CEB_COLOR('ALTO')};
  box-sizing: border-box;
  padding: 35px 15px 35px 25px;

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    min-width: 320px;
    max-width: 320px;
  }
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
    max-width: 532px;
    min-width: 532px;
  }

  @media only print {
    display: none;
  }

  h4 {
    padding: 0;
    margin-bottom: 18px;
    font-family: 'Zilla Slab', serif;
  }

  h3 {
    font-family: 'Zilla Slab', serif;
    margin-bottom: 11px;
    font-size: 19px;
  }

  i {
    margin-right: 0.5em;
    &.fa-exclamation-triangle {
      color: orange;
    }
  }
  a,
  a:visited {
    color: inherit;
  }
`;
