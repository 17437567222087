import { gql } from '@apollo/client';

const UPDATE_BOOKMARK = gql`
  mutation updateBookmark($id: ID!, $folderId: ID!) {
    updateBookmark(id: $id, folderId: $folderId) {
      id
      folderId
    }
  }
`;

export default UPDATE_BOOKMARK;
