import { useMemo } from 'react';
import { hasProductCode, getProducts } from 'util/hasProductCode';
import { GetAssetsQuery } from '__generated__/graphql';

export interface CEBProductFlags {
  hasCitatorAccess?: boolean;
  hasPractitionerTrial?: boolean;
  hasSecondarySourcesTrial?: boolean;
  hasPractitionerAllAccess?: boolean;
  hasSecondarySourcesAllAccess?: boolean;
  hasPractitionerVisible?: boolean;
  hasOnlawBeta?: boolean;
  hasSecondarySourcesProducts?: boolean;
  hasOnlawProducts?: boolean;
  hasCommunityProduct?: boolean;
  trialProduct?: NonNullable<GetAssetsQuery['assets']>[number];
  hasPracticeAreas?: boolean;
  hasSecondarySources?: boolean;
  hasLocalRulesOfCourt?: boolean;
  hasFormsAndMatters?: boolean;
  hasProductCode?: (regex: RegExp) => boolean;
}

export const practitioner14DayTrialCode = '00ftpr0000';
export const practitioner7DayTrialCode = '00ftpr0007';
export const secondarySourcesTrialCode = '00ftop0000';
export const practitionerAllAccessCode = '13plxx0000';
export const secondarySourcesAllAccessCode = '12mall0000';

export function useProductFlags(
  assets: NonNullable<GetAssetsQuery['assets']>,
): CEBProductFlags {
  return useMemo(() => {
    const hasCitatorTrial =
      hasProductCode(assets, practitioner14DayTrialCode) ||
      hasProductCode(assets, practitioner7DayTrialCode) ||
      hasProductCode(assets, secondarySourcesTrialCode);
    const hasPractitionerTrial =
      hasProductCode(assets, practitioner14DayTrialCode) ||
      hasProductCode(assets, practitioner7DayTrialCode);
    const hasSecondarySourcesTrial =
      hasPractitionerTrial || hasProductCode(assets, secondarySourcesTrialCode);
    const hasPractitionerAllAccess = hasProductCode(
      assets,
      practitionerAllAccessCode,
    );
    const hasSecondarySourcesAllAccess = hasProductCode(
      assets,
      secondarySourcesAllAccessCode,
    );
    const hasPractitionerVisible =
      hasProductCode(assets, /^13/) ||
      hasProductCode(assets, practitioner14DayTrialCode, false) ||
      hasProductCode(assets, practitioner7DayTrialCode, false);
    const hasOnlawBeta = hasProductCode(assets, 'FEATUREFLAG-ONLAWBETA');
    const hasSecondarySourcesProducts = hasProductCode(assets, /^15.+/);
    const hasOnlawProducts = hasProductCode(assets, /^89/);
    const hasCitatorAccess =
      hasProductCode(assets, '11plcx0000') || hasCitatorTrial;
    const hasCommunityProduct =
      hasProductCode(assets, '01cepi0024') ||
      hasProductCode(assets, '01epix0024') ||
      hasProductCode(assets, '55ppxx0000') ||
      hasProductCode(assets, '01rpic2300') ||
      hasProductCode(assets, '02ucxx0000');
    const hasLocalRulesOfCourt = hasProductCode(assets, '16lrcx0000');
    const hasFormsAndMatters = hasProductCode(assets, '95tffl0000');

    return {
      hasCitatorAccess,
      hasPractitionerTrial,
      hasSecondarySourcesTrial,
      hasPractitionerAllAccess,
      hasSecondarySourcesAllAccess,
      hasPractitionerVisible,
      hasOnlawBeta,
      hasSecondarySourcesProducts,
      hasOnlawProducts,
      hasCommunityProduct,
      trialProduct:
        getProducts(assets, practitioner14DayTrialCode, false)[0] ||
        getProducts(assets, practitioner7DayTrialCode, false)[0] ||
        getProducts(assets, secondarySourcesTrialCode, false)[0],
      hasPracticeAreas: hasProductCode(assets, /^13.+/),
      hasSecondarySources:
        hasSecondarySourcesProducts || hasSecondarySourcesTrial,
      hasProductCode: regex => hasProductCode(assets, regex),
      hasLocalRulesOfCourt,
      hasFormsAndMatters,
    };
  }, [assets]);
}
