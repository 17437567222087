import { useMemo } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { TermsAndConditionsDocument } from '__generated__/graphql';

function useTerms() {
  const { data } = useSuspenseQuery(TermsAndConditionsDocument);
  return useMemo(() => data?.contentfulSimplePage, [data]);
}

export default useTerms;
