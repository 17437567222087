import React from 'react';

export default function useDropdown(
  defaultState,
  timeout = 0,
  containerCheck = true,
) {
  const containerRef = React.useRef(null);
  const [dropdown, setDropdown] = React.useState(defaultState);

  const toggle = (event, value) => {
    if (event) event.preventDefault();
    setDropdown(value !== undefined ? value : !dropdown);
  };

  const onBlur = event => {
    const target = event.relatedTarget || document.activeElement;
    setTimeout(function() {
      if (containerCheck) {
        if (containerRef.current && !containerRef.current.contains(target)) {
          setDropdown(false);
        }
      } else {
        setDropdown(false);
      }
    }, timeout);
  };

  return [dropdown, toggle, onBlur, containerRef, setDropdown];
}
