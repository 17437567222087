import flow from 'lodash/fp/flow';
import slice from 'lodash/fp/slice';
import findIndex from 'lodash/fp/findIndex';
import { nextWithSections } from './nextWithSections';
export function getNextSection(index, sections) {
  const sectionIndex = findIndex(item => item.index === index)(sections);
  return flow(
    slice(sectionIndex, sections.length),
    nextWithSections,
  )(sections);
}
