import { CEB_PORTAL_URL } from 'app-constants';
import getOr from 'lodash/fp/getOr';
import { getConstitutionUri } from 'util/statuteURL';
import caseUrl from './caseUrl';
import courtRuleUrl from 'util/courtRuleUrl';
import { getStatuteUri } from './getStatuteUri';

function getRefGlobal(target) {
  return (
    target.getAttribute('refglobal') || target.getAttribute('data-refglobal')
  );
}

export function getContentUri(element, fallback = true) {
  const caseUrlId = getOr(null, 'dataset.caseUrlId')(element);

  const constitutionArticle = getOr(null, 'dataset.statuteArticle')(element);

  const statuteJdx = getOr(null, 'dataset.statuteJdx')(element);

  const refglobal = getRefGlobal(element);

  if (caseUrlId) {
    return caseUrl(caseUrlId);
  }

  if (constitutionArticle) {
    return getConstitutionUri(element);
  }

  if (statuteJdx) {
    return getStatuteUri(element);
  }

  if (refglobal && refglobal.match(/rule:\/ca\/crc\/.*/)) {
    const ruleId = getRefGlobal(element)
      ?.replace(/rule:\/ca\/crc\//, '')
      ?.split(/[^0-9.]+/, 1)[0]
      ?.replace(/\./g, '_');

    if (ruleId) return courtRuleUrl('CRC_rule_' + ruleId);
  }

  if (refglobal && refglobal.match(/rule:\/ca\/crc(sja|esnaca)\/.*/)) {
    const standardId = getRefGlobal(element)
      ?.replace(/rule:\/ca\/crc(sja|esnaca)?\//, '')
      ?.split(/[^0-9.]+/, 1)[0]
      ?.replace(/\./g, '_');

    if (standardId) return courtRuleUrl('CRC_standard_' + standardId);
  }

  if (fallback && element.href) {
    return element.href.replace(
      new RegExp(`(https?://research.ceb.com|${CEB_PORTAL_URL})`, 'gi'),
      '',
    );
  }
}
