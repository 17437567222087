import { finder } from 'util/finder';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';

export const elementToHeading = defaultTitle => element => {
  const find = finder(element);
  const heading = element.querySelector(':scope > .heading');
  const title =
    (element.dataset && element.dataset.title) ||
    element.getAttribute('data-title') ||
    (heading ? heading.getAttribute('value') : defaultTitle);
  const sections = find(':scope > section');
  const children = flow(
    map(elementToHeading('Header')),
    filter(a => a !== null),
  )(sections);
  const level = heading ? heading.getAttribute('level') : undefined;
  const firstChildWithId = element.querySelector('*[id]');
  const id = element.id || (firstChildWithId && firstChildWithId.id);
  return [
    {
      title,
      element,
      level,
      id,
    },
  ].concat(children);
};
