import React from 'react';
import styled from 'styled-components';

export const SectionRange = ({ sectionBegin, sectionEnd }) => {
  return sectionBegin ? (
    <StyledSectionRange>
      {sectionBegin && sectionBegin !== sectionEnd
        ? `${sectionBegin} - ${sectionEnd}`
        : sectionBegin}
    </StyledSectionRange>
  ) : null;
};

const StyledSectionRange = styled.span`
  flex: 0 1 auto;
  padding-left: 2em;
  white-space: nowrap;
`;
