import React, { Suspense } from 'react';
import { HistoryIcon } from 'components/common/icons';
import useDropdown from 'hooks/useDropdown';
import { HistoryPanel } from 'components/history-panel';
import styled, { css } from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function HistoryButton() {
  const [dropdown, toggle] = useDropdown();
  const close = React.useCallback(e => dropdown && toggle(e), [
    dropdown,
    toggle,
  ]);
  return (
    <>
      <StyledHistoryButton to="/" onClick={toggle} $open={dropdown}>
        <HistoryIcon />
      </StyledHistoryButton>
      {dropdown ? (
        <Suspense fallback={null}>
          <HistoryPanel close={close} />
        </Suspense>
      ) : null}
    </>
  );
}

const StyledHistoryButton = styled.button`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  svg {
    opacity: 0.75;
  }
  ${({ $open }) =>
    $open &&
    css`
      background: white;
      z-index: 101;
      position: relative;
      &:focus {
        outline: 0 !important;
      }
      svg {
        opacity: 1;
        g {
          stroke: ${CEB_COLOR('BLACK')};
        }
      }
    `}
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
