import { matchPaths } from 'components/content-renderer/getContentRoute';
import { SearchContext } from 'components/layouts/research-layout/SearchContext';
import React from 'react';
import { matchPath, useLocation } from 'react-router';

export function useSearchTitleForPathname(pathOverride) {
  const { pathname } = useLocation();
  return React.useMemo(() => {
    const path = pathOverride ? pathOverride : pathname;
    if (matchPaths(['/', '/news/*', '/posts/*'], path)?.length > 0)
      return 'Search DailyNews';
    if (matchPath('/primary-law/cases/*', path)) return 'Search Cases';
    if (matchPath('/primary-law/statutes/*', path)) return 'Search Statutes';
    if (matchPath('/primary-law/rules/*', path)) return 'Search Rules of Court';
    if (matchPath('/secondary-sources/*', path))
      return 'Search Secondary Sources';
    if (matchPath('/practitioner/*', path))
      return 'Search Practitioner Documents';
    if (matchPath('/learning/*', path)) return 'Search CLE Courses';
    if (matchPath('/documents_and_forms/*', path))
      return 'Search Documents & Forms';
    return 'Search';
  }, [pathname, pathOverride]);
}

export function useSearchPageTitle() {
  const { queryString } = React.useContext(SearchContext);

  const searchTitle = useSearchTitleForPathname();
  return React.useMemo(() => {
    return `${
      queryString.query ? `${queryString.query} - ` : ''
    }${searchTitle}`;
  }, [queryString, searchTitle]);
}
