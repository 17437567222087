import React from 'react';
import { StatuteCodeContent } from 'components/statutes/codes/StatuteCodeContent';
import { StatuteSidebar } from 'components/statutes/StatuteSidebar';
import { WithSidebar } from 'components/layouts/WithSidebar';
import {
  ContentRendererContext,
  TabbableLocationContext,
} from '../preview-tabs/PreviewPanel';
import useHistory from 'hooks/useHistory';
import { HISTORY_MAP, HISTORY_TYPE_MAP } from 'components/history-panel';
import { useLocation } from 'react-router';

export function StatutePreview() {
  const { data } = React.useContext(ContentRendererContext);
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);
  const { location: referrer } = useLocation();

  const { mutateHistory, recentHistoryLoading } = useHistory(referrer);

  const { text } = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    if (!data?.code || !data?.codeSection) return {};
    return {
      text: `${data.code.name} (${
        data.codeSection.title
      }) - California Code ${currentYear}`,
      title: `${data.code.name} (${data.codeSection.title})`,
    };
  }, [data.code, data.codeSection]);

  React.useEffect(() => {
    if (!text || recentHistoryLoading) return;
    mutateHistory({
      variables: {
        text,
        type: HISTORY_TYPE_MAP.STATUTE,
        action: 'VIEW',
        subtype: HISTORY_MAP.VIEW_STATUTE_RULE,
      },
    });
  }, [text, recentHistoryLoading]);

  return (
    <WithSidebar collapsible defaultCollapsed={true}>
      <StatuteSidebar
        jurisdictionId={params.jurisdictionId}
        codeJudicataId={params.codeJudicataId}
        statute={data.statute}
        code={data.code}
        codeSection={data.codeSection}
      />
      <StatuteCodeContent
        code={data.code}
        codeSection={data.codeSection}
        content={data.codeSection.content}
        terms={data.codeSection.searchHighlightTerms}
      />
    </WithSidebar>
  );
}
