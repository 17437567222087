import React from 'react';
import styled from 'styled-components';
import StatusMessage from '../common/StatusMessage';

export function BlankSlate({ loading }) {
  return (
    <StyledBlankSlate>
      <StatusMessage icon="fa-history" className="history-blank-slate">
        {loading ? 'Loading...' : 'No recent history.'}
      </StatusMessage>
    </StyledBlankSlate>
  );
}

const StyledBlankSlate = styled.div`
  min-width: 320px;
  flex: 1;
  > .history-blank-slate {
    padding: 0;
    min-height: auto;
  }
`;
