import React, { useState } from 'react';

import { getCurrentSection } from './getCurrentSection';

export default function useContentScroll(
  sections,
  initialSectionIndex,
  topOffset,
) {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(
    initialSectionIndex,
  );
  const onScroll = React.useCallback(
    event => {
      const { scrollTop } = event.target;
      const currentSection = getCurrentSection({
        scrollTop,
        topOffset: isNaN(topOffset) ? topOffset.current : topOffset,
      })(sections);
      if (currentSection && currentSection.index !== currentSectionIndex) {
        setCurrentSectionIndex(currentSection.index);
      }
    },
    [sections, topOffset, currentSectionIndex],
  );

  return [onScroll, currentSectionIndex, setCurrentSectionIndex];
}
