import React from 'react';
import ReactDOM from 'react-dom';

import Loading from 'components/common/Loading';
import { Header } from './Header';
import { Footer } from './Footer';
import { createGlobalStyle } from 'styled-components';
import {
  removeSequentiallyLoadedScripts,
  sequentiallyLoadScripts,
} from 'components/account/billing/sequentiallyLoadScripts';
import { EnvironmentBanner } from '../EnvironmentBanner';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

const scripts = [
  {
    type: 'text/javascript',
    src: '/static/jquery.min.js',
    id: 'jquery-core-js',
  },
  {
    type: 'text/javascript',
    src: '/static/sage-main.js',
    id: 'sage/main.js-js',
  },
];

export const PublicTwentyThree = ({
  hideNav,
  showFooter,
  white,
  overrideVisited,
  ...props
}) => {
  React.useEffect(() => {
    sequentiallyLoadScripts(scripts);

    return () => {
      removeSequentiallyLoadedScripts(scripts);
    };
  }, []);

  // helmet is broken in react 18+
  React.useEffect(() => {
    const bodyEl = document.querySelector('body');
    const headEl = document.querySelector('head');
    bodyEl.setAttribute(
      'class',
      'page-template page-template-template-icon-header template-icon-header page page-id-143 page-parent page-child parent-pageid-137 antialiased text-black onlaw app-data index-data singular-data page-data page-143-data page-onlaw-data template-icon-header-data cookies-not-set',
    );
    const linkEl = document.createElement('link');
    linkEl.setAttribute('href', '/static/sage-main.css');
    linkEl.setAttribute('rel', 'stylesheet');
    headEl.appendChild(linkEl);
  }, []);

  return ReactDOM.createPortal(
    <>
      <EnvironmentBanner />
      <div className="min-h-screen bg-white">
        <Header />
        <div id="root">
          <Loading>{props.children}</Loading>
        </div>
        <Footer />
      </div>
      <GlobalStyleOverwrites
        backgroundColor={white ? undefined : CEB_COLOR('VERY_LIGHT_PINK')}
        overrideVisited={overrideVisited}
      />
    </>,
    document.body,
  );
};

const GlobalStyleOverwrites = createGlobalStyle`
  html, body {
    height: auto !important;
    width: auto !important;
    font-family: "BasisGrotesque", sans-serif !important;

    ${props =>
      !props.overrideVisited &&
      `
      a:visited{
        color: inherit;
      }
    `}

    @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      height: auto !important;
      width: auto !important;
    }
  }
  
  body > #root{
    display: none;
  }

  #root{
    width: auto !important;
    height: auto !important;
    padding-top: 100px;
    overflow: visible;
    .container{
      margin: auto;
    }
  }

  .site-footer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  body > .bg-white{
    ${props =>
      props.backgroundColor &&
      `background-color: ${props.backgroundColor} !important;`}
  }

  .min-h-screen{
    display: flex;
    flex-direction: column;
    > *:first-child{
      flex: 0 1 auto;
    }
    > * {
      flex: 1 1 auto;
    }
    > *last-child{
      flex: 0 1 auto;
    }
  }
  .site-menu__submenu-wrap,
  .site-menu__submenu-content {
    background: white;
  }
  .site-menu__button--primary {
    &:visited{
      color: white;
    }
  }
`;
