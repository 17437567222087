import React, { useContext } from 'react';
import { ModalOverlay } from 'components/common/modal-overlay';
import { NewTandC } from './NewTandC';
import dayjs from 'dayjs';
import { AppContext } from 'components/app-context';

function TermsAndConditions({ terms, visible }) {
  return (
    <>
      {visible ? (
        <ModalOverlay
          visible={visible}
          backgroundOpacity={1}
          contentWidth={46}
          minWidth="662px"
          maxHeight="645px"
        >
          <NewTandC page={terms} termsDate={dayjs().format('MM/DD/YYYY')} />
        </ModalOverlay>
      ) : null}
    </>
  );
}

export default function() {
  const context = useContext(AppContext);
  return context.user ? (
    <TermsAndConditions terms={context.terms} visible={context.showTerms} />
  ) : null;
}
