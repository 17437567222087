import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import React from 'react';
import styled from 'styled-components';

export function EmptyFolder() {
  return (
    <StyledEmptyFolder>
      <i className="fa fa-folder-open" />
      This folder is empty
    </StyledEmptyFolder>
  );
}

const StyledEmptyFolder = styled.div`
  color: ${CEB_COLOR_RGBA('BLACK', 0.5)};
  font-size: 14px;
  font-family: 'BasisGrotesque', sans-serif;

  i {
    margin-right: 8px;
  }
`;
