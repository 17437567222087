import dayjs from 'dayjs';
import { useSuspendableQuery } from 'hooks/useSuspendableQuery';
import React from 'react';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import useDropdown from 'hooks/useDropdown';
import getFriendlyDate from 'util/getFriendlyDate';
import { DateSelectCalendar } from './DateSelectCalendar';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { GetHistoryDatesDocument } from '__generated__/graphql';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const StyledDownTriangle = styled.i.attrs(
  ({ color = '#343434', width = 5, height = 5 }) => ({
    width,
    color,
    height,
  }),
)`
  width: 0px;
  height: 0px;
  border-left: ${({ width }) => width}px solid transparent;
  border-right: ${({ width }) => width}px solid transparent;
  border-top: ${({ height }) => height}px solid ${({ color }) => color};
`;

export function HistoryDateSelect({ onChange }) {
  const {
    data: { historyDates },
  } = useSuspendableQuery(GetHistoryDatesDocument);

  const [selected, setSelected] = React.useState(historyDates[0]);
  const [selectedMonth, setSelectedMonth] = React.useState(
    dayjs(selected).startOf('month'),
  );

  const dates = historyDates
    .map(date => dayjs(date, 'YYYY-MM-DD'))
    .filter(dj => dj.isSame(selectedMonth, 'month'));

  const onChangeDate = React.useCallback(e => {
    e.stopPropagation();
    const date = e.target.dataset.startDate;
    const startDate = dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD');

    setSelected(date);

    toggle();

    onChange({ startDate });
  });

  React.useEffect(() => {
    if (historyDates) {
      onChange({ startDate: historyDates[0] });
    }
  }, [historyDates]);

  const [dropdown, toggle, onBlur, dropdownContainer] = useDropdown();

  React.useEffect(() => {
    setSelectedMonth(dayjs(selected).startOf('month'));
  }, [dropdown]);

  return historyDates?.length > 0 ? (
    <StyledHistoryDateSelect>
      <button className="date-select" onClick={toggle}>
        <i className="fa"></i>
        <span>{getFriendlyDate(dayjs(selected, 'YYYY-MM-DD'))}</span>{' '}
        <StyledDownTriangle width="5" height="5" />
      </button>
      {dropdown && (
        <div className="dates-calendar-container">
          <section className="dates-menu" ref={dropdownContainer}>
            {dates.length > 0 ? (
              dates.map(dj => {
                const startDate = dj.format('YYYY-MM-DD');
                return (
                  <button
                    className={selected === startDate ? 'selected' : null}
                    onClick={onChangeDate}
                    data-start-date={startDate}
                    key={startDate}
                  >
                    {getFriendlyDate(dj)}
                  </button>
                );
              })
            ) : (
              <h5>
                No history activity
                <br />
                for this month
              </h5>
            )}
          </section>
          <section className="dates-calendar">
            <DateSelectCalendar
              onChangeDate={onChangeDate}
              selected={selected}
              historyDates={historyDates}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
            />
          </section>
        </div>
      )}
    </StyledHistoryDateSelect>
  ) : null;
}

const StyledHistoryDateSelect = styled.div`
  position: relative;
  margin-bottom: 9px;

  button.date-select {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #343434;
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    font-style: italic;
    font-weight: bold;
    .fa {
      font-size: 18px;
    }
    span {
      margin: 0 8px;
    }
  }
  .dates-calendar-container {
    background-color: #fafaf9;
    border-radius: 3px;
    border: solid 1px #e4e4e2;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
    color: #343434;
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 260px;
    box-sizing: border-box;
    top: 1.5em;
    z-index: 10;
    section {
      display: flex;
      box-sizing: border-box;
    }
  }
  .dates-calendar {
    padding: 12px;
    box-sizing: border-box;
  }
  .dates-menu {
    flex-direction: column;
    left: 0;
    min-width: 230px;
    max-width: 230px;
    overflow-y: auto;
    padding: 12px;
    h5 {
      color: ${CEB_COLOR_RGBA('BLACK', 0.5)};
      padding: 12px;
      margin-top: 72px;
      font-weight: normal;
      text-align: center;
    }
    button {
      border-radius: 2px;
      color: #343434;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      line-height: 2.14;
      margin-bottom: 2px;
      padding: 0 8px;
      text-align: left;
      &.selected {
        background-color: ${CEB_COLOR('COOL_BLUE')};
        font-weight: bold;
        color: white;
      }
      &:hover {
        background-color: ${CEB_COLOR_RGBA('COOL_BLUE', 0.2)};
      }
    }
  }
`;
