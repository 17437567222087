import React from 'react';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { Footer } from 'components/footer';
import { RestorableScroll } from './RestorableScroll';
import { useIsRawLayout } from './useIsRawLayout';

export interface WithFooterProps {
  flex?: any;
  children: React.ReactNode;
}

export const WithFooter = React.forwardRef<HTMLElement, WithFooterProps>(
  ({ flex, children, ...props }, ref) => {
    const raw = useIsRawLayout();
    const elementRef = React.useRef<HTMLElement>(null);
    return (
      <StyledWithFooter
        {...props}
        ref={ref || elementRef}
        data-layout={`${!raw ? '' : 'raw-'}with-footer`}
      >
        <section>{children}</section>
        {!raw && <Footer />}
      </StyledWithFooter>
    );
  },
);
WithFooter.displayName = 'WithFooter';

const StyledWithFooter = styled(RestorableScroll)`
  > footer {
    display: none;
  }
  @media print {
    display: block;
  }
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    grid-template-areas:
      'main'
      'footer';
    height: 100%;
    overflow-y: auto;
    > section {
      grid-area: main;
    }
    > footer {
      grid-area: footer;
      display: block;
    }
  }
`;
