import React from 'react';
import qs from 'util/qs';
import { TabContext } from 'components/app-context/TabContextProvider';
import { useNavigateWithState } from 'components/routes/RedirectWithState';
import useQueryString from 'hooks/useQueryString';
import { matchPath } from 'react-router';

export function useSearchQueryNavigation() {
  const { dispatch } = React.useContext(TabContext) || {};
  const navigate = useNavigateWithState();
  const { yourPracticeAreas } = useQueryString();

  return React.useCallback(
    ({ query, type }: { query?: string; type?: string }) => {
      dispatch({ type: 'MINIMIZE_TAB' });
      const isLearning = matchPath('/learning/*', type!);
      navigate(
        `${isLearning ? '/learning/courses' : type}/search?${qs.stringify({
          query,
          ...(isLearning && yourPracticeAreas !== undefined
            ? { yourPracticeAreas }
            : {}),
        })}`,
      );
    },
    [dispatch, navigate, yourPracticeAreas],
  );
}
