import React from 'react';

export function useHeapIdentify(user, assetProperties) {
  React.useEffect(() => {
    if (window.heap && user) {
      window.heap.identify(user.email);

      window.heap.addUserProperties({
        accountId: user?.accountId,
        engagementLevel: user?.engagementLevel,
        purchasedProductTypes: user?.productTypesPurchased,
        purchasedPracticeAreas: user?.productPracticeAreasPurchased,
        secondaryPracticeAreas: user?.secondaryPracticeAreas?.selected,
        organizationType: user?.organizationType?.value,
        userRole: user?.userRole?.value,
        organizationId: user?.firm?.id,
        ...assetProperties,
      });
    }
  }, [user]);
}
