import React from 'react';
import { AppContext } from 'components/app-context';
import { ENABLE_HISTORY_FEATURE } from 'app-constants';
import { FreeTrialNav } from './FreeTrialNav';
import { useAssetAccess } from 'components/asset-access';
import dayjs from 'dayjs';
import classSet from 'react-classset';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { BookmarkButton } from './BookmarkButton';
import { HistoryButton } from './HistoryButton';
import { UserDropdownMenu } from './UserDropdownMenu';

export const LoggedIn = ({ logout, hideHistoryAndBookmarks }) => {
  const { localUser } = React.useContext(AppContext) || {};

  const assetAccess = useAssetAccess();

  const freeTrialDays = assetAccess?.trialProduct?.quantity;
  const usageEndDate = assetAccess?.trialProduct?.usageEndDate;
  const hideTrialAlert =
    // if the user doesn't have a free trial product or
    !assetAccess?.trialProduct ||
    // if the user has subsequently purchased a paid onlaw product or
    assetAccess?.hasOnlawProducts ||
    // if the user has subsequently purchased a paid secondary sources product or
    assetAccess?.hasPractitionerAllAccess ||
    // if the user has subsequently purchased a practitioner all access product or
    assetAccess?.hasSecondarySourcesProducts ||
    // if the user has subsequently purchased a secondary sources all access product or
    assetAccess?.hasSecondarySourcesAllAccess ||
    // if it has been more than two weeks since it expired
    (usageEndDate && dayjs(usageEndDate).add(14, 'day').isBefore(dayjs()));

  const showHistoryAndBookmarks =
    ENABLE_HISTORY_FEATURE && !localUser.ipSession && !hideHistoryAndBookmarks;

  return (
    <StyledLoggedInUserNavList
      className={classSet({ 'free-trial': !hideTrialAlert })}
    >
      {showHistoryAndBookmarks ? (
        <>
          <li className="bookmark-nav">
            <BookmarkButton />
          </li>
          <li className="history-nav">
            <HistoryButton />
          </li>
        </>
      ) : null}
      <UserDropdownMenu logout={logout} />
      {!hideTrialAlert && <FreeTrialNav days={freeTrialDays} />}
    </StyledLoggedInUserNavList>
  );
};

const StyledLoggedInUserNavList = styled.ul`
  display: flex;
  flex: 0 1 auto;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 16px;
  justify-content: flex-end;
  line-height: 1.2em;
  list-style: none;
  margin-left: auto;
  text-align: right;
  > li {
    display: block;
    flex: 0 1 auto;
    text-align: right;
    white-space: nowrap;
    &:first-child {
      padding-left: 12px;
    }
    > a,
    > button {
      display: block;
      padding: 20px;
      letter-spacing: 0.025em;
      color: #fff;
      font-family: 'BasisGrotesque', sans-serif;
      &:focus {
        outline: 1px solid ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      }
    }
    > button {
      padding: 10px 15px 20px;
    }
    > a.active {
      background: ${CEB_COLOR('NICE_BLUE')};
    }

    &.history-nav,
    &.bookmark-nav {
      width: 50%;
      display: inline-block;
      position: relative;
      padding-top: 10px;
      > a,
      > button {
        width: 100%;
      }
    }
    &.history-nav {
      display: block;
    }
  }
`;
