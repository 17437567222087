import React from 'react';
import { PillDropdown } from './PillDropdown';
import { PillButton } from './PillButton';
import colorForAttitude from 'util/colorForAttitude';
import transparentizeRGBA from 'util/transparentizeRGBA';
import useDropdown from 'hooks/useDropdown';

export const PillButtonDropdown = props => {
  const [dropdown, toggle, onBlur, dropdownRef] = useDropdown(false);
  const color = colorForAttitude(props.attitude);
  const handleBlur = React.useCallback(event => {
    const target = event.relatedTarget || document.activeElement;
    if (target.classList.contains('overlay-container')) return;
    onBlur(event);
  });
  return (
    <>
      <PillButton
        meta={props.count}
        color={color}
        onClick={toggle}
        onBlur={handleBlur}
        aria-expanded={dropdown}
        aria-haspopup="true"
      >
        {props.title}
      </PillButton>
      {dropdown ? (
        <PillDropdown ref={dropdownRef} onBlur={handleBlur}>
          {props.children}
        </PillDropdown>
      ) : null}
    </>
  );
};
