import React from 'react';
import styled from 'styled-components';

export function CircleIcon({
  icon = 'info',
  size = 24,
  bgcolor = 'white',
  color = 'inherit',
  ...props
}) {
  return (
    <StyledCircleIcon size={size} bgcolor={bgcolor} color={color}>
      <i className={`fa fa-${icon}`} />
    </StyledCircleIcon>
  );
}

const StyledCircleIcon = styled.span.attrs(({ bgcolor, color, size }) => ({
  bgcolor,
  color,
  size,
}))`
  background: ${({ bgcolor }) => bgcolor};
  border-radius: ${({ size }) => size}px;
  color: ${({ color }) => color};
  display: inline-block;
  height: ${({ size }) => size}px;
  line-height: 1.5;
  text-align: center;
  width: ${({ size }) => size}px;
`;
