import React from 'react';
import useResizeObserver from 'hooks/useResizeObserver';
import classSet from 'classnames';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { useIsRawLayout } from './useIsRawLayout';
import { RestorableScroll } from './RestorableScroll';

export const SidebarContext = React.createContext({});

export interface WithSidebarProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
}

export const WithSidebar = React.forwardRef<HTMLElement, WithSidebarProps>(
  (
    { children, collapsible, defaultCollapsed, ...props }: WithSidebarProps,
    ref,
  ) => {
    const raw = useIsRawLayout();

    const childrenArray = React.Children.toArray(children);
    const [sidebar, ...content] = childrenArray;

    const sidebarCollapsed =
      collapsible && typeof window !== 'undefined'
        ? JSON.parse(localStorage.getItem('sidebarCollapsed') || 'false')
        : false;

    const [collapsed, setCollapsed] = React.useState<boolean>(
      typeof defaultCollapsed !== 'undefined'
        ? defaultCollapsed
        : sidebarCollapsed,
    );

    const [buttonOffset, setButtonOffset] = React.useState<string | number>(
      -36,
    );

    const toggleSidebar = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        const newSidebarCollapsed = !collapsed;
        typeof defaultCollapsed === 'undefined' &&
          localStorage.setItem(
            'sidebarCollapsed',
            JSON.stringify(newSidebarCollapsed),
          );
        setCollapsed(newSidebarCollapsed);

        const buttonEl = e.target as HTMLElement;

        const button =
          buttonEl.tagName.toLowerCase() === 'button'
            ? buttonEl
            : buttonEl.parentElement!;

        button.blur();
      },
      [defaultCollapsed, setCollapsed, collapsed],
    );

    const [sidebarRef, { contentRect }] = useResizeObserver();

    React.useLayoutEffect(() => {
      const { width } = contentRect || {};
      if (!width) return;
      setButtonOffset(contentRect ? `${width - 18}px` : '18px');
    }, [contentRect, collapsed]);

    const className = classSet({
      collapsed,
      [props.className || '']: props.className !== undefined,
    });

    const StyledSidebarComponent = collapsible
      ? StyledCollapsibleWithSidebar
      : StyledWithSidebar;

    return (
      <SidebarContext.Provider value={{ toggleSidebar, setCollapsed }}>
        <StyledSidebarComponent
          {...props}
          ref={ref}
          className={className}
          data-layout={`${!raw ? '' : 'raw-'}with-sidebar`}
          $collapsed={collapsed}
          $buttonOffset={buttonOffset}
        >
          <RestorableScroll className="main" id="main-restorable-scroll">
            {content}
          </RestorableScroll>
          {raw ? null : (
            <>
              <RestorableScroll
                className="sidebar"
                ref={sidebarRef}
                id="sidebar-restorable-scroll"
              >
                {sidebar}
              </RestorableScroll>
              {collapsible && (
                <button onClick={toggleSidebar}>
                  <i className="fas fa-arrow-down" />
                </button>
              )}
            </>
          )}
        </StyledSidebarComponent>
      </SidebarContext.Provider>
    );
  },
);
WithSidebar.displayName = 'WithSidebar';

interface StyledWithSidebarProps {
  $collapsed: boolean;
  $buttonOffset: string | number;
}

const StyledWithSidebar = styled.section<StyledWithSidebarProps>`
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'sidebar main';
    height: 100%;
    width: 100%;
    > .main {
      box-sizing: border-box;
      grid-area: main;
      height: 100%;
      overflow: auto;
      > section {
        -webkit-overflow-scrolling: touch;
        position: relative;
      }
    }
    > .sidebar {
      grid-area: sidebar;
      height: 100%;
      overflow: auto;
    }

    > .sidebar > *:first-child:last-child {
      min-height: 100%;
    }
  }
`;

export const WithRightSidebar = styled(WithSidebar)`
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr;
    grid-template-areas: 'main sidebar';
    column-gap: 24px;
  }
`;

const StyledCollapsibleWithSidebar = styled(StyledWithSidebar)`
  > button {
    display: none;
  }
  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    &.collapsed {
      > .sidebar {
        background-color: ${CEB_COLOR('CARARRA')};
        max-width: 36px;
        min-width: 36px;
        overflow: hidden;
        header > h4,
        header > h3 {
          text-indent: 40px;
          white-space: nowrap;
          > button,
          > i {
            color: ${CEB_COLOR('DOVE_GRAY')};
            display: inline-block;
            left: 10px;
            position: absolute;
            text-indent: 0;
          }
          > button i {
            display: inline;
          }
        }

        > div,
        > section {
          max-width: 36px;
          min-width: 36px;
          overflow: hidden;
          ul,
          ol,
          div {
            display: none;
          }
        }
      }
    }

    > button {
      background: ${CEB_COLOR('VERY_LIGHT_PINK')};
      border-radius: 18px;
      border: solid 1px #e0e0de;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      color: ${CEB_COLOR('BLACK')};
      display: block;
      font-size: 17px;
      height: 36px;
      margin-top: 36px;
      line-height: 36px;
      left: ${({ $collapsed, $buttonOffset }) =>
        $collapsed ? '18px' : $buttonOffset};
      position: absolute;
      width: 36px;
      z-index: 11;
      &:focus {
        outline: 0;
        box-shadow: 0 2px 10px 0 ${CEB_COLOR_RGBA('DANUBE', 0.75)};
      }
      i {
        opacity: 0.6;
        transform: rotate(${({ $collapsed }) => ($collapsed ? '-' : '')}90deg);
      }
    }
  }
`;
