import { pick } from 'lodash/fp';

export function locationToPath({ pathname, search, hash }) {
  return [pathname, search, hash].filter(Boolean).join();
}

export function getLocationFromPath(path) {
  const uriPath = typeof path === 'string' ? path : locationToPath(path);
  const link = document.createElement('a');
  link.setAttribute('href', uriPath);
  return pick(
    [
      'hash',
      'host',
      'hostname',
      'href',
      'origin',
      'pathname',
      'port',
      'protocol',
      'search',
    ],
    link,
  );
}
