import React from 'react';
import { StatuteHeader } from '../StatuteHeader';
import { useAssetAccess } from 'components/asset-access';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { constURL } from 'util/statuteURL';
import { SectionTab, SectionTabs } from '../SectionTabs';

export const ArticleCitationHeader = ({
  title,
  article,
  citationCount,
  judicataId,
}) => {
  const { hasCitatorAccess } = useAssetAccess();
  const articleUrl = constURL(article, judicataId);
  const citationUri = `${articleUrl}/citator`;
  return (
    <StatuteHeader title="California Constitution" subtitle={title}>
      <SectionTabs>
        <SectionTab>
          <Link to={articleUrl} end>
            Section Text
          </Link>
        </SectionTab>
        {hasCitatorAccess && (
          <SectionTab>
            <Link to={citationUri} end>
              Citing Cases <span>({citationCount})</span>
            </Link>
          </SectionTab>
        )}
      </SectionTabs>
    </StatuteHeader>
  );
};
