import React from 'react';
import { DeleteConfirmationDialog } from './DeleteBookmark';
import GET_BOOKMARKS from './graphql/getBookmarks';
import { useSuspendableQuery } from 'hooks/useSuspendableQuery';

export const DeleteFolderConfirmationDialog = React.forwardRef(
  ({ confirm, cancel, folder }, ref) => {
    const {
      data: { bookmarks },
    } = useSuspendableQuery(GET_BOOKMARKS, {
      variables: { folderId: folder?.id },
    });

    return (
      <DeleteConfirmationDialog
        confirm={confirm}
        cancel={cancel}
        title="Delete this Folder?"
        message={
          bookmarks.length > 0
            ? `The ${
                bookmarks.length
              } bookmarks in this folder will also be deleted`
            : undefined
        }
        ref={ref}
      />
    );
  },
);
