import React from 'react';
import styled from 'styled-components';
import { useContentQuery } from '../content-renderer/useContentQuery';
import { PreviewPanelHeader } from './PreviewPanelHeader';
import { elementIsInView } from 'util/elementIsInView';
import { FOCUSABLES_SELECTOR } from 'app-constants/FOCUSABLES_SELECTOR';
import { findParents } from 'util/finder';
import { ContentRenderer } from '../content-renderer';
import { useLocation } from 'react-router';

export const ContentRendererContext = React.createContext({});
export const TabbableLocationContext = React.createContext({});

export function useSandboxedLocation() {
  const { location } = React.useContext(TabbableLocationContext) || {};
  return location;
}

export function useIsSandboxed() {
  return !!useSandboxedLocation();
}

export function PreviewPanel() {
  const {
    state: { tabState },
  } = useLocation();

  const { current: activeTab } = tabState;
  const { data, loading, location, match, variables } = useContentQuery(
    activeTab?.uri,
  );

  const panelRef = React.useRef();

  React.useLayoutEffect(() => {
    panelRef.current.focus();
    return () => {
      const initiator = document.querySelector(activeTab.initiator);
      if (initiator) {
        const nearestFocusableParent = initiator.matches(FOCUSABLES_SELECTOR)
          ? initiator
          : findParents(initiator)(FOCUSABLES_SELECTOR)[0];
        if (elementIsInView(nearestFocusableParent))
          nearestFocusableParent.focus();
      }
    };
  }, [activeTab]);

  return (
    <TabbableLocationContext.Provider value={{ location, match }}>
      {activeTab && (
        <ContentRendererContext.Provider
          value={{
            data,
            loading,
            variables,
          }}
        >
          <StyledPreviewPanel ref={panelRef} tabIndex={0}>
            <div>
              <PreviewPanelHeader />
              <section>{!loading && <ContentRenderer />}</section>
            </div>
          </StyledPreviewPanel>
        </ContentRendererContext.Provider>
      )}
    </TabbableLocationContext.Provider>
  );
}

const StyledPreviewPanel = styled.section`
  position: absolute;
  right: 0;
  top: 196px;
  bottom: 0;
  z-index: 1;
  width: 60vw;
  @media print {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:before,
  &:after {
    background-color: #fafafa;
    border-bottom-color: transparent;
    border: solid 1px #e4e4e2;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
  }
  &:before {
    height: calc(100% - 6px);
    left: -4px;
    top: 4px;
    z-index: 2;
  }

  &:after {
    height: calc(100% - 10px);
    left: -8px;
    top: 8px;
    z-index: 1;
  }
  > div {
    background-color: #fafafa;
    border: solid 1px #e4e4e2;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    height: calc(100% - 2px);
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    > section {
      flex: 1 1 auto;
      display: flex;
      footer {
        display: none;
      }
      [data-layout='with-sidebar'] {
        position: relative;
        > button {
          display: none;
        }
        .sidebar div > header button > i {
          display: inline;
        }
      }
      section.collapsed {
        .sidebar {
          background: transparent;
          div {
            background: transparent;
          }
        }
      }
      .sidebar {
        div {
          min-width: 0;
        }
      }
      + section {
        height: 1px;
      }
      &:first-child {
        flex: 0 1 auto;
      }
    }
  }
`;
