import { gql } from '@apollo/client';

const CREATE_BOOKMARK = gql`
  mutation createBookmark(
    $folderId: ID!
    $type: HistoryType!
    $title: String!
    $text: JSONObject!
    $path: String!
    $subtype: String
  ) {
    createBookmark(
      folderId: $folderId
      type: $type
      title: $title
      text: $text
      path: $path
      subtype: $subtype
    ) {
      id
      folderId
      type
      title
      text
      path
    }
  }
`;

export default CREATE_BOOKMARK;
