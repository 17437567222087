import React from 'react';

const History = props => (
  <svg width={20} height={17} {...props}>
    <g
      transform="translate(1 1)"
      stroke="#FFF"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect
        strokeWidth={1.5}
        x={0.57}
        y={0.19}
        width={12.375}
        height={10.125}
        rx={1.5}
      />
      <path d="M.57 3h12.375" />
      <path
        d="M15.195 3.565v7.875c0 .621-.504 1.125-1.125 1.125H3.945"
        strokeWidth={1.5}
      />
      <path
        d="M17.445 5.815v7.875c0 .621-.504 1.125-1.125 1.125H6.195"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);

export default History;
