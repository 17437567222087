import cond from 'lodash/fp/cond';
import flow from 'lodash/fp/flow';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import reverse from 'lodash/fp/reverse';
import isEmpty from 'lodash/fp/isEmpty';
import join from 'lodash/fp/join';
import constant from 'lodash/fp/constant';
import { notEmpty } from 'util/notEmpty';
import { escapeRegExp } from './escapeRegExp';

const { Finder } =
  typeof window !== 'undefined' ? require('findandreplacedomtext') : {};

export const markSearchTerms = (terms, content) => {
  return cond([
    [
      notEmpty,
      flow(
        sortBy('length'),
        reverse,
        map(escapeRegExp),
        join('|'),
        terms => `(\\b)((?:${terms})(?: (?:${terms}))*)(\\b)`,
        pattern => new RegExp(pattern, 'gi'),
        regexp => {
          return content => {
            if (typeof window !== 'undefined') {
              const contentParent = document.createElement('div');
              const contentNode = document
                .createRange()
                .createContextualFragment(content);

              const replace = new Finder(contentNode, {
                find: regexp,
                wrap: 'mark',
              });
              contentParent.appendChild(replace.node);
              return contentParent.innerHTML;
            }
          };
        },
      ),
    ],
    [isEmpty, terms => constant(content)],
  ])(terms)(content);
};
