export const CONTENT_PATHS = [
  // cases
  '/primary-law/:type/:judicataId',
  // statutes
  '/primary-law/:type/:jurisdictionId/codes/:codeJudicataId/:judicataIdBegin/:judicataIdEnd',
  '/primary-law/:type/:jurisdictionId/codes/:codeJudicataId/:judicataId',
  '/primary-law/:type/:jurisdictionId/codes/:codeJudicataId',
  // constitution
  '/primary-law/:type/:jurisdictionId/const/:article/:judicataId',
  '/primary-law/:type/:jurisdictionId/const/:article',
];
