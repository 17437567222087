import React from 'react';

const Shopping = props => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Header-Learning"
        transform="translate(-557.000000, -20.000000)"
        stroke="#FFFFFF"
        strokeWidth="1.5"
      >
        <g id="Group-3" transform="translate(182.000000, 0.000000)">
          <g id="Group-13-Copy-2" transform="translate(376.000000, 21.000000)">
            <g id="icon-store">
              <path
                d="M6,16.49975 C6,17.32825 5.3285,17.99975 4.5,17.99975 C3.6715,17.99975 3,17.32825 3,16.49975 C3,15.67125 3.6715,14.99975 4.5,14.99975 C5.3285,14.99975 6,15.67125 6,16.49975 L6,16.49975 Z"
                id="Stroke-1338"
              />
              <path
                d="M14,16.49975 C14,17.32825 13.3285,17.99975 12.5,17.99975 C11.6715,17.99975 11,17.32825 11,16.49975 C11,15.67125 11.6715,14.99975 12.5,14.99975 C13.3285,14.99975 14,15.67125 14,16.49975 L14,16.49975 Z"
                id="Stroke-1339"
              />
              <polyline id="Stroke-1342" points="18 0 15.5 0 12.5 15 4.5 15" />
              <polyline
                id="Stroke-1343"
                points="12.4217 11 2.33353333 11 0.0002 3 14.0002 3"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Shopping;
