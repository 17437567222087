import { gql, useMutation } from '@apollo/client';

const UPDATE_LOCAL_USER = gql`
  mutation UpdateLocalUser(
    $authorized: Boolean = false
    $ipSession: Boolean = false
    $token: String
    $returnUrl: String = "/"
    $postLoginRedirect: String
    $dontRefresh: Boolean = true
  ) {
    updateLocalUser(
      authorized: $authorized
      ipSession: $ipSession
      token: $token
      returnUrl: $returnUrl
      postLoginRedirect: $postLoginRedirect
      dontRefresh: $dontRefresh
    ) @client {
      token
    }
  }
`;

export default function useUpdateUser(options = {}) {
  const [fn] = useMutation(UPDATE_LOCAL_USER, options);
  return fn;
}
