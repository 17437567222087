import React from 'react';

const TrueCite = ({ width = 12, ...props }) => (
  <svg width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle fill="#438f78" className="cls-1" cx="3.62" cy="3.62" r="3.62" />
        <circle
          fill="#5698c9"
          className="cls-2"
          cx="12.38"
          cy="3.62"
          r="3.62"
        />
        <circle
          fill="#eaa222"
          className="cls-3"
          cx="3.62"
          cy="12.38"
          r="3.62"
        />
        <circle
          fill="#bb4c2a"
          className="cls-4"
          cx="12.38"
          cy="12.38"
          r="3.62"
        />
      </g>
    </g>
  </svg>
);

export default TrueCite;
