import React from 'react';
import useDropdown from 'hooks/useDropdown';
import isArray from 'lodash/fp/isArray';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export const HeaderActions = ({ onPrint, ...props }) => {
  const print = React.useCallback(
    e => {
      e.preventDefault();
      onPrint ? onPrint() : window.print();
    },
    [onPrint],
  );

  const [dropdown, toggle, onBlur, menuRef] = useDropdown(false);

  const downloadUrl = !isArray(props.downloadUrl)
    ? props.downloadUrl
    : props.downloadUrl.length === 1
    ? props.downloadUrl[0].url
    : null;

  const downloadUrls =
    isArray(props.downloadUrl) && props.downloadUrl.length > 1
      ? props.downloadUrl
      : null;

  return (
    <StyledHeaderActions className="header-actions">
      <li>
        <a href="#" onClick={print}>
          <i className="fa fa-print" />
          Print
        </a>
      </li>
      {downloadUrl ? (
        <li>
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-download" />
            Download
          </a>
        </li>
      ) : null}
      {downloadUrls ? (
        <li>
          <span
            href="#"
            onClick={toggle}
            onKeyPress={toggle}
            role="menu"
            tabIndex={0}
            onBlur={onBlur}
          >
            <i className="fa fa-download" />
            Download
          </span>
          {dropdown ? (
            <div className="download-menu" ref={menuRef}>
              <ul>
                {downloadUrls.map((item, i) => (
                  <li key={i}>
                    <a href={item.url} role="menuitem">
                      <i className={item.icon} />
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </li>
      ) : null}
    </StyledHeaderActions>
  );
};

const StyledHeaderActions = styled.ul`
  &.header-actions {
    color: ${CEB_COLOR('BLACK')};
    display: block;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 12px;
    line-height: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    > li {
      display: inline-block;
      margin: 0;
      padding: 0;
      position: relative;
    }
    @media print {
      display: none !important;
    }
    i {
      display: block;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 9px;
    }
    a,
    span {
      color: inherit;
      display: inline-block;
      font-family: inherit;
      font-size: inherit;
      font-weight: bold;
      padding: 10px;
      position: relative;
      text-transform: inherit;
      &:hover {
        cursor: pointer;
      }
    }
    span:focus {
      outline: 0;
      border-bottom: 1px solid ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      margin-bottom: -1px;
    }
    .download-menu {
      background-color: ${CEB_COLOR('DESERT_STORM')};
      background: white;
      border-radius: 3px;
      border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
      padding: 10px;
      position: absolute;
      right: 0;
      margin-top: -10px;
      z-index: 1;
      ul {
        text-align: left;
        list-style-type: none;
        i {
          display: inline-block;
          margin-right: 12px;
          vertical-align: -2px;
          margin-bottom: 0;
        }
        a {
          display: block;
          padding: 7px;
          text-transform: none;
        }
      }
    }
  }
`;
