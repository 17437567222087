import React, { useEffect, useLayoutEffect, useState } from 'react';
import { AppContext } from 'components/app-context';
import { Navigate, useLocation } from 'react-router';
import useUpdateUser from 'hooks/useUpdateUser';
import queryString from 'util/qs';
import uriStringify from 'util/uriStringify';
import PreviewLayout from 'components/layouts/PreviewLayout';
import { ALLOW_LOGGEDOUT_PREVIEW } from 'app-constants';
import { gql, useMutation } from '@apollo/client';
import StatusMessage from 'components/common/StatusMessage';
import HAS_IP_ACCESS from 'components/ipaccess/getIpAccessSessionToken';

const REFRESH_TOKEN = gql`
  mutation getRefreshToken {
    refreshSession {
      token
    }
  }
`;

function useCheckIpAccess(returnUrl?: string) {
  const updateUser = useUpdateUser();
  const [mutate] = useMutation(HAS_IP_ACCESS, {
    onCompleted: data => {
      const {
        ipAccess: {
          session: { token },
        },
      } = data;

      updateUser({
        variables: {
          authorized: true,
          ipSession: true,
          token,
          returnUrl,
          nopUserMigrated: false,
          nopPassUpdateNeeded: false,
          postLoginRedirect: returnUrl,
        },
      });
    },
  });

  return mutate;
}

export interface RequireAuthProps {
  noIpSessions?: boolean;
  ipAccessRedirect?: boolean;
  doNotReturn?: boolean;
  allowPreview?: boolean;
  renderIn?: React.ComponentType<any>;
  previewLayout?: React.ComponentType<any>;
  children?: React.ReactNode;
}

export default function RequireAuth({
  noIpSessions,
  ipAccessRedirect,
  doNotReturn,
  allowPreview,
  renderIn: RenderIn,
  previewLayout,
  children,
}: RequireAuthProps) {
  const { localUser } = React.useContext(AppContext);

  return localUser?.authorized ? (
    <Authorized
      noIpSessions={noIpSessions}
      localUser={localUser}
      renderIn={RenderIn}
    >
      {children}
    </Authorized>
  ) : (
    <NotAuthorized
      allowPreview={allowPreview}
      previewLayout={previewLayout}
      doNotReturn={doNotReturn}
      localUser={localUser}
      ipAccessRedirect={ipAccessRedirect}
    >
      {children}
    </NotAuthorized>
  );
}

interface AuthorizedProps
  extends Pick<RequireAuthProps, 'noIpSessions' | 'renderIn' | 'children'> {
  localUser?: any;
}

function Authorized({
  noIpSessions,
  localUser,
  renderIn: RenderIn,
  children,
}: AuthorizedProps) {
  const updateUser = useUpdateUser();
  const [refreshToken] = useMutation(REFRESH_TOKEN);

  useEffect(() => {
    if (localUser.dontRefresh) {
      updateUser({ variables: { ...localUser, dontRefresh: true } });
    } else {
      refreshToken().then(({ data }) => {
        const token = data?.refreshSession?.token;
        updateUser({ variables: { ...localUser, token } });
      });
    }
  }, [localUser, updateUser, refreshToken]);

  if (noIpSessions && localUser?.ipSession) {
    return <Navigate to="/" />;
  }

  return RenderIn ? <RenderIn>{children}</RenderIn> : children;
}

interface NotAuthorizedProps
  extends Pick<
    RequireAuthProps,
    | 'allowPreview'
    | 'previewLayout'
    | 'doNotReturn'
    | 'ipAccessRedirect'
    | 'children'
  > {
  localUser?: any;
}

function NotAuthorized(props: NotAuthorizedProps) {
  const location = useLocation();
  const returnUrl = React.useMemo(
    () => uriStringify(location.pathname, queryString.parse(location.search)),
    [location],
  );
  const [error, setError] = useState(null);
  const checkIpAccess = useCheckIpAccess(returnUrl);

  useLayoutEffect(() => {
    checkIpAccess().catch(err => setError(err));
  }, [checkIpAccess, setError]);

  return error ? (
    <RenderPreviewOrRedirect {...props} />
  ) : (
    <StatusMessage icon="fa-spinner fa-spin">Loading...</StatusMessage>
  );
}

interface RenderPreviewOrRedirectProps
  extends Pick<
    RequireAuthProps,
    | 'allowPreview'
    | 'ipAccessRedirect'
    | 'doNotReturn'
    | 'previewLayout'
    | 'children'
  > {
  localUser?: any;
}

function RenderPreviewOrRedirect({
  allowPreview,
  previewLayout: RenderInPreview = PreviewLayout,
  doNotReturn,
  localUser,
  ipAccessRedirect,
  children,
}: RenderPreviewOrRedirectProps) {
  const location = useLocation();
  const returnUrl = React.useMemo(
    () => uriStringify(location.pathname, queryString.parse(location.search)),
    [location],
  );
  const updateUser = useUpdateUser();

  if (allowPreview && ALLOW_LOGGEDOUT_PREVIEW) {
    return <RenderInPreview>{children}</RenderInPreview>;
  }

  if (!doNotReturn) {
    updateUser({ variables: { ...localUser, returnUrl } });
  }

  return (
    <Navigate
      to={{
        pathname: ipAccessRedirect ? '/ipAccess' : '/login',
      }}
      state={{ from: location }}
    />
  );
}
