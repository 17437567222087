import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import React from 'react';
import styled from 'styled-components';

export function BlankSlateUnbookmarkable() {
  return (
    <StyledBlankSlateUnbookmarkable>
      <section>
        <span>
          <i className="fa fa-folder" /> You have no bookmarks yet
        </span>
      </section>
      <p>
        Use the Bookmark icon <i className="fa fa-bookmark" /> next to
        bookmarkable content to save a page here.
      </p>
      <p>
        Most CEB content is bookmarkable, from Primary Source and Practitioner
        documents to Secondary Sources, Current Awareness articles and Law
        Alerts.
      </p>
    </StyledBlankSlateUnbookmarkable>
  );
}

const StyledBlankSlateUnbookmarkable = styled.div`
  section {
    align-items: center;
    border: dashed 1px #cccccc;
    color: ${CEB_COLOR_RGBA('BLACK', 0.5)};
    display: flex;
    height: 300px;
    margin-bottom: 16px;
    text-align: center;
    span {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      flex: 1 1 auto;
      i {
        margin-right: 0.5em;
      }
    }
  }
  p {
    font-size: 15px;
    font-family: 'Zilla Slab', serif;
    i {
      color: ${CEB_COLOR_RGBA('BLACK', 0.26)};
      margin: 0 0.25em;
    }
    + p {
      margin-top: 1em;
    }
  }
`;
