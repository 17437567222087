import React from 'react';

const Learning = ({ stroke = '#fff', ...props }) => (
  <svg width={20} height={20} {...props}>
    <g
      stroke={stroke}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.75 18.18a12.742 12.742 0 0 1 6.642-2.918 1.2 1.2 0 0 0 1.043-1.19V5.658a1.208 1.208 0 0 0-1.376-1.2A12.8 12.8 0 0 0 10.75 7.33a1.227 1.227 0 0 1-1.505 0A12.8 12.8 0 0 0 2.94 4.46a1.208 1.208 0 0 0-1.377 1.197v8.415a1.2 1.2 0 0 0 1.044 1.19c2.45.308 4.759 1.322 6.644 2.918a1.225 1.225 0 0 0 1.5 0z" />
      <path d="M16.015 1.563a13.26 13.26 0 0 0-5.265 2.673 1.227 1.227 0 0 1-1.505 0 13.251 13.251 0 0 0-5.257-2.673M10 7.588v10.847" />
    </g>
  </svg>
);

export default Learning;
