import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import gql from 'graphql-tag';
import { WithSidebar } from 'components/layouts/WithSidebar';
import { WithFooter } from 'components/layouts/WithFooter';
import toElement from 'util/toElement';
import { SectionContent } from './SectionContent';
import TitledPage from 'components/common/TitledPage';
import { ArticleCitationHeader } from './ArticleCitationHeader';
import { ArticleHeader } from './ArticleHeader';
import { constURL } from 'util/statuteURL';
import { LayoutContext } from 'components/layouts/LayoutContext';
import useBookmark from 'hooks/useBookmark';
import useHistory from 'hooks/useHistory';
import { HISTORY_MAP, HISTORY_TYPE_MAP } from 'components/history-panel';
import styled, { createGlobalStyle } from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import { useArticleSectionData } from './useArticleSectionData';

function useHistoryAndBookmark(data) {
  const bookmark = useBookmark();
  const location = useLocation();

  const { mutateHistory, recentHistoryLoading } = useHistory();

  const { historyTitle } = useArticleSectionData(data);

  React.useEffect(() => {
    if (!historyTitle || recentHistoryLoading) return;

    mutateHistory({
      variables: {
        text: historyTitle,
        type: HISTORY_TYPE_MAP.STATUTE,
        action: 'VIEW',
        subtype: HISTORY_MAP.VIEW_CONSTITUTION,
      },
    });
  }, [historyTitle, recentHistoryLoading]);

  React.useEffect(() => {
    bookmark({
      type: 'STATUTE_RULE',
      title: historyTitle,
      selector: '.article-content',
      path: location.pathname,
      subtype: 'California Constitution',
    });
  }, [historyTitle, bookmark, location]);
}

export const GET_CONSTITUTION_SECTION = gql`
  query GetCaConstitutionSection($article: String!, $judicataId: String!) {
    caConstitutionSection(article: $article, judicataId: $judicataId) {
      title
      abbreviatedSectionTitle
      content
      history
      citationCount
      parentTableOfContents {
        title
      }
      judicataNumericId
      searchHighlightTerms
    }
  }
`;

const ConstitutionArticleSection = () => {
  const { article, judicataId } = useParams();
  const { raw } = React.useContext(LayoutContext);
  const { data } = useSuspendableQuery(GET_CONSTITUTION_SECTION, {
    variables: { article, judicataId },
  });
  useHistoryAndBookmark(data);

  const {
    content,
    title,
    parentTableOfContents,
    citationCount,
    history,
    sectionTitle,
  } = useArticleSectionData(data);

  const element = React.useMemo(() => toElement(content), [content]);

  return (
    <TitledPage title="California Constitution">
      <StyledConstitutionArticleSection className="article-section-layout">
        {!raw && (
          <ArticleCitationHeader
            citationCount={citationCount}
            article={article}
            judicataId={judicataId}
            title={title}
          />
        )}
        <WithSidebar collapsible>
          <StyledConstitutionArticleSectionSidebar>
            <header>
              <h4>
                <i className={'fas fa-list-ul'} />
                <Link to={constURL()}>California Constitution</Link>
              </h4>
              <h3>
                <Link to={constURL(article)}>
                  {parentTableOfContents[1].title}
                </Link>
              </h3>
            </header>
            <ol>
              <li>{sectionTitle}</li>
            </ol>
          </StyledConstitutionArticleSectionSidebar>
          <WithFooter className="article-section-content" flex>
            <StyledStyledConstitutionArticleSectionContent>
              <ArticleHeader
                title={parentTableOfContents[1].title}
                subtitle={sectionTitle}
              />
              <SectionContent
                article={article}
                section={{ title: undefined, element, history }}
              />
            </StyledStyledConstitutionArticleSectionContent>
          </WithFooter>
        </WithSidebar>
      </StyledConstitutionArticleSection>
      <StyledConstitutionArticleSectionGlobals />
    </TitledPage>
  );
};

const StyledConstitutionArticleSectionGlobals = createGlobalStyle`
  [data-layout='with-sidebar'] {
    > button {
      margin-top: 28px !important;
    }
    header i {
      display: none;
      top: 16px;
    }
  }
`;

export const StyledStyledConstitutionArticleSectionContent = styled.div`
  align-self: stretch;
  margin: 0 auto;
  max-width: 660px;
  padding: 12px 80px 100px;
  flex: 1 1 auto;
`;

const StyledConstitutionArticleSection = styled.div`
  &.article-section-layout {
    height: 100%;
  }
  .article-section-content {
  }
`;

export const StyledConstitutionArticleSectionSidebar = styled.section`
  background: ${CEB_COLOR('CARARRA')};
  box-sizing: border-box;
  padding: 35px 15px 35px 25px;
  overflow-y: auto;
  @media print {
    display: none;
  }
  ol,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 7px 0 6px 0;
    position: relative;
    &:before {
      background-color: ${CEB_COLOR('ORANGEY_YELLOW')};
      content: ' ';
      display: block;
      height: 100%;
      left: -25px;
      position: absolute;
      top: 0;
      width: 15px;
    }
  }
  a,
  a:visited {
    color: inherit;
  }

  h4 {
    margin-bottom: 18px;
  }

  h3 {
    margin-bottom: 13px;
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    max-width: 360px;
    min-width: 360px;
    border-right: 1px solid ${CEB_COLOR('ALTO')};
    height: 100%;
  }
`;

export default ConstitutionArticleSection;
