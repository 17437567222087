import React from 'react';

const MoveFolder = ({ height = 32, width = 32, ...props }) => (
  <svg width="15px" height="13px" viewBox="0 0 15 13">
    <g
      id="----Bookmarking"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Bookmarks-Menu-First-Bookmark-Hover"
        transform="translate(-258.000000, -51.000000)"
        fill="#343434"
      >
        <g id="Group-11" transform="translate(40.000000, 30.000000)">
          <g id="Group-3" transform="translate(218.000000, 17.000000)">
            <g id="icon-move-folder" transform="translate(0.000000, 4.000000)">
              <path
                d="M6,5.00151581 L5.75000685,5.001517 C5.37044911,5.001517 5.05653414,4.71923785 5.00685079,4.35325739 L5.00000119,4.25151849 L5.00000119,3.7515179 C5.00000119,3.37196015 4.71772405,3.05804919 4.35174392,3.00836651 L4.25000507,3.001517 L1.75000209,3.001517 C1.37044434,3.001517 1.05653338,3.28379816 1.0068507,3.64977895 L1.00000119,3.7515179 L1.00000119,10.4155883 L3.00000119,7.95464791 C3.41827312,7.44262807 4.16272684,7.06374042 4.83395172,7.00847971 L5.00000119,7.001517 L13.5000161,7.00151581 C14.0312667,7.00151581 14.5625174,7.24370956 14.7968926,7.74371016 C14.8750177,7.90777285 14.9140803,8.08746057 14.9140803,8.27496079 C14.9140803,8.569604 14.805024,8.8527676 14.6410297,9.09493261 L14.5547049,9.21246191 L12.2500146,12.0484028 C11.8389564,12.5532111 11.081744,12.9381967 10.4146545,12.9944383 L10.2500122,13.0015289 L1.75000209,13.0015289 C0.837110373,13.0015289 0.0793360321,12.2893992 0.00584082728,11.3943014 L0,11.2515268 L0,3.7515179 C0,2.83862618 0.712129755,2.08085184 1.6072275,2.00735664 L1.75000209,2.00151581 L4.25000507,2.00151581 C5.16289678,2.00151581 5.92066467,2.71364557 5.99415922,3.60874331 L6,3.7515179 L6,5.00151581 Z M13.5000161,8.00152296 L5.00000596,8.00152296 C4.63194997,8.00152296 4.15895558,8.20522691 3.87183246,8.47546043 L3.773442,8.57964865 L1.47656426,11.4155895 C1.40625168,11.5093396 1.33593909,11.6109023 1.33593909,11.7280899 C1.33593909,11.9194964 1.52136412,11.9852887 1.68286333,11.9987461 L1.75000209,12.001523 L10.2500122,12.001523 C10.6180682,12.001523 11.0910626,11.7978228 11.3781857,11.5221031 L11.4765762,11.4155895 L13.7734539,8.57964865 C13.8437665,8.49371105 13.9140791,8.39214843 13.9140791,8.27496079 C13.9140791,8.08355431 13.7286541,8.01775833 13.5671548,8.00430007 L13.5000161,8.00152296 Z"
                id="Combined-Shape"
                fillRule="nonzero"
              />
              <path
                d="M5.25728992,7.79539743 C5.25728992,5.05691664 6.85330178,4.77655349 9.32769428,4.77655349 L10.6136894,4.77655349 L10.6136894,6.24626225 C10.6136894,6.447199 10.7801799,6.61368945 10.9811166,6.61368945 C11.0787145,6.61368945 11.1705713,6.5735021 11.2394639,6.5046095 L14.1788814,3.56519197 C14.247774,3.49629937 14.2879614,3.40444257 14.2879614,3.30684472 C14.2879614,3.20924687 14.247774,3.11739008 14.1788814,3.04849748 L11.2394639,0.109079947 C11.1705713,0.0401873491 11.0787145,0 10.9811166,0 C10.7801799,0 10.6136894,0.166490446 10.6136894,0.367427191 L10.6136894,1.83713596 L9.32769428,1.83713596 C7.44462992,1.83713596 5.10228157,2.17011685 4.30427564,4.15077905 C4.06315155,4.75933034 4,5.41955107 4,6.06254866 C4,6.58714497 4.17119896,7.21690636 4.38110567,7.79539743 C4.49280222,8.10322682 5.25728992,8.03078047 5.25728992,7.79539743 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MoveFolder;
