import { basePaths } from 'app-constants';

let prefix;

export const setCourtRuleUrlPrefix = slug => {
  prefix = slug;
};

export default function courtRuleUrl(id) {
  return [prefix, basePaths.courtRules, 'ca', id]
    .filter(Boolean)
    .join('/')
    .replace(/\/+$/, '');
}

export function localCourtRuleUrl(documentId, documentPartId) {
  return [prefix, basePaths.courtRules, 'local', documentId, documentPartId]
    .filter(Boolean)
    .join('/')
    .replace(/\/+$/, '');
}

export const courtRuleSearchUrl = courtRuleUrl('search');
