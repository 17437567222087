import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router';
import { LoadingMessage } from 'components/common/LoadingMessage';
import { Show404Context } from './Show404';

export default function Check404({ notFoundCheck, children }) {
  const client = useApolloClient();
  const params = useParams();
  const [status, setStatus] = React.useState();
  const { show404 } = React.useContext(Show404Context);

  React.useEffect(() => {
    let cancel = false;
    async function checkStatus() {
      if (notFoundCheck) {
        const statusCode = await notFoundCheck(client, { params });
        if (!cancel) {
          setStatus(statusCode);
          if (statusCode === 404) show404();
        }
      }
    }

    checkStatus();

    return () => {
      cancel = true;
    };
  }, [notFoundCheck, client, params, show404]);

  return status && status !== 404 ? children : <LoadingMessage />;
}
