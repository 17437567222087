import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/common/Loading';
import Header from 'components/header';
import { SearchContextProvider } from './SearchContext';
import { EnvironmentBanner } from '../EnvironmentBanner';
import { Footer } from 'components/footer';
import qs from 'util/qs';
import { AppContext } from 'components/app-context';
import { LayoutContext } from '../LayoutContext';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { useIntercomDefaultLauncher } from '../useIntercomWidget';
import { PreviewTabPanel } from 'components/preview-tabs';
import { TopFlash } from '../TopFlash';

export const ResearchLayout = ({
  hideNav = false,
  showFooter = true,
  ...props
}) => {
  const location = useLocation();
  const queryString = qs.parse(location.search) || {};

  const lastQueryRef = React.useRef('');

  if (queryString.query) {
    lastQueryRef.current = queryString.query;
  }

  useIntercomDefaultLauncher();

  return (
    <LayoutContext.Provider value={{}}>
      <SearchContextProvider>
        <StyledResearchLayout>
          <EnvironmentBanner />
          <TopFlash />
          <Header hideNav={props?.hideNav} />
          <section role="main">
            <Loading>
              {props.children}
              <PreviewTabPanel />
            </Loading>
          </section>

          {props.showFooter && (
            <section className="footer">
              <Footer />
            </section>
          )}
        </StyledResearchLayout>
      </SearchContextProvider>
    </LayoutContext.Provider>
  );
};

const StyledResearchLayout = styled.div.attrs(props => {
  return {
    breakpoint: props.breakpoint || StyleConstant.BREAKPOINT_HANDHELD,
  };
})`
  @media screen and (min-width: ${({ breakpoint }) => breakpoint}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr min-content;
    grid-template-areas:
      'layout-banner'
      'layout-header'
      'layout-main'
      'layout-footer';
    height: 100vh;
    width: 100vw;
    > .env-banner,
    > .top-flash {
      grid-area: layout-banner;
    }
    > header {
      grid-area: layout-header;
    }
    > [role='main'] {
      grid-area: layout-main;
      height: 100%;
      overflow: hidden;
    }
    > .footer {
      display: none;
      grid-area: layout-footer;
    }
  }
`;

ResearchLayout.propTypes = {
  /** current query string as an object */
  queryString: PropTypes.object,
  /** search callback */
  search: PropTypes.func,
};

export default function ResearchLayoutIntermediary(props) {
  const { localUser } = React.useContext(AppContext);

  return <ResearchLayout {...props} hideNav={localUser?.authorized} />;
}
