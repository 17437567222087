import React from 'react';

const Home = props => (
  <svg width={20} height={18} {...props}>
    <g
      stroke="#FFF"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.816 10.442v6.188h4.5v-4.5c0-.622.503-1.125 1.124-1.125h1.126c.62 0 1.124.503 1.124 1.125v4.5h4.5v-6.188M1.566 9.317l7.642-7.642c.44-.439 1.151-.439 1.59 0l7.643 7.642" />
      <path d="M13.378 4.255V3.13h2.813v3.937" />
    </g>
  </svg>
);

export default Home;
