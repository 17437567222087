import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { SectionRange } from './SectionRange';
import { statuteURL } from 'util/statuteURL';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const TableOfContentsItem = ({
  item: { depth, title, sectionBegin, sectionEnd, index },
  selected,
  jurisdictionId,
  codeJudicataId,
  next,
  onClick,
}) => {
  const handleClick = React.useCallback(event => onClick(index));
  const selectedRef = React.useRef(null);
  React.useEffect(() => {
    if (selected && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, []);

  return (
    <StyledTableOfContentsItem
      data-depth={depth}
      data-selected={selected}
      ref={selectedRef}
    >
      {sectionBegin || next ? (
        <Link
          to={statuteURL(
            jurisdictionId,
            codeJudicataId,
            sectionBegin || next.sectionBegin,
            sectionEnd || next.sectionEnd,
          )}
          className="outline-item"
        >
          <span className="section-title">{title}</span>
          <SectionRange sectionBegin={sectionBegin} sectionEnd={sectionEnd} />
        </Link>
      ) : (
        <button onClick={handleClick} className="outline-item">
          <span className="section-title">{title}</span>
          <SectionRange sectionBegin={sectionBegin} sectionEnd={sectionEnd} />
        </button>
      )}
    </StyledTableOfContentsItem>
  );
};

const StyledTableOfContentsItem = styled.li`
  padding: 7px 0 6px 0;
  position: relative;
  display: flex;
  .outline-item {
    display: flex;
    flex: 1 1 auto;
    font-family: inherit;
    font-size: 16px;
    text-align: left;
    .section-title {
      color: black;
      flex: 1 1 auto;
      &:focus {
        outline: 0;
      }
    }
  }
  .outline-item:focus {
    border: 0;
    outline: 0;
    margin-bottom: 0;
  }
  .outline-item:hover,
  .outline-item:hover span {
    text-decoration: underline;
    cursor: pointer;
  }
  ${[...Array(19)].map((_, i) => {
    return `&[data-depth='${i + 1}'] {
      padding-left: ${(i + 1) * 16}px;
    }`;
  })}
  &[data-selected='true'] {
    &:before {
      background-color: ${CEB_COLOR('ORANGEY_YELLOW')};
      content: ' ';
      display: block;
      height: 100%;
      left: -25px;
      position: absolute;
      top: 0;
      width: 15px;
    }
    .outline-item {
      font-weight: bold;
    }
  }
`;
