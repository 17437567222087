import React from 'react';
import classSet from 'react-classset';
import { debounce, trim } from 'lodash/fp';
import { useMutation } from '@apollo/client';
import UPDATE_BOOKMARK_FOLDER from './graphql/updateBookmarkFolder';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';
import { FolderMenuContext } from './FolderMenu';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { BookmarkPanelContext } from '.';

export function FolderList(props) {
  return (
    <StyledFolderList>
      {props.folders
        .filter(f => {
          return props.excludeFolder ? f.id !== props.excludeFolder : true;
        })
        .map(folder => (
          <li key={folder.id}>
            <Folder
              folders={props.folders}
              folder={folder}
              onSelect={props.onSelectFolder}
              contenteditable={props.contenteditable}
            />
          </li>
        ))}
    </StyledFolderList>
  );
}

const StyledFolderList = styled.ol`
  list-style: none;
  width: 100%;
  margin: 5px 0;
  box-sizing: border-box;
  padding: 0 0 10px 0;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.07;
  max-height: 180px;
  overflow: auto;
  &:first-child:last-child {
    margin: 10px 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0 10px;
    + li {
      margin-top: 7px;
    }
  }
`;

export function Folder({ folders, folder, onSelect, contenteditable }) {
  const nameInputRef = React.useRef();
  const doneButtonRef = React.useRef();
  const [editing, setEditing] = React.useState(false);
  const { setDeletingFolder, toggleTrashDropdown } =
    React.useContext(FolderMenuContext);

  const { folderId } = React.useContext(BookmarkPanelContext);
  const selected = folderId === folder.id;
  const selectFolder = React.useCallback(e => {
    if (!editing) onSelect(folder.id, e);
  });

  const [updateNameMutation] = useMutation(UPDATE_BOOKMARK_FOLDER);
  const [errors, setErrors] = React.useState([]);

  const handleEditClick = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setEditing(!editing);
  });

  const handleDeleteClick = React.useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    toggleTrashDropdown(e);
    setDeletingFolder(folder);
  });

  const handleDone = React.useCallback(async e => {
    e.stopPropagation();
    e.preventDefault();
    const name = trim(nameInputRef.current.value);
    if (name !== '') {
      try {
        await updateNameMutation({
          variables: {
            id: folder.id,
            name,
          },
          refetchQueries: [{ query: GET_BOOKMARK_FOLDERS }],
        });
        setEditing(false);
        setCanSave(false);
      } catch (error) {
        if (/name must be unique/.test(error.message))
          setErrors(['Folder Already Exists']);
        if (/name is unchanged/.test(error.message)) {
          setErrors([]);
          setEditing(false);
          setCanSave(false);
        }
      }
    } else {
      setErrors([]);
      setEditing(false);
      setCanSave(false);
    }
    return e;
  });

  const hasContent = trim(nameInputRef.current?.value).length > 0;

  const [canSave, setCanSave] = React.useState(hasContent);

  React.useEffect(() => {
    if (editing) {
      nameInputRef.current.focus();
    }
  }, [editing]);

  const handleBlur = React.useCallback(
    debounce(250)(e => {
      if (
        ![doneButtonRef.current, nameInputRef.current].includes(e.relatedTarget)
      ) {
        setEditing(false);
        setCanSave(false);
      }
    }),
    [setEditing, setCanSave],
  );

  const handleKeyUp = React.useCallback(
    e => {
      switch (e.keyCode) {
        case 13:
          handleDone(e);
          e.preventDefault();
          break;
        case 27:
          e.preventDefault();
          setEditing(false);
          setCanSave(false);
          break;
        default:
          setErrors([]);
      }
      setCanSave(trim(e.target.value).length > 0);
    },
    [setCanSave, setEditing, handleDone, setErrors],
  );

  const ButtonElement = !editing ? 'button' : 'div';

  return (
    <StyledFolder editing={editing}>
      <ButtonElement
        onClick={selectFolder}
        className={selected ? 'selected' : ''}
      >
        <div className={errors.length ? 'error' : null}>
          <i className="fa fa-folder-open" />
          {!editing ? (
            <span>{folder.name}</span>
          ) : (
            <input
              placeholder="Rename Folder..."
              ref={nameInputRef}
              type="text"
              onBlur={handleBlur}
              onKeyUp={handleKeyUp}
              name="name"
              defaultValue=""
            />
          )}
          {errors.map((error, i) => (
            <p className="errors" key={i}>
              {error}
            </p>
          ))}
        </div>
        {editing && (
          <button
            className={classSet({
              'done-editing': canSave,
              'cancel-editing': !canSave,
            })}
            ref={doneButtonRef}
            onKeyPress={handleDone}
            onClick={handleDone}
            onBlur={handleBlur}
          >
            {canSave ? 'Done' : <i className="fa fa-times-circle" />}
          </button>
        )}
        {contenteditable && !editing && (
          <div>
            <i
              role="button"
              tabIndex={0}
              onKeyPress={handleEditClick}
              className="fas fa-pencil-alt"
              onClick={handleEditClick}
            />
            <i
              role="button"
              tabIndex={0}
              onKeyPress={handleDeleteClick}
              className="fa fa-trash-alt"
              onClick={handleDeleteClick}
            />
          </div>
        )}
      </ButtonElement>
    </StyledFolder>
  );
}

const StyledFolder = styled.section.attrs(props => ({
  editing: props.editing,
}))`
  position: relative;
  white-space: nowrap;
  > button:first-child {
    > div:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div:last-child {
      display: none;
    }
    > div:first-child:last-child {
      display: block;
    }
    &.selected {
      background: ${({ editing }) =>
        editing ? 'transparent' : CEB_COLOR('COOL_BLUE')};
      color: white;
      .fa-trash-alt {
        color: white !important;
      }
    }
    &:hover,
    &:focus {
      div:last-child {
        display: block;
      }
    }
  }
  > div:first-child,
  > button:first-child {
    align-items: center;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    padding: 4px 8px;
    position: relative;
    text-align: left;
    width: 100%;
    div {
      flex: 1 1 auto;
      padding-right: 12px;
      &:last-child {
        flex: 0 1 auto;
        padding-right: 0;
        .fa-trash-alt {
          color: ${CEB_COLOR('REDDY_BROWN')};
          font-weight: normal;
        }
      }
    }

    i {
      + i,
      + span {
        margin-left: 12px;
      }
    }
  }

  input {
    border: 0;
    font-weight: bold;
    margin-left: 12px;
    max-width: 135px;
    padding: 0;
    font-size: 13px;
    &::placeholder {
      font-weight: normal;
    }
    &:focus {
      outline: 0;
    }
  }
  div {
    &.error {
      .errors,
      > i {
        color: red;
      }
    }
    .errors {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 11px;
      font-weight: bold;
      left: 39px;
      position: absolute;
    }
    & + .done-editing {
      background: #185cad;
      border-radius: 3px;
      color: white;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      margin: -4px 0;
      padding: 4px 10px 2px;
    }
  }
`;
