import styled from 'styled-components';

/**
 * FormButton Component
 *
 */

export const FormButton = styled.button`
  background: gray;
  border-radius: 5px;
  border-width: 0;
  color: white;
  font-family: 'Zilla Slab', serif;
  padding: 5px 10px;
`;
