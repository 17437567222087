import React from 'react';
import styled from 'styled-components';
import { TabContext } from 'components/app-context/TabContextProvider';
import { useContentQuery } from '../content-renderer/useContentQuery';
import { LoadingMessage } from 'components/common/LoadingMessage';
import { useTabButtonLabel } from './useTabButtonLabel';

export function PreviewTab({ tab }) {
  const { dispatch } = React.useContext(TabContext);
  const { data, loading } = useContentQuery(tab.uri);

  const buttonLabel = useTabButtonLabel(data, tab);

  return (
    <li>
      <StyledTabButtons>
        {loading ? (
          <LoadingMessage />
        ) : (
          <>
            <button
              onClick={() => dispatch({ type: 'MAXIMIZE_TAB', tab })}
              title={buttonLabel.toString()}
            >
              <b>{buttonLabel.title}</b> {buttonLabel.subtitle}
            </button>
          </>
        )}
        <button onClick={() => dispatch({ type: 'REMOVE_TAB', tab })}>
          <i className="fas fa-times" />
        </button>
      </StyledTabButtons>
    </li>
  );
}

export const StyledTabButtons = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #e4e4e2;
  background-color: #fafafa;
  position: relative;
  z-index: 3;
  display: flex;
  overflow: hidden;
  > .status-message {
    min-height: 0;
  }
  > .status-message,
  button {
    padding-top: 14px;
    padding-bottom: 13px;
    font-family: 'Zilla Slab', serif;
    font-size: 18px;
    flex: 1 1 auto;
    text-align: left;
    outline-offset: -1px;
    b {
      font-weight: bold;
    }
    &:first-child {
      overflow: hidden;
      padding-left: 12px;
      text-overflow: ellipsis;
      &:last-child {
        padding-right: 13px;
        margin-right: 0;
        padding-left: 12px;
      }
    }
    &:last-child {
      flex: 0 1 auto;
      padding-right: 3px;
      padding-left: 3px;
      margin-right: 10px;
    }
    + button {
      text-align: center;
      i {
        vertical-align: -2px;
      }
    }
  }
`;
