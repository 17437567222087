import { gql } from '@apollo/client';
import StatuteFragments from './fragments/statutes';

const GET_CODE_SECTION = gql`
  query GetCodeSection(
    $jurisdictionId: String!
    $codeJudicataId: String!
    $judicataId: String!
    $fromQuery: String
  ) {
    codeSection(
      jurisdictionId: $jurisdictionId
      codeJudicataId: $codeJudicataId
      judicataId: $judicataId
      fromQuery: $fromQuery
    ) {
      ...CodeSection
    }
    statute(jurisdictionId: $jurisdictionId) {
      ...Statute
    }
    code(jurisdictionId: $jurisdictionId, judicataId: $codeJudicataId) {
      ...Code
    }
  }

  ${StatuteFragments.CodeSection}
  ${StatuteFragments.Statute}
  ${StatuteFragments.Code}
`;

export default GET_CODE_SECTION;

export const GET_CODE_REFERENCE_COUNT = gql`
  query GetCodeReferenceCount(
    $jurisdictionId: String!
    $codeJudicataId: String!
    $sectionId: String!
  ) {
    search(
      type: PUBLICATION
      filters: {
        statuteReference: {
          citedStatuteRegJurisdictionId: $jurisdictionId
          citedStatuteRegJudicataId: $codeJudicataId
          citedStatuteRegSectionId: $sectionId
        }
      }
    ) {
      totalCount
      searchFilter(category: PUBLICATION_PRACTICE_AREA) {
        name
        children {
          name
          count
        }
      }
    }
  }
`;
export const GET_CODE_REFERENCES = gql`
  query GetCodeReferences(
    $jurisdictionId: String!
    $codeJudicataId: String!
    $sectionId: String!
    $optionFilters: [String]
  ) {
    search(
      type: PUBLICATION
      filters: {
        statuteReference: {
          citedStatuteRegJurisdictionId: $jurisdictionId
          citedStatuteRegJudicataId: $codeJudicataId
          citedStatuteRegSectionId: $sectionId
        }
        optionFilters: $optionFilters
      }
    ) {
      totalCount
      searchFilter(category: PUBLICATION_PRACTICE_AREA) {
        name
        children {
          name
          count
        }
      }
      edges {
        ... on PublicationSectionSearchEdge {
          breadcrumbs
          ordering
          node {
            id
            secondarySourcesTitleParts {
              id
              name
              onlawId
              title {
                onlawId
                productCode
                productTitle
                firstTitlePart: tableOfContents(
                  limit: 1
                  where: { depth: { _eq: 1 } }
                  order_by: { order: asc }
                ) {
                  onlawId
                }
                practiceAreas {
                  practiceArea {
                    name
                    slug
                  }
                  primary
                }
              }
            }
          }
        }
      }
    }
  }
`;
