import { isNull, isEqual } from 'lodash/fp';
import { parenthesize } from './index';
import {
  finder,
  findParents,
  findPrev,
  findChildren,
  findNext,
} from 'util/finder';

/**
 * Returns a string corresponding to the subdivisions covered by the
 * selection beginning in startNode and ending in endNode. These will be
 * appended after the section number. See SEARCHFE-1895 for examples and
 * rationale.
 *
 * @param {node} startNode
 * @param {node} endNode
 * @returns {string}
 */
export function getSubdivisions(startNode, endNode) {
  /*
    We use the following rules to come up with the subdivision string:
 
    1. If the selection begins and ends in the preamble, return nothing.
    2. If the selection encompasses all subdivisions, return nothing.
    3. If the selection begins in the preamble, but ends elsewhere,
        ignore the preamble and pretend the selection starts in the first
        subdivision.
    4. If the selection begins and ends in the same subdivision, return
        that subdivision.
    5. If the selection encompasses all the subparts of a subdivision,
        return that subdivision.
    6. Otherwise, return the start subdivision, an en dash, and the end
        subdivision (omitting redundant specifiers if the start and end
        are siblings).
    */
  const startSection = startNode.closest
    ? startNode.closest('.statute-section')
    : startNode.parentElement.closest('.statute-section');
  const endSection = endNode.closest
    ? endNode.closest('.statute-section')
    : endNode.parentElement.closest('.statute-section');

  let startSpecifiers = getSpecifiers(startSection);
  let endSpecifiers;

  if (!startSection && !endSection) {
    return '';
  }

  if (startSection > 0 && startSection.contains(endSection)) {
    // Adjust for rule #5.
    endSpecifiers = getEndSpecifiers(endSection);
  } else {
    endSpecifiers = getSpecifiers(endSection);
  }
  const isStartFirst =
    // There is no earlier subdivision sibling...
    findPrev(startSection)('.statute-section').length === 0 &&
    // ...and its parent is the root and does not have content.
    startSection.parentElement.matches('.statute-root:not(.statute-section)');

  const isEndLast =
    // No children subdivisions...
    findChildren(endSection)('.statute-section').length === 0 &&
    // ...and no logical successors (e.g., (b)(2) followed by (c)).
    isNull(nextNotLowerSubdivision(endSection));
  if (isStartFirst && isEndLast) {
    // Rule #2.
    return '';
  }

  if (startSpecifiers.length === 0) {
    if (endSpecifiers.length === 0) {
      // Rule #1.
      return '';
    }
    // Adjust for rule #3.
    const firstSubdivision = finder(startSection)('.statute-section')[0];
    startSpecifiers = getSpecifiers(firstSubdivision);
  }

  // Rule #4/5.
  if (isEqual(startSpecifiers)(endSpecifiers)) {
    return parenthesize(startSpecifiers);
  }

  // Rule #6.
  if (findNext(startSection)('.statute-section') === endSection) {
    // They are siblings. Omit redundant leading specifiers.
    let i = 0;
    while (startSpecifiers[i++] === endSpecifiers[0]) {
      endSpecifiers.shift();
    }
  }
  const reverse =
    endNode.compareDocumentPosition(startNode) &
    Node.DOCUMENT_POSITION_FOLLOWING;

  const start = parenthesize(reverse ? endSpecifiers : startSpecifiers);
  const end = parenthesize(reverse ? startSpecifiers : endSpecifiers);
  return `${start}\u2013${end}`;
}

/**
 * Given a statute section node, returns the array of statute specifiers
 * that identifies that statute section. For example, if the section were
 * 12940(a)(5)(B), we'd return ['a', '5', 'B'].
 *
 * @param {node} section
 * @returns {Array}
 */
function getSpecifiers(section) {
  const specifiers = [];
  let parent = section;
  while (parent) {
    const para = findChildren(parent)('p')[0];
    const specifier = finder(para)('.specifier');
    if (specifier.length === 0) {
      break;
    }
    specifiers.push(specifier[0].getAttribute('data-value'));
    parent = findParents(parent)('.statute-section')[0];
  }

  specifiers.reverse();
  return specifiers;
}

/**
 * Like getSpecifiers, but if $section ends a subdivision, returns the
 * specifiers that represent the ended subdivision. For example, if
 * 12940(a)(5)(B) were followed by (a)(6), getEndSepecifiers for
 * (a)(5)(B) would return ['a', '5'].
 *
 * @param {node} section
 * @returns {Array}
 */
function getEndSpecifiers(section) {
  while (section) {
    const parent = findParents(section)('.statute-section');
    if (parent.length === 0 || !isEntireSubdivision(parent, section)) {
      break;
    }
    section = parent;
  }
  return getSpecifiers(section);
}

/**
 * Returns the next subdivision at an equal or greater (closer to the root)
 * depth. Returns null if there is no matching subdivision.
 *
 * @param {node} section
 * @returns {node section | null}
 */
function nextNotLowerSubdivision(section) {
  const next = findNext(section)('.statute-section');
  if (next.length > 0) {
    return next[0];
  }
  const parent = findParents(section)('.statute-section');
  if (parent.length === 0) {
    return null;
  }
  return nextNotLowerSubdivision(parent[0]);
}

/**
 * Returns whether or not $startSection begins a subdivision (at any
 * depth) and $endSection is the last subdivision within $startSection.
 *
 * If a section is laid out as:
 *   (preamble)
 *   a
 *     1
 *     2
 *       i
 *       ii
 *     3
 *   b
 *   c
 *
 * We can say, for example:
 * - isEntireSubdivision(a, a(3)): true
 * - isEntireSubdivision(a(1), a(3)): false
 * - isEntireSubdivision(a(2), a(2)(ii)): true
 * - isEntireSubdivision(preamble, c): true
 *
 * @param {node} startSection - A .statute-section
 * @param {node} endSection - A .statute-section
 * @returns {boolean}
 */
function isEntireSubdivision(startSection, endSection) {
  if (!startSection[0].contains(endSection[0])) {
    return false;
  }
  const startNext = nextNotLowerSubdivision(startSection);
  const endNext = nextNotLowerSubdivision(endSection);
  if (isNull(startNext)) {
    return isNull(endNext);
  }
  return startNext === endNext;
}
