import React from 'react';
import { constURL } from 'util/statuteURL';
import { StyledStyledConstitutionArticleSectionContent } from 'components/statutes/constitution/ArticleSection';
import { WithSidebar } from 'components/layouts/WithSidebar';
import toElement from 'util/toElement';
import { WithFooter } from 'components/layouts/WithFooter';
import { ArticleHeader } from 'components/statutes/constitution/ArticleHeader';
import { SectionContent } from 'components/statutes/constitution/SectionContent';
import { NavOutline } from 'components/common/nav-outline';
import useContentScroll from 'hooks/useContentScroll';
import useScrollThreshold from 'hooks/useScrollThreshold';
import {
  ContentRendererContext,
  TabbableLocationContext,
} from '../preview-tabs/PreviewPanel';
import useHistory from 'hooks/useHistory';
import { HISTORY_MAP, HISTORY_TYPE_MAP } from 'components/history-panel';
import { useLocation } from 'react-router';

export function ArticlePreview() {
  const { data } = React.useContext(ContentRendererContext);
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);

  const { location: referrer } = useLocation();
  const headerHeight = React.useRef(0);
  const contentRef = React.useRef();

  const [sections, setSections] = React.useState([]);

  React.useEffect(() => {
    setSections(
      data.caConstitutionHeading.codeSections.map(section => {
        return {
          title: `§${section.abbreviatedSectionTitle.split(' § ')[1]}`,
          level: 1,
          current: 0,
          element: toElement(section.content),
          id: section.judicataId,
          history: section.history,
        };
      }),
    );
  }, [data]);

  const {
    caConstitutionHeading: { title, parentTableOfContents },
  } = data;
  const subtitle = sections.length
    ? `Sections ${sections[0].id}-${sections[sections.length - 1].id}`
    : null;

  const articleTitleEl = toElement('<span />');
  const articleTitleSection = {
    title: `${title} ${parentTableOfContents[1].name}`,
    level: 1,
    element: articleTitleEl,
    id: '0',
  };

  const [onContentScroll, currentSectionIndex] = useContentScroll(
    sections,
    0,
    headerHeight,
  );
  const containerPadding = { top: 12, left: 80 };
  const [scrolled, onScrolled] = useScrollThreshold(containerPadding.top);

  const onScroll = React.useCallback(
    e => {
      onScrolled(e);
      onContentScroll(e);
    },
    [onScrolled, onContentScroll],
  );

  const scrollToIt = React.useCallback((element, id) => {
    const contentEl = contentRef.current;
    if (contentEl) {
      const el = contentEl.querySelector(`*[id="${id}"]`);
      if (el) {
        contentEl.scrollTop = el.offsetTop - 20;
      }
    }
  }, []);

  const { mutateHistory, recentHistoryLoading } = useHistory(referrer);

  React.useEffect(() => {
    if (!data || recentHistoryLoading) return;
    mutateHistory({
      variables: {
        text: `${data.caConstitutionHeading.title} ${
          data.caConstitutionHeading.parentTableOfContents[1].name
        }`,
        type: HISTORY_TYPE_MAP.STATUTE,
        action: 'BROWSE',
        subtype: HISTORY_MAP.BROWSE_CONSTITUTION,
      },
    });
  }, [data, recentHistoryLoading]);

  return (
    <WithSidebar collapsible defaultCollapsed>
      <NavOutline
        sections={[articleTitleSection, ...sections]}
        title="California Constitution"
        href={constURL()}
        current={currentSectionIndex + 1}
        onScrollTo={scrollToIt}
        icon="list-ul"
      />
      <WithFooter
        className={`
        ${scrolled ? ' scrolled' : ''}
      `}
        onScroll={onScroll}
        ref={contentRef}
        flex
      >
        <StyledStyledConstitutionArticleSectionContent>
          <ArticleHeader
            title={`${title} ${parentTableOfContents[1].name}
                `}
            subtitle={subtitle}
          />
          {sections.map((section, i) => (
            <SectionContent
              article={params.article}
              section={section}
              key={i}
              id={section.id}
            />
          ))}
        </StyledStyledConstitutionArticleSectionContent>
      </WithFooter>
    </WithSidebar>
  );
}
