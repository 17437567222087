import React from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ModalHeader } from 'components/common/modal-overlay/ModalHeader';
import utc from 'dayjs/plugin/utc';
import { DATE_MAP } from 'hooks/useHistory';
import { HistoryTable } from './HistoryTable';
import { BlankSlate } from './BlankSlate';
import { HistoryDateSelect } from './HistoryDateSelect';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { GetHistoryItemsDocument } from '__generated__/graphql';

dayjs.extend(utc);

export const HISTORY_MAP = {
  BROWSE_COURT_RULE: 'Browse: $subtitle',
  BROWSE_CONSTITUTION: 'Browse: CA Const',
  BROWSE_PRACTITIONER: 'Browse: Practitioner',
  BROWSE_SECONDARY_SOURCES: 'Browse: Sec. Sources',
  BROWSE_STATUTE_RULE: 'Browse: CA Codes',
  SEARCH_CASE: 'Search: Cases',
  SEARCH_CITATION: 'Search: Citing Cases',
  SEARCH_PRACTITIONER: 'Search: Practitioner',
  SEARCH_SECONDARY_SOURCES: 'Search: Sec. Sources',
  SEARCH_STATUTE_RULE: 'Search: Statutes & Rules',
  SEARCH_CURRENT_AWARENESS: 'Search: Current Awareness',
  VIEW_CASE: 'Cases',
  VIEW_CONSTITUTION: 'California Constitution',
  VIEW_LAW_ALERT: 'Law Alerts',
  VIEW_POST: 'Articles',
  VIEW_SECONDARY_SOURCES: 'Secondary Sources',
  VIEW_STATUTE_RULE: 'California Codes',
  VIEW_WORKFLOW: 'Workflows',
  VIEW_COURT_RULE: '$subtitle',
};

export const HISTORY_TYPE_MAP = {
  DAILY_NEWS: 'CURRENT_AWARENESS',
  PRACTITIONER: 'PRACTITIONER',
  PUBLICATION: 'SECONDARY_SOURCES',
  CASE: 'CASE',
  STATUTE: 'STATUTE_RULE',
  GENERIC: 'STATUTE_RULE',
  POST: 'CURRENT_AWARENESS',
  LAW_ALERT: 'CURRENT_AWARENESS',
};

export function HistoryPanel({ close, ...props }) {
  const initialRender = React.useRef(true);
  const [dates, setDates] = React.useState(DATE_MAP.today);

  const { loading, data } = useQuery(GetHistoryItemsDocument, {
    variables: {
      ...dates,
    },
    fetchPolicy: 'no-cache',
  });

  const historyPanel = React.useRef();

  const clickListener = React.useCallback(
    e => {
      const isDescendent = historyPanel.current.contains(e.target);
      if (e.target && !isDescendent && close && !initialRender.current)
        close(e);
      initialRender.current = false;
    },
    [close],
  );

  React.useLayoutEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [historyPanel.current]);

  return (
    <StyledHistoryPanel>
      <div className="history-panel" ref={historyPanel}>
        <div>
          <ModalHeader close={close}>
            <h3>History</h3>
            <HistoryDateSelect onChange={setDates} />
          </ModalHeader>
          <section>
            {!loading && data?.historyItemsByDate?.length ? (
              <HistoryTable
                historyItems={data.historyItemsByDate}
                close={close}
              />
            ) : (
              <BlankSlate loading={loading} />
            )}
          </section>
        </div>
      </div>
    </StyledHistoryPanel>
  );
}

const StyledHistoryPanel = styled.div.attrs(() => ({ arrowSize: 10 }))`
  .history-panel {
    display: block;
    position: absolute;
    right: 0;
    text-align: left;
    top: 51px;
    z-index: 100;
    div > header {
      flex: 0 1 auto;
    }

    > div {
      background-color: white;
      border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
      box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 50vh;
      overflow: hidden;
      padding: 33px 36px;
      z-index: 100;
      width: 75vw;
      max-width: 888px;
      > header {
        margin-bottom: 14px;
        > h3 {
          color: ${CEB_COLOR('BLACK')};
          font-family: 'Zilla Slab', serif;
          font-size: 20px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          margin: 0;
          margin-bottom: 27px;
          text-align: left;
        }
      }
      > section {
        flex: 1;
        min-height: 250px;
        overflow-y: auto;
      }
    }
  }
`;
