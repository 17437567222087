import React from 'react';
import Loading from 'components/common/Loading';
import Header from 'components/header';
import styled, { createGlobalStyle } from 'styled-components';

interface FrameLayoutProps extends React.HTMLAttributes<HTMLElement> {
  hideNav?: boolean;
  src: string;
}

const FrameLayout = ({ hideNav, src }: FrameLayoutProps) => {
  return (
    <Loading>
      <StyledFrameLayout className="iframe-layout">
        <Header hideNav={hideNav} hideHistoryAndBookmarks />
        <iframe src={src} name="ceb" title="OnLaw" />
      </StyledFrameLayout>
      <StyledFrameGlobals />
    </Loading>
  );
};
const StyledFrameGlobals = createGlobalStyle`
  html,
  body,
  #root {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }
`;

const StyledFrameLayout = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > div,
  iframe {
    flex: 1 1 auto;
    width: 100%;
  }
`;

export default FrameLayout;
