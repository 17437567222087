import React from 'react';

export const FlashMessageContext = React.createContext();

export const FlashMessageContextProvider = props => {
  const [flashMessages, setFlashMessages] = React.useState(
    JSON.parse(sessionStorage.getItem('flashMessages') || '[]').map(msg => ({
      ...msg,
      timestamp: new Date(msg.timestamp),
    })),
  );

  const addMessage = React.useCallback(
    msg => {
      const msgObj = typeof msg !== 'string' ? msg : { msg };
      setFlashMessages([
        ...flashMessages,
        { ...msgObj, timestamp: new Date() },
      ]);
    },
    [flashMessages, setFlashMessages],
  );

  const deleteMessage = React.useCallback(
    msg => {
      setFlashMessages(flashMessages => flashMessages.filter(v => v !== msg));
    },
    [setFlashMessages],
  );

  React.useEffect(() => {
    sessionStorage.setItem('flashMessages', JSON.stringify(flashMessages));
  }, [flashMessages]);

  return (
    <FlashMessageContext.Provider
      value={{ addMessage, deleteMessage, flashMessages }}
    >
      {props.children}
    </FlashMessageContext.Provider>
  );
};
