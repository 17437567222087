export function sequentiallyLoadScripts(scriptsArray, i = 0) {
  const script = document.createElement('script');
  const s = scriptsArray[i];
  if (s.innerHTML) script.innerHTML = s.innerHTML;
  if (s.type) script.type = s.type;
  if (s.async) script.async = false;
  if (s.id) script.id = s.id;

  const recurse = () => {
    if (i + 1 < scriptsArray.length) {
      sequentiallyLoadScripts(scriptsArray, i + 1);
    }
  };

  if (s.src && s.src !== '') {
    script.onload = recurse;
    script.async = true;
    script.src = s.src;
  } else {
    recurse();
  }

  document.body.appendChild(script);
}

export function removeSequentiallyLoadedScripts(scripts) {
  scripts.map(script => {
    const selector = Object.keys(script)
      .map(key => {
        return ['src', 'type', 'id'].includes(key)
          ? `[${key}="${script[key]}"]`
          : null;
      })
      .reduce((t, v) => {
        return (t += v);
      }, '');
    const scriptEl = document.querySelector(selector);

    document.body.removeChild(scriptEl);
  });
}
