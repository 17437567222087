import { gql } from '@apollo/client';

const GET_CONSTITUTION_HEADING = gql`
  query GetCaConstitutionHeading($article: String!) {
    code(jurisdictionId: "ca", judicataId: "const") {
      name
    }
    caConstitutionHeading(article: $article) {
      title
      sectionBegin
      sectionEnd
      parentTableOfContents {
        name
      }
      codeSections {
        abbreviatedSectionTitle
        content
        history
        judicataId
      }
    }
  }
`;

export default GET_CONSTITUTION_HEADING;
