import React from 'react';

const Research = ({ stroke = '#fff', ...props }) => (
  <svg width={17} height={20} {...props}>
    <g
      stroke={stroke}
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.313 3.812h-3c-.622 0-1.126.503-1.126 1.125v12.375c0 .621.504 1.125 1.125 1.125h12.376c.62 0 1.124-.504 1.124-1.125V4.937c0-.622-.503-1.125-1.124-1.125h-3" />
      <path d="M11.44 4.552a.563.563 0 0 1-.533.385H6.093a.563.563 0 0 1-.534-.385l-.75-2.25a.562.562 0 0 1 .534-.74h6.314a.562.562 0 0 1 .534.74l-.75 2.25zM13.563 6.062v7.408c0 .299-.119.585-.33.796l-1.591 1.59c-.211.212-.497.33-.795.33H4a.562.562 0 0 1-.563-.562V6.062" />
      <path d="M10.188 16.187v-2.25c0-.622.503-1.125 1.124-1.125h2.25" />
    </g>
  </svg>
);

export default Research;
