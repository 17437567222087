import { gql } from '@apollo/client';

export default gql`
  query typeaheadSearch($query: String!, $types: [TypeaheadSearchType!]!) {
    typeaheadSearch(query: $query, types: $types) {
      __typename
      ... on SpellingTypeaheadResult {
        value
      }
      ... on NgramTypeaheadResult {
        value
      }
      ... on CaseDocumentTypeaheadResult {
        value
        caseId
        date
        cite
      }
      ... on CodeOutlineTypeaheadResult {
        value
        jurisdictionId
        codeId
      }
      ... on CodeSectionCitatorTypeaheadResult {
        value
        jurisdictionId
        articleId # will be used later for the CA constitution
        sectionId
        codeSectionCodeId: codeId # necessary to alias to avoid conflict with CodeOutlineTypeaheadResult
      }
      ... on CodeSectionDocumentTypeaheadResult {
        value
        jurisdictionId
        articleId # will be used later for the CA constitution
        sectionId
        codeSectionCodeId: codeId # necessary to alias to avoid conflict with CodeOutlineTypeaheadResult
      }
      ... on OnlawOutlineTypeaheadResult {
        value
        onlawId
      }
      ... on OnlawSearchCaseTypeaheadResult {
        value
        date
        cite
      }
      ... on OnlawSearchCodeTypeaheadResult {
        value
      }
      ... on PractitionerDocumentTypeaheadResult {
        value
        contentfulId
      }
      ... on CurrentAwarenessTypeaheadResult {
        slug
        title
      }
      ... on CourseTypeaheadResult {
        title
        slug
        contentfulId
      }
      ... on CoursesCollectionTypeaheadResult {
        title
        slug
        contentfulId
      }
    }
  }
`;
