import React from 'react';
import { UserIcon } from 'components/common/icons';
import { AppContext } from 'components/app-context';
import { ENABLE_MANAGE_FIRM } from 'app-constants';
import styled, { css } from 'styled-components';
import User from '../common/icons/User';
import Firm from '../common/icons/Firm';
import SignOut from '../common/icons/SignOut';
import { isActive } from 'util/isActive';
import { useLocation } from 'react-router';
import {
  HeaderMenu,
  StyledHeaderMenuList,
  HeaderMenuListItem,
} from './HeaderMenu';

export function UserDropdownMenu({ logout }) {
  const { user, localUser } = React.useContext(AppContext);
  const { pathname } = useLocation();

  return (
    <HeaderMenu
      as={StyledUserDropdownMenu}
      className="user-nav"
      title={user ? `${user.firstName} ${user.lastName}` : undefined}
    >
      <UserIcon className="icon" />
      <StyledHeaderMenuList anchor="right" className="aside">
        {user && !localUser.ipSession ? (
          <HeaderMenuListItem
            to="/account"
            end
            className={
              isActive({
                pathname,
                to: '/account',
                exclude: '/account/organization',
              })
                ? 'active'
                : null
            }
          >
            <User
              width="20"
              height="20"
              circle="transparent"
              path="var(--COLOR_NICE_BLUE)"
            />
            Personal Account
          </HeaderMenuListItem>
        ) : null}
        {user &&
        user.firmPermissions?.isFirmAdmin &&
        ENABLE_MANAGE_FIRM &&
        !localUser.ipSession ? (
          <HeaderMenuListItem to="/account/organization">
            <Firm
              width="20"
              height="20"
              circle="transparent"
              path="var(--COLOR_NICE_BLUE)"
            />
            Organization Account
          </HeaderMenuListItem>
        ) : null}

        <HeaderMenuListItem onClick={logout}>
          <SignOut width="16" height="20" path="var(--COLOR_NICE_BLUE)" />
          Log Out
        </HeaderMenuListItem>
      </StyledHeaderMenuList>
    </HeaderMenu>
  );
}

const StyledUserDropdownMenu = styled.li`
  position: relative;
  padding: 0 7px;
  &.user-nav > button {
    box-sizing: border-box;
    height: 51px;
    padding: 13px;
    position: relative;
    border: solid 1px transparent;
    &:hover {
      svg {
        opacity: 1;
      }
    }
    svg {
      opacity: 0.75;
    }
    ${({ open }) =>
      open
        ? css`
            background: white;
            border-radius: 3px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: solid 1px #d2d2d2;
            border-bottom-width: 0;
            svg {
              opacity: 1;
            }
            &:before {
              display: block;
              content: ' ';
              position: absolute;
              bottom: -2px;
              right: 0;
              left: 0;
              height: 2px;
              background: white;
              z-index: 1;
            }
          `
        : ''}
    &:active:focus {
      outline: 0;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      right: -1px;
      top: 28px;
      border: 3px solid transparent;
      border-left-width: 4px;
      border-right-width: 4px;
      opacity: 0.75;
      ${({ open }) =>
        open
          ? css`
              display: none;
              border-bottom-color: white;
            `
          : css`
              border-top-color: white;
            `}}
    }
  }
  > ul {
    right: 7px;
  }
`;
