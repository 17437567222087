import React from 'react';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';
import GET_BOOKMARKS from './graphql/getBookmarks';
import useDropdown from 'hooks/useDropdown';
import DELETE_BOOKMARK from './graphql/deleteBookmark';
import { client } from 'apollo/client';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';

export const DeleteConfirmationDialog = React.forwardRef(
  ({ title, message, ...props }, ref) => {
    return (
      <StyledDeleteConfirmationDialog ref={ref}>
        <h5>{title}</h5>
        {message && <p>{message}</p>}
        <section>
          <button onClick={props.confirm}>Delete</button>
          <button onClick={props.cancel}>Don't Delete</button>
        </section>
      </StyledDeleteConfirmationDialog>
    );
  },
);

const StyledDeleteConfirmationDialog = styled.div`
  position: absolute;
  padding: 15px 12px;
  top: 22px;
  border-radius: 3px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
  background-color: ${CEB_COLOR('DESERT_STORM')};
  z-index: 10;
  h5 {
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 9px;
    white-space: normal;
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    margin-top: -9px;
    font-weight: normal;
  }
  section {
    display: flex;
    button {
      flex: 1 1 auto;
    }
  }
  button {
    background: ${CEB_COLOR('SLATE_GREY')};
    border-radius: 3px;
    color: white;
    display: inline-block;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    font-weight: normal;
    padding: 7px 0 3px;
    text-align: center;
    width: 112px;
    white-space: nowrap;
    &:first-child {
      background: ${CEB_COLOR('TUSCANY')};
      margin-right: 10px;
    }
  }
`;

export function DeleteBookmark({ bookmark, folderId, onToggle, open }) {
  const [trashDropdown, trashToggle, onTrashBlur, trashRef] = useDropdown();

  const deleteBookmark = React.useCallback(
    async e => {
      e.stopPropagation();
      await client.mutate({
        mutation: DELETE_BOOKMARK,
        variables: {
          id: bookmark.id,
        },
        refetchQueries: [
          {
            query: GET_BOOKMARKS,
            variables: { folderId },
          },
          { query: GET_BOOKMARK_FOLDERS },
        ],
      });
      trashToggle(e);
    },
    [trashDropdown, trashToggle],
  );

  const handleTrashToggle = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    onToggle(!trashDropdown, e);
  });

  React.useEffect(() => {
    if (!trashDropdown && open !== trashDropdown) {
      onToggle(trashDropdown);
    }
  }, [trashDropdown]);

  React.useEffect(() => {
    trashToggle(undefined, open);
  }, [open]);

  return (
    <>
      <StyledDeleteBookmark onClick={handleTrashToggle}>
        <i className="fa fa-trash-alt" />
      </StyledDeleteBookmark>
      {trashDropdown && (
        <DeleteConfirmationDialog
          cancel={e => {
            e.stopPropagation();
            trashToggle(e);
          }}
          ref={trashRef}
          title="Delete this Bookmark?"
          confirm={deleteBookmark}
        />
      )}
    </>
  );
}

const StyledDeleteBookmark = styled.button`
  display: block;
  i {
    color: ${CEB_COLOR('REDDY_BROWN')};
  }
  + div {
    min-width: 260px;
    max-width: 260px;
  }
`;
