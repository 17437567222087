import React from 'react';
import { FormInput } from 'components/common/json_form';
import { TypeaheadQuery } from './TypeaheadQuery';
import styled from 'styled-components';

function useComponentBlur(parentRef, onBlur) {
  return React.useCallback(
    e => {
      if (e.relatedTarget) {
        if (!parentRef.current.contains(e.relatedTarget)) {
          onBlur && onBlur();
        }
      } else {
        // IE 11
        setTimeout(function() {
          if (!parentRef.current.contains(document.activeElement)) {
            onBlur && onBlur();
          }
        }, 0);
      }
    },
    [parentRef.current, onBlur],
  );
}

export const SearchTypeAhead = ({
  queryRef,
  query,
  type,
  onChange,
  onFocus,
  onBlur,
  placeholder,
}) => {
  const searchAheadRef = React.useRef();

  const [visible, setVisible] = React.useState(false);

  const handleFocus = React.useCallback(e => {
    if (e.currentTarget.value) {
      setVisible(true);
    }
    onFocus && onFocus();
  });

  const onComponentBlur = React.useCallback(() => {
    setTimeout(function() {
      setVisible(false);
    }, 100);
  });

  const handleBlur = useComponentBlur(searchAheadRef, onComponentBlur);

  const handleChange = React.useCallback(e => {
    setVisible(true);
    onChange && onChange(e);
  });

  const handleClick = React.useCallback(e => {
    setVisible(false);
  });

  const handleKeydown = React.useCallback(e => {
    if (e.keyCode === 13) {
      setVisible(false);
    }
  });

  React.useLayoutEffect(() => {
    !visible && onBlur && onBlur();
  }, [visible, onBlur]);

  React.useEffect(() => {
    if (queryRef.current) {
      queryRef.current.value = query ? query : '';
    }
  }, [query, queryRef]);

  return (
    <StyledSearchTypeAhead
      className="typeahead-search-input"
      ref={searchAheadRef}
    >
      <FormInput
        placeholder={placeholder}
        aria-owns="typeahead-results"
        autoComplete="off"
        className="header-search-query"
        defaultValue={query}
        inputRef={queryRef}
        name="query"
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      <TypeaheadQuery
        onBlur={handleBlur}
        onClick={handleClick}
        query={query}
        type={type}
        visible={visible}
      />
    </StyledSearchTypeAhead>
  );
};

const StyledSearchTypeAhead = styled.div`
  &.typeahead-search-input {
    position: relative;
    input {
      &:focus {
        outline: 0;
      }
    }
  }
`;
