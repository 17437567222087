import React from 'react';
import { useQuery } from '@apollo/client';
import { NavLink as Link } from 'react-router-dom';
import GET_TYPEAHEAD from 'graphql/getTypeahead';
import { groupBy } from 'lodash/fp';
import toText from 'util/toText';
import { escapeRegExp } from 'util/escapeRegExp';
import caseUrl from 'util/caseUrl';
import { statuteURL } from 'util/statuteURL';
import { basePaths } from 'app-constants';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

function typeaheadMeta(item, query) {
  switch (item.__typename) {
    case 'SpellingTypeaheadResult':
      return {
        prefix: 'Did you mean',
      };
    case 'CaseDocumentTypeaheadResult':
      return {
        suffix: `${item.cite} • ${item.date}`,
        url: `${caseUrl(item.caseId)}?query=${encodeURIComponent(query)}`,
      };
    case 'CodeOutlineTypeaheadResult':
      return {
        prefix: 'Browse',
        url: `${statuteURL(
          item.jurisdictionId,
          item.codeId,
        )}?query=${encodeURIComponent(query)}`,
      };
    case 'CodeSectionCitatorTypeaheadResult':
      return {
        prefix: 'See cases citing',
        url: `${statuteURL(
          item.jurisdictionId,
          item.codeSectionCodeId,
          item.sectionId,
        )}/citator?query=${encodeURIComponent(query)}`,
      };
    case 'OnlawOutlineTypeaheadResult':
      return {
        prefix: 'Browse',
        url: `${basePaths.secondarySources}/redirect/title/${
          item.onlawId
        }?query=${encodeURIComponent(query)}`,
      };
    case 'OnlawSearchCaseTypeaheadResult':
    case '/secondary-sources':
      return {
        prefix: 'See Secondary Sources citing',
        suffix: `${item.cite} • ${item.date}`,
        url: `${basePaths.secondarySources}/search?query=${encodeURIComponent(
          item.value,
        )}+"${encodeURIComponent(toText(item.cite))}"`,
      };
    case 'OnlawSearchCodeTypeaheadResult':
      return {
        prefix: 'See Secondary Sources citing',
        url: `${basePaths.secondarySources}/search?query=${encodeURIComponent(
          item.value,
        )}`,
      };
    case 'CodeSectionDocumentTypeaheadResult':
      return {
        url: `${statuteURL(
          item.jurisdictionId,
          item.codeSectionCodeId,
          item.sectionId,
        )}?query=${encodeURIComponent(query)}`,
      };
    case 'PractitionerDocumentTypeaheadResult':
      return {
        url: `${basePaths.practitioner}/contentfulRedirect/${
          item.contentfulId
        }`,
      };
    case 'CurrentAwarenessTypeaheadResult':
      return {
        prefix: '',
        url: `/posts/${item.slug}`,
        suffix: '',
      };
    case 'CourseTypeaheadResult':
      return {
        prefix: '',
        url: `/learning/courses/${item.slug}`,
        suffix: 'CLE Course',
      };
    case 'CoursesCollectionTypeaheadResult':
      return {
        prefix: '',
        url: item.title.match('Compliance Package')
          ? `/learning/collections/package/${item.slug}`
          : `/learning/collections/other/${item.slug}`,
        suffix: 'CLE Collection',
      };
    case 'NgramTypeaheadResult':
    default:
      return {};
  }
}

function withBoldMatch(str, q) {
  return str.replace(new RegExp(`(${escapeRegExp(q)})`, 'gi'), '<b>$1</b>');
}

const TypeaheadGroup = props => (
  <StyledTypeaheadGroup>
    {props.group.map((item, i) => {
      const { prefix = '', url, suffix = '' } = typeaheadMeta(
        item,
        props.query,
      );
      return (
        <li key={i}>
          <Link
            role="option"
            aria-selected="false"
            onClick={props.onClick}
            onBlur={props.onBlur}
            to={
              url
                ? url
                : `${props.overrideUrl ||
                    props.typeUrl}/search?query=${encodeURIComponent(
                    item.value,
                  )}`
            }
            title={`${prefix} ${item.value || item.title} ${suffix.replace(
              /<.*?>/gi,
              '',
            )}`}
          >
            {prefix ? <label>{prefix}</label> : null}
            <span
              dangerouslySetInnerHTML={{
                __html: withBoldMatch(item.value || item.title, props.query),
              }}
            />
            {suffix ? (
              <cite
                dangerouslySetInnerHTML={{
                  __html: suffix,
                }}
              />
            ) : null}
          </Link>
        </li>
      );
    })}
  </StyledTypeaheadGroup>
);

const StyledTypeaheadGroup = styled.ul`
  padding: 10px 13px 9px 13px;
  list-style: none;

  a,
  a:visited {
    color: inherit;
    display: block;
  }

  a:hover span {
    text-decoration: underline;
  }

  li {
    font-size: 14px;
    margin-bottom: 1px;
    span + cite {
      color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
      font-style: normal;
      margin-left: 0.5em;
    }
  }

  label:first-child {
    color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
    font-style: italic;
    margin-right: 0.3em;
    &:hover {
      cursor: inherit;
    }
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_TABLET}) {
    min-width: 430px;
    white-space: nowrap;
  }
`;

export const TypeaheadItems = ({
  query,
  typeUrl,
  overrideUrl,
  types,
  onClick,
  onBlur,
  visible,
}) => {
  const { data } = useQuery(GET_TYPEAHEAD, {
    variables: { query, types },
  });
  const items = data?.typeaheadSearch || [];
  const groups = groupBy('__typename')(items);

  return visible && items.length > 0 ? (
    <StyledTypeaheadItems id="typeahead-results" role="listbox">
      {Object.keys(groups).map((group, i) => (
        <li key={i}>
          <TypeaheadGroup
            query={query}
            group={groups[group]}
            typeUrl={typeUrl}
            overrideUrl={overrideUrl}
            onClick={onClick}
            onBlur={onBlur}
          />
        </li>
      ))}
    </StyledTypeaheadItems>
  ) : null;
};

const StyledTypeaheadItems = styled.ul`
  background-color: white;
  border-radius: 6px;
  border: 1px solid ${CEB_COLOR('SILVER')};
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
  list-style: none;
  > li {
    padding: 1px 0;
    border-top: 1px solid ${CEB_COLOR('SILVER')};
    &:first-child {
      border-top: none;
    }
  }
`;
