import { statuteURL } from 'util/statuteURL';

export function getStatuteUri(el) {
  const { statuteJdx, statuteCode, statuteSection } = el.dataset.statuteJdx
    ? el.dataset
    : el.parent.dataset;
  return statuteJdx
    ? statuteURL(statuteJdx, statuteCode, statuteSection)
    : null;
}
