import React from 'react';
import styled from 'styled-components';

export function DoubleTriangle({ color = 'white' }) {
  return (
    <StyledDoubleTriangle color={color} className="triangles">
      <div className="icon">
        <div className="up" />
        <div className="down" />
      </div>
    </StyledDoubleTriangle>
  );
}

const StyledDoubleTriangle = styled.div.attrs(props => ({
  color: props.color || 'white',
}))`
  display: inline-block;

  .icon {
    display: flex;
    flex-direction: column;
    float: right;
    .up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${({ color }) => color};
      margin-bottom: 5px;
    }
    .down {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${({ color }) => color};
    }
  }
`;
