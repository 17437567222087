import { gql } from '@apollo/client';

const HAS_IP_ACCESS = gql`
  mutation getIpAccessSessionToken {
    ipAccess {
      session {
        token
      }
    }
  }
`;

export default HAS_IP_ACCESS;
