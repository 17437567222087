import { useMutation } from '@apollo/client';
import useDropdown from 'hooks/useDropdown';
import React from 'react';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { BookmarkPanelContext } from '.';
import { DeleteFolderConfirmationDialog } from './DeleteFolderConfirmationDialog';
import DELETE_BOOKMARK_FOLDER from './graphql/deleteBookmarkFolder';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';

export const FolderMenuContext = React.createContext();

export const FolderMenu = React.forwardRef(({ children, folders }, ref) => {
  const [deleteFolderMutation] = useMutation(DELETE_BOOKMARK_FOLDER);
  const [deletingFolder, setDeletingFolder] = React.useState();
  const { setFolderId } = React.useContext(BookmarkPanelContext);

  const [
    trashDropdown,
    toggleTrashDropdown,
    onTrashBlur,
    trashDropdownRef,
  ] = useDropdown();

  const handleCancelDelete = React.useCallback(
    e => {
      e.stopPropagation();
      toggleTrashDropdown(e);
      setDeletingFolder(undefined);
    },
    [toggleTrashDropdown],
  );

  const deleteFolder = React.useCallback(async e => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await deleteFolderMutation({
        variables: {
          id: deletingFolder.id,
        },
        refetchQueries: [{ query: GET_BOOKMARK_FOLDERS }],
      });
      setFolderId(folders.filter(f => f.id !== deletingFolder.id)[0]?.id);
      setDeletingFolder(undefined);
      toggleTrashDropdown(e);
    } catch (error) {
      console.error(error);
    }
  });

  React.useEffect(() => {
    if (trashDropdown && trashDropdownRef.current) {
      trashDropdownRef.current.querySelector('button:last-child').focus();
      setFolderId(deletingFolder.id);
    }
  }, [trashDropdown]);

  return (
    <FolderMenuContext.Provider
      value={{
        toggleTrashDropdown,
        setDeletingFolder,
      }}
    >
      {trashDropdown && (
        <StyledDialogContainer>
          <DeleteFolderConfirmationDialog
            confirm={deleteFolder}
            cancel={handleCancelDelete}
            folder={deletingFolder}
            ref={trashDropdownRef}
          />
        </StyledDialogContainer>
      )}
      {!trashDropdown && (
        <StyledFolderMenu ref={ref}>
          {children && children.length > 0 ? (
            children.map((child, key) => <section key={key}>{child}</section>)
          ) : (
            <section>{children}</section>
          )}
        </StyledFolderMenu>
      )}
    </FolderMenuContext.Provider>
  );
});

const StyledFolderMenu = styled.div`
  background-color: #fafaf9;
  border-radius: 3px;
  border: solid 1px #e4e4e2;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  color: #343434;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  margin-top: -13px;
  width: 100%;
  @media only screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    max-height: 269px;
    width: 260px;
    z-index: 10;
  }
  > section {
    flex: 1 1 auto;
  }
`;

const StyledDialogContainer = styled.span`
  color: #343434;
  display: block;
  left: 0;
  margin-top: -22px;
  position: absolute;
  top: 3em;
  z-index: 10;
`;
