import React from 'react';
import styled from 'styled-components';

export const PillDropdown = React.forwardRef((props, ref) => (
  <StyledPillDropdown
    className="dropdown"
    role="dialog"
    ref={ref}
    tabIndex={0}
    onBlur={props.onBlur}
    color={props.color}
    innerBorderColor={props.innerBorderColor}
  >
    <div>{props.children}</div>
  </StyledPillDropdown>
));

const StyledPillDropdown = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
  margin-top: 4px;
  position: absolute;
  width: 331px;
  z-index: 30;
  &:before {
    border: 10px solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    content: ' ';
    height: 0;
    left: 20px;
    position: absolute;
    top: -20px;
    width: 0;
  }
  &:focus {
    outline: 0;
  }
  > div {
    background-color: white;
    padding: 12px 12px 8px;
    overflow: hidden;
    border-radius: 8px;
  }
`;
