import React from 'react';
import useResizeObserver from './useResizeObserver';
import { StickyContentHeaderContext } from 'components/statutes/StickyContentHeader';

export function useStickyContentHeader() {
  const [headerWidth, setHeaderWidth] = React.useState(0);
  const { setScrollThreshold, scrolled, setHeaderHeight } = React.useContext(
    StickyContentHeaderContext,
  );
  const headerRef = React.useRef(null);
  const headerRectRef = React.useRef({
    height: 0,
    x: 0,
    y: 0,
  });

  const [placeholderRef, entry, placeholderNode] = useResizeObserver();
  const { contentRect } = entry;

  React.useEffect(() => {
    if (!scrolled && placeholderNode && placeholderNode.current) {
      headerRectRef.current = headerRef.current.getBoundingClientRect();
      setScrollThreshold(headerRectRef.current.height);
      placeholderNode.current.style.height = 0;
    } else if (placeholderNode && placeholderNode.current) {
      placeholderNode.current.style.height = headerRectRef.current.height;
    }
  }, [scrolled]);

  React.useEffect(() => {
    setHeaderHeight && setHeaderHeight(headerRef.current.offsetHeight);
  }, [headerRef.current, scrolled]);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      const { width } = contentRect || {};
      setHeaderWidth(width);
    }, 50);
    return () => clearTimeout(handler);
  }, [contentRect?.width]);

  return { placeholderRef, headerRef, headerRectRef, headerWidth };
}
