import React from 'react';
import { SearchContext } from 'components/layouts/research-layout/SearchContext';
import { useQuery } from '@apollo/client';
import GET_SEARCH_TOTALS from 'graphql/getSearchTotals';
import { getTotalCount } from 'util/getTotalCount';
import { usePacificTime } from 'hooks/usePacificTime';
import { useAssetAccess } from 'components/asset-access';

export const OtherSectionContext = React.createContext();

export const TYPE_MAP = {
  news: 'DailyNews',
  cases: 'Cases',
  statutes: 'Statute',
  rules: 'Rules of Court',
  publications: 'Secondary Sources',
  practitioner: 'Practitioner Resources',
  course: 'CLE Courses',
};

export const OtherSectionsContextProvider = props => {
  const { queryString, variables, searchUrlFor } =
    React.useContext(SearchContext);

  const date = usePacificTime();

  const assetAccess = useAssetAccess();

  const optionFilters = React.useMemo(
    () => [
      'titleid_CRC',
      ...(assetAccess?.hasLocalRulesOfCourt
        ? [
            'titleid_LRC_LA',
            'titleid_LRC_SD',
            'titleid_LRC_OR',
            'titleid_LRC_FR',
            'titleid_LRC_SM',
            'titleid_LRC_SAC',
            'titleid_LRC_KR',
            'titleid_LRC_ST',
            'titleid_LRC_CC',
            'titleid_LRC_SB',
            'titleid_LRC_AL',
            'titleid_LRC_TU',
            'titleid_LRC_RI',
            'titleid_LRC_SF',
            'titleid_LRC_VE',
            'titleid_LRC_SBA',
            'titleid_LRC_SOL',
            'titleid_LRC_SON',
            'titleid_LRC_SJ',
            'titleid_LRC_SC',
          ]
        : []),
    ],
    [assetAccess],
  );

  const { data, loading } = useQuery(GET_SEARCH_TOTALS, {
    variables: { ...variables, date, optionFilters },
    skip: !variables?.query,
  });

  const [otherResults, setOtherResults] = React.useState({});

  React.useEffect(() => {
    if (data) {
      const newResults = {};
      Object.keys(TYPE_MAP).forEach(key => {
        const total = getTotalCount(`${key}.totalCount`)(data);
        if (total > 0) {
          newResults[key] = {
            total,
            url: searchUrlFor(key, queryString),
            label: TYPE_MAP[key],
          };
        }
      });
      setOtherResults(newResults);
    }
  }, [data]);

  return (
    <OtherSectionContext.Provider
      value={{
        results: otherResults,
        data,
        loading,
      }}
    >
      {props.children}
    </OtherSectionContext.Provider>
  );
};
