import React from 'react';
import { DoubleTriangle } from './DoubleTriangle';
import useDropdown from 'hooks/useDropdown';
import { FolderList, Folder } from './FolderList';
import { FolderMenu } from './FolderMenu';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';

export function FolderSelect({
  value,
  folders,
  onChange,
  className,
  children,
  color = 'white',
  contenteditable,
}) {
  const [dropdown, toggle, onBlur, dropdownContainer] = useDropdown();

  const onSelectFolder = React.useCallback((id, event) => {
    onChange && onChange(id);
    event.stopPropagation();
    toggle(event);
  });

  const folder = React.useMemo(
    () => folders.filter(folder => folder.id === value)[0],
    [folders, value],
  );

  return (
    <>
      {folders?.length > 0 ? (
        <StyledFolderSelect
          className={className}
          ref={dropdownContainer}
          color={color}
        >
          <button className="select" onClick={toggle}>
            <section>
              <i className="fa fa-folder-open" />
            </section>
            <section>
              <span>{folder?.name}</span>
            </section>
            <section>
              <DoubleTriangle color={color} />
            </section>
          </button>
          {dropdown && (
            <FolderMenu folders={folders}>
              {children}
              <FolderList
                folders={folders}
                onSelectFolder={onSelectFolder}
                contenteditable={contenteditable}
              />
            </FolderMenu>
          )}
        </StyledFolderSelect>
      ) : (
        <StyledFolderSelect className={className} color={color}>
          <Folder key={folder.id} folder={folder} onSelect={onSelectFolder} />
        </StyledFolderSelect>
      )}
    </>
  );
}

const StyledFolderSelect = styled.div.attrs(({ color }) => ({ color }))`
  position: relative;
  > button {
    display: flex;
    @media only screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      .sidebar & {
        width: 176px;
        section {
          span {
            width: calc(176px - 30px - 20px);
            overflow: hidden;
          }
        }
      }
    }
    section {
      flex: 1 1 auto;

      &:first-child,
      &:last-child {
        flex: 0 1 auto;
      }
      span {
        display: block;
        max-width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .select {
    color: ${({ color }) => color};
    cursor: default;
    font-family: 'BasisGrotesque', serif;
    font-weight: bold;
    line-height: 30px;
    margin: 13px 0;
    text-align: left;
    width: 100%;
    i {
      margin-right: 8px;
    }
    .triangles {
      clear: right;
      float: right;
      margin-top: 5px;
    }
  }
`;
