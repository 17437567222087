import React from 'react';
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import getUuid from 'uuid-by-string';

export interface ScrollCacheNavState {
  type: NavigationType;
  locationKey: string;
}

export interface ScrollCacheContextValue {
  setScrollCache?: (uniqueSelector: string, scrollTop: number) => void;
  getScrollCache?: (uniqueSelector: string) => number;
  navState?: ScrollCacheNavState;
}
export const ScrollCacheContext = React.createContext<ScrollCacheContextValue>(
  {},
);

export interface ScrollCacheProviderProps {
  children: React.ReactNode;
}

export function ScrollCacheProvider(props: ScrollCacheProviderProps) {
  const location = useLocation();
  const { pathname, search, hash } = location;
  const scrollCache = React.useRef(
    JSON.parse(sessionStorage.getItem('scrollCache') || '{}'),
  );

  const locationKey = React.useMemo(
    () => pathname + search,
    [pathname, search],
  );

  const setScrollCache = React.useCallback(
    (uniqueSelector: string, scrollTop: number) => {
      const uuid = getUuid(locationKey + uniqueSelector);
      scrollCache.current = {
        ...scrollCache.current,
        [uuid]: scrollTop,
      };
      try {
        sessionStorage.setItem(
          'scrollCache',
          JSON.stringify(scrollCache.current),
        );
      } catch (error) {
        console.error(error);
      }
    },
    [locationKey],
  );

  const getScrollCache = React.useCallback(
    (uniqueSelector: string) => {
      const uuid = getUuid(locationKey + uniqueSelector);
      const scrollTop = scrollCache.current ? scrollCache.current[uuid] : 0;
      return scrollTop;
    },
    [locationKey],
  );

  const navigationType = useNavigationType();

  const [navState, setNavState] = React.useState<
    ScrollCacheNavState | undefined
  >();

  React.useEffect(() => {
    switch (navigationType) {
      case NavigationType.Push:
        setNavState({ type: NavigationType.Push, locationKey });
        break;
      case NavigationType.Pop:
        setNavState({ type: NavigationType.Pop, locationKey });
        break;
      default:
    }
  }, [navigationType]);

  return (
    <ScrollCacheContext.Provider
      value={{
        setScrollCache,
        getScrollCache,
        navState,
      }}
    >
      {props.children}
    </ScrollCacheContext.Provider>
  );
}
