/* eslint-disable quotes */

export const $ = node => {
  return {
    node,
    parents: (sel, context) => {
      const parents = [];
      let parent = node.parentElement;
      while (parent && (!context || !parent.matches(context))) {
        if ((sel && parent.matches(sel)) || !sel) {
          parents.push(parent);
        }

        parent = parent.parentElement;
      }
      return $(parents);
    },
    find: sel => {
      return;
    },
  };
};

export const ELEMENT_NODE = document.ELEMENT_NODE || 1;

// ZeroClipboard's test
function isWindows() {
  const isWindowsRegex = /win(dows|[\s]?(nt|me|ce|xp|vista|[\d]+))/i;
  return (
    !!window.navigator &&
    (isWindowsRegex.test(window.navigator.appVersion || '') ||
      isWindowsRegex.test(window.navigator.platform || '') ||
      (window.navigator.userAgent || '').indexOf('Windows') !== -1)
  );
}

export const LINE_SEP = isWindows() ? '\r\n' : '\n';

// A mapping of Unicode code points to Code Page 1252 code points (when the
// mapping is not identical). See
// http://www.unicode.org/Public/MAPPINGS/VENDORS/MICSFT/WINDOWS/CP1252.TXT
const UNICODE_TO_CP1252 = {
  0x20ac: 0x80, //EURO SIGN
  0x201a: 0x82, //SINGLE LOW-9 QUOTATION MARK
  0x0192: 0x83, //LATIN SMALL LETTER F WITH HOOK
  0x201e: 0x84, //DOUBLE LOW-9 QUOTATION MARK
  0x2026: 0x85, //HORIZONTAL ELLIPSIS
  0x2020: 0x86, //DAGGER
  0x2021: 0x87, //DOUBLE DAGGER
  0x02c6: 0x88, //MODIFIER LETTER CIRCUMFLEX ACCENT
  0x2030: 0x89, //PER MILLE SIGN
  0x0160: 0x8a, //LATIN CAPITAL LETTER S WITH CARON
  0x2039: 0x8b, //SINGLE LEFT-POINTING ANGLE QUOTATION MARK
  0x0152: 0x8c, //LATIN CAPITAL LIGATURE OE
  0x017d: 0x8e, //LATIN CAPITAL LETTER Z WITH CARON
  0x2018: 0x91, //LEFT SINGLE QUOTATION MARK
  0x2019: 0x92, //RIGHT SINGLE QUOTATION MARK
  0x201c: 0x93, //LEFT DOUBLE QUOTATION MARK
  0x201d: 0x94, //RIGHT DOUBLE QUOTATION MARK
  0x2022: 0x95, //BULLET
  0x2013: 0x96, //EN DASH
  0x2014: 0x97, //EM DASH
  0x02dc: 0x98, //SMALL TILDE
  0x2122: 0x99, //TRADE MARK SIGN
  0x0161: 0x9a, //LATIN SMALL LETTER S WITH CARON
  0x203a: 0x9b, //SINGLE RIGHT-POINTING ANGLE QUOTATION MARK
  0x0153: 0x9c, //LATIN SMALL LIGATURE OE
  0x017e: 0x9e, //LATIN SMALL LETTER Z WITH CARON
  0x0178: 0x9f, //LATIN CAPITAL LETTER Y WITH DIAERESIS
};

// There are four exceptions that should not be coded as Unicode escape
// sequences, but should use predefined RTF escape sequences.
const UNICODE_TO_RTF_ESCAPE = {
  0x0a: '\\line ', //NEWLINE
  0x0c: '\\page ', //FORM FEED
  0xa0: '\\~', //NON-BREAKING SPACE
  0xad: '\\-', //SOFT HYPHEN
};

export function escapeRTF(s) {
  // RTF can only accept 7-bit ASCII. Translate any characters
  // outside that range (or reserved characters like '{' or '}')
  // into escape sequences. See https://goo.gl/7pYhSb
  // eslint-disable-next-line no-control-regex
  return s.replace(/([\x00-\x1f\\{}\x80-\uffff])/g, c => {
    let code = c.charCodeAt(0);

    // Some code points shouldn't be escaped.
    if (UNICODE_TO_RTF_ESCAPE.hasOwnProperty(code)) {
      return UNICODE_TO_RTF_ESCAPE[code];
    }

    // If the code point is in CP1252, use that code point, as some
    // applications without Unicode support will still accept these
    // CP1252 characters.
    if (UNICODE_TO_CP1252.hasOwnProperty(code)) {
      code = UNICODE_TO_CP1252[code];
    }

    if (code <= 255) {
      // Escape CP1252 characters.
      // eslint-disable-next-line no-useless-escape
      return `\'${code.toString(16)}`;
    }

    // Escape Unicode characters. See
    // http://latex2rtf.sourceforge.net/rtfspec_6.html for sources
    // of quotations.

    // "RTF control words generally accept signed 16-bit numbers as
    // arguments. For this reason, Unicode values greater than 32767
    // must be expressed as negative numbers."
    if (code >= 32768) {
      code -= 65536;
    }

    // Use \uN to represent "a single Unicode character that has no
    // equivalent ANSI representation based on the current ANSI code
    // page. [...] This keyword is followed immediately by equivalent
    // character(s) in ANSI representation."
    // We display a question mark if pasted into an application that
    // doesn't support Unicode.
    return `\\uc1\\u${code}?`;
  });
}

export function parenthesize(specifiers) {
  return specifiers.map(s => `(${s})`).join('');
}
