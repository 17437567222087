import React from 'react';
import styled from 'styled-components';

export const CustomerSupport = () => (
  <StyledCustomerSupport>
    <li>
      <b>
        Mon – Fri <span>7:30am – 5:00pm</span> (PST)
      </b>
    </li>
    <li>
      <i className="fas fa-phone" />{' '}
      <a href="tel:18002323444">
        1 (800) <span>232-3444</span>
      </a>
    </li>
    <li>
      <i className="fas fa-phone" /> <strong>Tech:</strong>{' '}
      <a href="tel:18007509155">
        1 (800) <span>750-9155</span>
      </a>
    </li>
    <li>
      <i className="fas fa-envelope" />{' '}
      <a href="mailto: tech_support@ceb.ucla.edu">Email Support</a>
    </li>
  </StyledCustomerSupport>
);

const StyledCustomerSupport = styled.ul`
  span {
    white-space: nowrap;
  }
  .fa-phone {
    transform: rotate(90deg);
    vertical-align: -4px;
  }

  li {
    margin: 0.5em 0;
    &:first-child {
      margin-top: 0;
    }
  }
`;
