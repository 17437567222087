import { ENVIRONMENT_NAME } from 'app-constants';
import React from 'react';
import styled from 'styled-components';
import colorForAttitude from 'util/colorForAttitude';

export function EnvironmentBanner() {
  return !ENVIRONMENT_NAME || ENVIRONMENT_NAME === 'PRODUCTION' ? null : (
    <StyledEnvironmentBanner className="env-banner">
      Current Environment: {ENVIRONMENT_NAME}
    </StyledEnvironmentBanner>
  );
}

const StyledEnvironmentBanner = styled.p`
  background-color: ${colorForAttitude('CAUTION')};
  border-bottom: 1px solid #fff;
  color: #fff;
  font-family: 'BasisGrotesque', sans-serif;
  font-weight: bold;
  padding: 0.5em 0;
  text-align: center;
  width: 100%;
`;
