import React from 'react';
import { TabbableLocationContext } from '../preview-tabs/PreviewPanel';
import { ArticlePreview } from './ArticlePreview';
import { ArticleSectionPreview } from './ArticleSectionPreview';
import { CasePreview } from './CasePreview';
import { StatutePreview } from './StatutePreview';
import { StatutesPreview } from './StatutesPreview';

export function ContentRenderer() {
  return (
    <ContentRouter>
      <ContentRoute
        path="/primary-law/:type/:judicataId"
        element={<CasePreview />}
      />
      <ContentRoute
        path="/primary-law/:type/:jurisdictionId/codes/:codeJudicataId/:judicataIdBegin/:judicataIdEnd"
        element={<StatutesPreview />}
      />
      <ContentRoute
        path="/primary-law/:type/:jurisdictionId/codes/:codeJudicataId/:judicataId"
        element={<StatutePreview />}
      />
      <ContentRoute
        path="/primary-law/:type/:jurisdictionId/const/:article/:judicataId"
        element={<></>}
      />
      <ContentRoute
        path="/primary-law/:type/:jurisdictionId/const/:article/:judicataId"
        element={<ArticleSectionPreview />}
      />
      <ContentRoute
        path="/primary-law/:type/:jurisdictionId/const/:article"
        element={<ArticlePreview />}
      />
    </ContentRouter>
  );
}

function ContentRoute({ path, element }) {
  const { match } = React.useContext(TabbableLocationContext);
  return <>{path === match?.pattern?.path && element}</>;
}

function ContentRouter({ children }) {
  return <>{children}</>;
}
