import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import startsWith from 'lodash/fp/startsWith';

export const getPartialValue = flow(
  map(item => [startsWith(item), constant(item)]),
  cond,
);
