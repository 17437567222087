import React, { useContext } from 'react';
// import { useAssetAccess } from 'components/asset-access';
import { useHeapIdentify } from './useHeapIdentify';
import { useBugsnagUser } from './useBugsnagUser';
import { useWrappedIntercom } from 'components/app/useWrappedIntercom';
import { AppContext } from '.';
import { useAssetAccess } from 'components/asset-access';

export default function Analytics(props) {
  const user = useContext(AppContext);

  const { hasOnlawProducts } = useAssetAccess();

  const assetProperties = {
    hasOnLaw: hasOnlawProducts,
  };

  const intercom = useWrappedIntercom();

  useHeapIdentify(user, assetProperties);
  useBugsnagUser(user);

  intercom.update(assetProperties);

  return props.children;
}
