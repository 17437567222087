import TagManager from 'react-gtm-module';
import { CEB_PORTAL_GA_ID } from 'app-constants';
import { useLocation } from 'react-router-dom';
import { AppContext } from 'components/app-context';
import { useContext } from 'react';
import { useWrappedIntercom } from './useWrappedIntercom';

if (CEB_PORTAL_GA_ID) {
  TagManager.initialize({
    gtmId: CEB_PORTAL_GA_ID,
  });
}

export function PageTracking() {
  const location = useLocation();
  const { user } = useContext(AppContext);
  const intercom = useWrappedIntercom();

  const href = location.pathname + location.search;

  intercom.update({
    last_request_at: parseInt(new Date().getTime() / 1000),
  });

  if (typeof window !== 'undefined') {
    if (window.Munchkin)
      window.Munchkin.munchkinFunction('clickLink', { href });
    if (user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ user_id: user.accountId });
      window.setCookie('GAUSERID', user.accountId);
    }
  }

  return null;
}
