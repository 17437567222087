import React from 'react';
import { useLocation } from 'react-router';
import { ResearchLayout } from 'components/layouts/research-layout';
import NotFound from '../not-found';

export const Show404Context = React.createContext();

export function Show404(props) {
  const location = useLocation();
  const [show404, setShow404] = React.useState(false);

  React.useEffect(() => {
    setShow404(false);
  }, [location]);

  return (
    <Show404Context.Provider value={{ show404: () => setShow404(true) }}>
      {show404 ? (
        <ResearchLayout showFooter hideNav>
          <NotFound />
        </ResearchLayout>
      ) : (
        props.children
      )}
    </Show404Context.Provider>
  );
}
