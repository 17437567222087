import { escapeRTF } from 'util/cite';
import isUndefined from 'lodash/fp/isUndefined';
import isArray from 'lodash/fp/isArray';

export function getCitation(
  citation,
  format,
  { startPage, endPage, footnote, subdivisions },
  search,
  replace,
) {
  const cite = {
    rtf: '',
    html: '',
    text: '',
  };

  if (!citation) return cite;

  citation.map(fragment => {
    const citationFragment = isArray(fragment)
      ? { format: fragment[0], text: fragment[1] }
      : fragment;
    const fragText =
      !search || !replace
        ? citationFragment.text
        : (citationFragment.text || '').replace(search, replace);
    switch (citationFragment.format) {
      case 'italic':
        cite.rtf += `{\\i ${escapeRTF(fragText)}}`;
        cite.html += `<i>${fragText}</i>`;
        cite.text += fragText;
        break;
      case 'underline':
        cite.rtf += `{\\ul ${escapeRTF(fragText)}}`;
        cite.html += `<u>${fragText}</u>`;
        cite.text += fragText;
        break;
      default:
        if (fragText) {
          cite.rtf += escapeRTF(fragText);
          cite.html += fragText;
          cite.text += fragText;
        }
    }

    const rangeStart = startPage;
    let rangeEnd;
    if (endPage && startPage !== endPage) {
      if (
        format !== 'california' &&
        +startPage &&
        +endPage &&
        startPage.length >= 3 &&
        startPage.substring(0, startPage.length - 2) ===
          endPage.substring(0, endPage.length - 2)
      ) {
        rangeEnd = endPage.substring(endPage.length - 2);
      } else {
        rangeEnd = endPage;
      }
    }
    let pages = rangeStart;
    if (!isUndefined(rangeEnd)) {
      pages += `\u2013${rangeEnd}`;
    }

    if (fragment.typeName === 'official.at_pages') {
      if (!isUndefined(footnote)) {
        pages += `${format !== 'california' ? ' n.' : ', fn. '}${footnote}`;
      }

      cite.rtf += pages;
      cite.html += pages;
      cite.text += pages;
    }

    if (fragment.typeName === 'subdivisions') {
      let prefix = '';
      if (format === 'california' && subdivisions) {
        // California Style Manual requires "subd." (or "subds." before
        // ranges).
        const isRange = subdivisions.match('\u2013');
        prefix = `, ${isRange ? 'subds.' : 'subd.'} `;
      }

      cite.rtf += escapeRTF(prefix + subdivisions);
      cite.html += prefix + subdivisions;
      cite.text += prefix + subdivisions;
    }
    return null;
  });

  return cite;
}
