import { getContentUri } from 'util/getContentUri';

export function applyHrefs(contentEl, hrefParser = getContentUri) {
  const links = contentEl?.querySelectorAll('a');

  if (links) {
    for (const link of links) {
      const href = hrefParser(link);
      // eslint-disable-next-line no-script-url
      link.href = href ? href : 'javascript:void(0)';
    }
  }
}
