import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import {
  FirmFragment,
  GetFirmDocument,
  GetUserDocument,
  UserFragment,
} from '__generated__/graphql';

export interface UserWithFirm extends UserFragment {
  firm: FirmFragment;
}

export function useUser() {
  const {
    data: { user },
  } = useSuspenseQuery(GetUserDocument);

  const {
    data: { firm },
  } = useSuspenseQuery(GetFirmDocument);

  const userWithFirm = React.useMemo<UserWithFirm>(() => {
    return { ...(user as UserFragment), firm: firm as FirmFragment };
  }, [user, firm]);

  return userWithFirm;
}
