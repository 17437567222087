import { ELEMENT_NODE } from '.';

const getMargin = node => (node ? node.getAttribute('margin') : undefined);

// Find the page that node falls on by:
// - Looking backward to find the nearest page number
// - If none is found, look forward to find the next page number and try to
//   subtract 1 from it (which may not be possible if the page number is
//   not an integer)
// - If all else fails, assume the citation is on the first page of the
//   case and use the page number from the reporter cite
export const getPn = node => {
  const pn = getMargin(findPrevPn(node));
  if (pn) {
    return pn;
  }

  const nextPn = getMargin(findNextPn(node));
  // Other than junk page numbers, some page numbers have a format like
  // "1317a", "1317b", etc. These page numbers do not appear to be right
  // or useful, so just fall back as we do for other junk page numbers.
  if (+nextPn) {
    return (parseInt(nextPn, 10) - 1).toString();
  }
};

// Borrowed from lmnt: https://github.com/buyog/lmnt
function nextElementSibling(el) {
  if (el && el.nextElementSibling) {
    return el.nextElementSibling;
  }
  if (!el) {
    return null;
  }
  do {
    el = el.nextSibling;
  } while (el && el.nodeType !== ELEMENT_NODE);
  return el;
}

function previousElementSibling(el) {
  if (el && el.previousElementSibling) {
    return el.previousElementSibling;
  }
  if (!el) {
    return null;
  }
  do {
    el = el.previousSibling;
  } while (el && el.nodeType !== ELEMENT_NODE);
  return el;
}

export function findNextPn(node) {
  return findPn(node, false);
}

export function findPrevPn(node) {
  return findPn(node, true);
}

// Find the next or previous page number element. Use findNextPn() or
// findPrevPn() instead of this function.
// We accomplish this by "restarting" an in-order traversal from node.
// Usually the information about where we came from and where we are going
// would be tied up in a stack (either through recursion or an explicit
// stack).  Instead, we block off where we don't want to go by adding the
// nodes to a visited list.
export const findPn = (node, backward) => {
  let n = node;
  const stack = [];
  stack.push(n);
  const visited = [];
  let pagenumber;
  function shouldVisit(node) {
    return !visited.includes(node) && !stack.includes(node);
  }

  // Prevent the traversal from visiting siblings to the left of n. This
  // is used when traversing the DOM forward from n.
  function blockSiblingsBackward(n) {
    let sib = previousElementSibling(n);
    while (sib) {
      visited.push(sib);
      sib = previousElementSibling(sib);
    }
  }

  // Prevent the traversal from visiting siblings to the right of n. This
  // is used when traversing the DOM backward from n.
  function blockSiblingsForward(n) {
    let sib = nextElementSibling(n);
    while (sib) {
      visited.push(sib);
      sib = nextElementSibling(sib);
    }
  }

  while (stack.length !== 0) {
    n = stack.pop();
    visited.push(n);

    if (backward) {
      blockSiblingsForward(n);
    } else {
      blockSiblingsBackward(n);
    }

    if (n.classList && n.classList.contains('pagenumber')) {
      if (n.parentNode !== node) {
        pagenumber = n;
        break;
      }
    }

    if (n.parentNode) {
      if (shouldVisit(n.parentNode)) {
        stack.push(n.parentNode);
      }
    }

    const sibling = backward
      ? previousElementSibling(n)
      : nextElementSibling(n);

    if (sibling) {
      if (shouldVisit(sibling)) {
        stack.push(sibling);
      }
    }

    if (n.children && n.children.length > 0) {
      const child = backward
        ? n.children[n.children.length - 1]
        : n.children[0];
      if (shouldVisit(child)) {
        stack.push(child);
      }
    }
  }

  return pagenumber;
};
