import React from 'react';
import {
  StyledValidatedButton,
  StyledValidatedInput,
} from 'components/common/StyledForm';
import { ValidatedForm } from 'components/common/validated-form';
import { useMutation } from '@apollo/client';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';
import CREATE_BOOKMARK_FOLDER from './graphql/createBookmarkFolder';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export function CreateFolderButton({ folders, onCreate }) {
  const nameInputRef = React.useRef();

  const [showForm, setShowForm] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [mutate] = useMutation(CREATE_BOOKMARK_FOLDER);

  const onSubmit = React.useCallback(async form => {
    try {
      const {
        data: { createBookmarkFolder: folder },
      } = await mutate({
        variables: {
          name: form.name,
        },
        refetchQueries: [{ query: GET_BOOKMARK_FOLDERS }],
      });
      setErrors([]);
      setShowForm(false);
      onCreate(folder.id);
    } catch (error) {
      if (/name must be unique/.test(error.message))
        setErrors(['Folder Already Exists']);
    }
  });

  const openForm = React.useCallback(e => {
    e.stopPropagation();
    setErrors([]);
    setShowForm(true);
  });

  const handleInputChange = React.useCallback(e => {
    if (errors.length) {
      setErrors([]);
    }
  });

  const handleKeyDown = React.useCallback(
    e => {
      if (e.keyCode === 27) {
        setShowForm(false);
      }
    },
    [setShowForm],
  );

  React.useEffect(() => {
    showForm && nameInputRef.current && nameInputRef.current.focus();
  }, [showForm]);

  return (
    <StyledCreateFolderButton>
      {!showForm && (
        <button className="create-folder" onClick={openForm}>
          <i className="fa fa-plus-circle" /> Create New Folder...
        </button>
      )}
      {showForm && (
        <>
          <ValidatedForm onSubmit={onSubmit}>
            <StyledValidatedInput
              name="name"
              placeholder="New Folder Name"
              required
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              ref={nameInputRef}
            />
            <StyledValidatedButton>Save</StyledValidatedButton>
          </ValidatedForm>
          {errors.length > 0 ? (
            <ul className="errors">
              {errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          ) : null}
        </>
      )}
    </StyledCreateFolderButton>
  );
}

const StyledCreateFolderButton = styled.div`
  padding: 0 12px;
  form {
    display: flex;
    > div {
      margin: 0;
      + div {
        margin-left: 5px;
      }
    }
    input {
      padding: 6px 8px 5px;
      box-shadow: 1px 1px 4px 0 rgba(74, 75, 83, 0.08);
      border: solid 1px ${CEB_COLOR_RGBA('BLACK', 0.15)};
      font-family: 'Zilla Slab', serif;
      font-size: 14px;
      font-weight: bold;
    }
    button {
      padding: 6px 9px 3px;
      border-radius: 3px;
      background-color: ${CEB_COLOR('NICE_BLUE')};
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      color: white;
    }
  }

  button.create-folder {
    background: ${CEB_COLOR_RGBA('BLACK', 0.1)};
    border-radius: 3px;
    box-sizing: border-box;
    color: #343434;
    display: block;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 7px 0 5px;
    text-align: center;
    width: 100%;
  }
  .errors {
    color: ${CEB_COLOR('WARNING_RED')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 11px;
    font-weight: bold;
    list-style-type: none;
    margin-top: 1px;
  }
`;
