import React from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import last from 'lodash/fp/last';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { GetHistoryItemsDocument } from '__generated__/graphql';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
const WEEKDAYS = dayjs.weekdays();

export const DateSelectCalendar = ({
  onChangeDate,
  selected,
  historyDates,
  selectedMonth,
  setSelectedMonth,
}) => {
  const adjustMonth = React.useCallback((e, adjust) => {
    e.stopPropagation();
    setSelectedMonth(selectedMonth.add(adjust, 'month'));
  });

  const firstWeek = new Array(7).fill(0).map((v, i) => {
    const offset = i - selectedMonth.day();
    return selectedMonth.add(offset, 'day');
  });

  const weeks = [firstWeek];

  for (let i = 1; i < 6; i++) {
    const nextWeek = new Array(7).fill(0).map((v, j) => {
      const weekOffset = 7 * i;
      return firstWeek[0].add(weekOffset + j, 'day');
    });
    if (nextWeek[0].isAfter(selectedMonth, 'month')) break;
    weeks.push(nextWeek);
  }

  const { loading, data } = useQuery(GetHistoryItemsDocument, {
    variables: {
      startDate: weeks[0][0],
      endDate: last(last(weeks)),
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <StyledDateSelectCalendar>
      <table>
        <thead>
          <tr>
            <th>
              <button
                disabled={dayjs(last(historyDates)).isAfter(
                  selectedMonth.subtract(1, 'month'),
                  'month',
                )}
                title={selectedMonth.add(-1, 'month').format('MMMM YYYY')}
                onClick={e => adjustMonth(e, -1)}
              >
                <i className="fa fa-arrow-left" />
              </button>
            </th>
            <th colSpan="5">
              <h5>{selectedMonth.format('MMMM YYYY')}</h5>
            </th>
            <th>
              <button
                disabled={selectedMonth.isSame(dayjs(), 'month')}
                onClick={e => adjustMonth(e, 1)}
                title={selectedMonth.add(1, 'month').format('MMMM YYYY')}
              >
                <i className="fa fa-arrow-right" />
              </button>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            {WEEKDAYS.map((day, i) => (
              <th key={`weekday-${i}`}>
                <span title={day}>{day.slice(0, 1)}</span>
              </th>
            ))}
          </tr>
        </thead>
        {!loading && data ? (
          <tbody>
            {weeks.map((week, i) => {
              return (
                <tr key={`week-${i}`}>
                  {week.map((day, j) => {
                    return (
                      <td
                        key={`day-${j}`}
                        className={`${
                          day.isSame(selectedMonth, 'month')
                            ? 'currentMonth'
                            : 'otherMonth'
                        }`}
                      >
                        {data.historyItemsByDate.filter(historyItem => {
                          return dayjs(historyItem.createdAt).isSame(
                            day,
                            'day',
                          );
                        }).length > 0 ? (
                          <button
                            onClick={onChangeDate}
                            className={`${
                              dayjs(selected, 'YYYY-MM-DD').isSame(day, 'day')
                                ? 'current'
                                : ''
                            }`}
                            title={day.format('YYYY-MM-DD')}
                            data-start-date={day.format('YYYY-MM-DD')}
                          >
                            {day.format('D')}
                          </button>
                        ) : (
                          day.format('D')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          </tbody>
        )}
      </table>
    </StyledDateSelectCalendar>
  );
};

const StyledDateSelectCalendar = styled.div`
  height: 100%;
  width: 262px;

  table {
    border: 0;
    height: 100%;
    padding: 0;
    width: 100%;
    th,
    td {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      height: 24px;
      text-align: center;
      width: 24px;
    }
    th {
      color: ${CEB_COLOR_RGBA('NICE_BLUE', 0.5)};
      font-weight: bold;
      h5 {
        color: ${CEB_COLOR('BLACK')};
        font-size: 14px;
      }
      button {
        color: ${CEB_COLOR('COOL_BLUE')};
        &[disabled] {
          opacity: 0.2;
          &:hover {
            cursor: default;
          }
        }
      }
    }
    thead th {
      height: 24px;
    }
    tbody {
      height: 100%;
      button {
        background-color: ${CEB_COLOR_RGBA('BLACK', 0.1)};
        border-radius: 24px;
        height: 24px;
        width: 24px;
        &:hover {
          background-color: ${CEB_COLOR_RGBA('COOL_BLUE', 0.2)};
        }
        &.current {
          background-color: ${CEB_COLOR('COOL_BLUE')};
          color: white;
        }
      }
    }
  }

  .otherMonth {
    opacity: 0.5;
  }
`;
