import React from 'react';
import { useLocation, useNavigationType } from 'react-router';
import { useNavigateWithState } from 'components/routes/RedirectWithState';
import { removeClosedTab, addClosedTab } from './closedTabSessionHelper';
import { isEqual } from 'lodash/fp';

export function useTabs({ limit }) {
  const location = useLocation();
  const { state: locationState } = location;
  const { tabState } = locationState || {};
  const { pathname, search, hash } = location;

  const [state, dispatch] = React.useReducer(TabsReducer, {
    tabs: [],
    current: undefined,
    limit,
  });

  const navigationType = useNavigationType();
  const navigate = useNavigateWithState();

  React.useEffect(() => {
    if (isEqual(locationState?.tabState, state)) return;
    navigate(pathname + search + hash, {
      replace: true,
      state: {
        tabState: state,
      },
    });
  }, [state, pathname, search, hash, locationState]);

  React.useLayoutEffect(() => {
    const tabs = tabState?.tabs || [];
    const current = tabState?.current;
    if (navigationType === 'POP') {
      dispatch({ type: 'RESTORE_TABS', tabs, current });
    }
  }, [navigationType, location.pathname, location.search, location.hash]);

  const dispatchWrapper = React.useCallback(
    action => {
      switch (action.type) {
        case 'ADD_TAB':
          removeClosedTab(action.tab);
          break;
        case 'REMOVE_TAB':
          addClosedTab(action.tab);
          break;
        default:
          break;
      }

      dispatch(action);
    },
    [dispatch],
  );

  return [state, dispatchWrapper];
}

function PartitionMinimized(state) {
  return [...state.tabs]
    .reverse()
    .filter(tab => !state.current || tab.uri !== state.current.uri)
    .reduce(
      ({ minimized, overflow }, tab) => {
        return {
          minimized:
            minimized.length < state.limit ? [...minimized, tab] : minimized,
          overflow:
            minimized.length >= state.limit ? [...overflow, tab] : overflow,
        };
      },
      { minimized: [], overflow: [] },
    );
}

function TabsReducer(state, action) {
  let tabs = state.tabs;
  switch (action.type) {
    case 'ADD_TAB':
      tabs = [
        ...state.tabs.filter(tab => tab.uri !== action.tab.uri),
        action.tab,
      ];
      state.current = action.tab;
      break;
    case 'REMOVE_TAB':
      tabs = state.tabs.filter(tab => tab.uri !== action.tab.uri);
      if (state.current && state.current.uri === action.tab.uri)
        state.current = undefined;
      break;
    case 'MAXIMIZE_TAB':
      state.current = action.tab;
      tabs = [...tabs.filter(tab => tab.uri !== action.tab.uri), action.tab];
      break;
    case 'MINIMIZE_TAB':
      state.current = undefined;
      break;
    case 'RESTORE_TABS':
      tabs = action.tabs;
      state.current = action.current;
      break;
    default:
      return { ...state };
  }

  return {
    ...state,
    tabs,
    ...PartitionMinimized({ ...state, tabs }),
  };
}
