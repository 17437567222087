import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export const SectionTabs = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const SectionTab = styled.li`
  flex: 0 1 auto;
  + li {
    margin-left: 38px;
  }
  a,
  > span {
    border-bottom: 3px solid transparent;
    display: block;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    padding: 8px 0 12px;
    &[aria-current='page'] {
      border-bottom-color: ${CEB_COLOR('ORANGEY_YELLOW')};
    }
    span {
      font-weight: normal;
    }
  }
  > span {
    color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
  }
`;
