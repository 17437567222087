import React from 'react';
import { CaseContent } from 'components/cases/case/CaseContent';
import {
  ContentRendererContext,
  useSandboxedLocation,
} from '../preview-tabs/PreviewPanel';
import useHistory from 'hooks/useHistory';
import { HISTORY_MAP } from 'components/history-panel';
import { AppContext } from 'components/app-context';
import { useLocation } from 'react-router';

export function CasePreview() {
  const { data, variables } = React.useContext(ContentRendererContext);
  const sandboxedLocation = useSandboxedLocation();
  const { location: referrer } = useLocation();
  const { localUser } = React.useContext(AppContext);

  const { mutateHistory, recentHistoryLoading } = useHistory(referrer);

  React.useEffect(() => {
    if (localUser?.authorized && !recentHistoryLoading) {
      mutateHistory({
        variables: {
          text: data.case.name,
          type: 'CASE',
          action: 'VIEW',
          subtype: HISTORY_MAP.VIEW_CASE,
        },
      });
    }
  }, [data, recentHistoryLoading]);

  return (
    <CaseContent
      case={data.case}
      hash={sandboxedLocation.hash}
      query={variables.fromQuery}
      defaultCollapsed={true}
    />
  );
}
