import React from 'react';

const Tools = props => (
  <svg width={20} height={20} {...props}>
    <path
      d="M16.91 10.454a5.212 5.212 0 0 0 .975-6.02l-2.662 2.659c-.44.44-1.151.44-1.59 0l-.6-.6a1.125 1.125 0 0 1 0-1.591l2.7-2.7a5.21 5.21 0
0 0-7.2 6.815L1.9 15.645a1.156 1.156 0 0 0 0 1.636l.818.819a1.157 1.157 0 0 0 1.638 0l6.627-6.627a5.21 5.21 0 0 0 5.928-1.019z"
      stroke="#FFF"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Tools;
