import React from 'react';
import { DoubleTriangle } from 'components/bookmark-panel/DoubleTriangle';
import useDropdown from 'hooks/useDropdown';
import { PreviewTab, StyledTabButtons } from './PreviewTab';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function PreviewTabOverflow({ tabs }) {
  const [
    moreTabsOpen,
    toggleMoreTabs,
    onBlur,
    moreTabsContainerRef,
    setDropdown,
  ] = useDropdown();

  React.useEffect(() => {
    if (tabs?.length === 0) setDropdown(false);
  }, [tabs, setDropdown]);

  return tabs?.length > 0 ? (
    <StyledOverflowButton ref={moreTabsContainerRef} onBlur={onBlur}>
      <StyledTabButtons>
        <button
          onClick={toggleMoreTabs}
          aria-expanded={moreTabsOpen}
          aria-haspopup="true"
        >
          <DoubleTriangle color="#fff" />
          &amp; {tabs?.length} more...
        </button>
        {moreTabsOpen && (
          <StyledOverflowMenu onBlur={onBlur}>
            {tabs.map(tab => {
              return <PreviewTab key={tab.uri} tab={tab} />;
            })}
          </StyledOverflowMenu>
        )}
      </StyledTabButtons>
    </StyledOverflowButton>
  ) : null;
}

export const StyledOverflowMenu = styled.ol`
  bottom: 95px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 0;
  z-index: 4;
  &:before {
    bottom: -40px;
    border: 0px solid #fafafa;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-width: 20px 16px;
    content: '';
    display: block;
    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.12));
    height: 0;
    right: 170px;
    position: absolute;
    width: 0;
  }
`;

export const StyledOverflowButton = styled.li`
  margin-left: -190px !important;
  max-width: 190px;
  position: relative;
  right: -190px;
  width: 190px;
  &:before,
  &:after {
    display: none !important;
  }
  > div {
    overflow: visible;
    background: ${CEB_COLOR('BLACK')};
    > button {
      color: white;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      padding-left: 14px;
      width: 100%;
      .triangles {
        margin-right: 8px;
        vertical-align: -1px;
      }
    }
  }
  li {
    white-space: nowrap;
    width: 27vw;
    > div {
      box-shadow: none;
    }
    + li > div {
      border-top: none;
    }
    &:last-child {
      > div {
        border-bottom: none;
      }
    }
  }
`;
