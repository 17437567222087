import React from 'react';

const StopWatchIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="32"
    viewBox="0 0 27 32"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path
        d="M24.375 17.5c0 6.559-5.317 11.875-11.875 11.875C5.94 29.375.625 24.059.625 17.5S5.94 5.625 12.5 5.625c6.558 0 11.875 5.316 11.875 11.875h0zM8.75.625L16.25.625M20.896 9.104L23.125 6.875"
        transform="translate(1 1)"
      />
      <path
        d="M10.625 5.684L10.625.625 14.375.625 14.375 5.684M22.5 6.25L23.75 7.5M12.5 17.5L6.875 11.875"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default StopWatchIcon;
