import React from 'react';
import { pick } from 'lodash/fp';
import dayjs from 'dayjs';
import { sectionTitle } from 'components/statutes/codes/CodeHeading';

export function useTabButtonLabel(data, tab) {
  const { title, subtitle } = React.useMemo(() => {
    if (data?.case) {
      return {
        title: data.case.name,
        subtitle: `(${dayjs(data.case.date).year()})`,
      };
    }

    if (data?.code && !data?.caConstitutionHeading) {
      if (data.codeSection && !data.codeHeading) {
        return {
          title: data.code.name,
          subtitle: data.codeSection.abbreviatedSectionTitle,
        };
      }

      if (data.codeSection && data.codeHeading) {
        return {
          title: data.code.name,
          subtitle:
            data.codeHeading.sectionBegin && data.codeHeading.sectionEnd
              ? `§ ${data.codeHeading.sectionBegin}-${
                  data.codeHeading.sectionEnd
                }`
              : '',
        };
      }

      if (data.statute) {
        return {
          title: data.code.name,
        };
      }
    }

    if (data?.caConstitutionHeading) {
      return {
        title: data.caConstitutionHeading.title,
        subtitle: sectionTitle(
          pick(['sectionBegin', 'sectionEnd'], data.caConstitutionHeading),
        ),
      };
    }

    if (data?.caConstitutionSection) {
      return {
        title: data.caConstitutionSection.title.split(',')[0],
        subtitle: data.caConstitutionSection.title.split(',')[1],
      };
    }

    return {
      title: tab.uri,
    };
  }, [data]);

  return {
    title,
    subtitle,
    toString: function() {
      return `${title}${subtitle ? ` ${subtitle}` : ''}`;
    },
  };
}
