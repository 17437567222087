import { GET_CONSTITUTION_SECTION } from 'components/statutes/constitution/ArticleSection';
import GET_CASE from 'graphql/getCase';
import GET_CODE from 'graphql/getCode';
import GET_CODE_HEADING from 'graphql/getCodeHeading';
import GET_CODE_SECTION from 'graphql/getCodeSection';
import GET_CONSTITUTION_HEADING from 'graphql/getConstitutionHeading';
import { omit } from 'lodash/fp';
import qs from 'util/qs';
import { getContentRoute } from './getContentRoute';

export function contentUriToQuery(uri) {
  const { location, match } = getContentRoute(uri);
  if (!match) return undefined;

  const { query: fromQuery } = qs.parse(location.search || '');
  let CONTENT_QUERY;
  const { params } = match;
  const variables = omit(['type', 'jurisdictionId'], params);

  switch (params.type) {
    case 'cases':
      CONTENT_QUERY = GET_CASE;
      variables.fromQuery = fromQuery;
      break;
    case 'statutes':
      if (params.codeJudicataId) {
        variables.jurisdictionId = params.jurisdictionId;
        if (params.judicataIdEnd) {
          CONTENT_QUERY = GET_CODE_HEADING;
        } else {
          if (params.judicataId) {
            CONTENT_QUERY = GET_CODE_SECTION;
          } else {
            CONTENT_QUERY = GET_CODE;
          }
        }
      }

      if (params.article) {
        if (params.judicataId) {
          CONTENT_QUERY = GET_CONSTITUTION_SECTION;
        } else {
          CONTENT_QUERY = GET_CONSTITUTION_HEADING;
        }
      }
      break;
    default:
  }

  return {
    CONTENT_QUERY,
    variables,
  };
}
