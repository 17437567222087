import React from 'react';
import expandToIndex from 'util/expandToIndex';
import { findSection } from './findSection';
import { TableOfContentsItem } from './TableOfContentsItem';
import { statuteURL } from 'util/statuteURL';
import { nextWithSections } from './nextWithSections';
import { getNextSection } from './getNextSection';
import { RedirectWithState } from 'components/routes/RedirectWithState';
import styled from 'styled-components';

export const TableOfContents = props => {
  if (props.judicataIdBegin === undefined) {
    const next = nextWithSections(props.sections);
    if (next) {
      const uri = statuteURL(
        props.jurisdictionId,
        props.codeJudicataId,
        next.sectionBegin,
        next.sectionEnd,
      );
      return <RedirectWithState to={uri} />;
    }
    return null;
  }

  const section = findSection(props.judicataIdBegin, props.judicataIdEnd)(
    props.sections,
  );
  const sectionIndex = section ? section.index : null;

  const expandedSections = expandToIndex(props.sections, sectionIndex);

  return (
    <StyledTableOfContents>
      {expandedSections.map((item, i) => (
        <TableOfContentsItem
          key={i}
          item={item}
          next={
            item.sectionBegin
              ? null
              : getNextSection(item.index, props.sections)
          }
          selected={item.index === sectionIndex}
          codeJudicataId={props.codeJudicataId}
          jurisdictionId={props.jurisdictionId}
        />
      ))}
    </StyledTableOfContents>
  );
};

const StyledTableOfContents = styled.ol`
  font-family: 'Zilla Slab', serif;
  list-style-type: none;
  padding: 0;
`;
