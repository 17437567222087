import React from 'react';
import styled from 'styled-components';

export const Information = () => (
  <StyledInformationList>
    <li>
      <a href="https://ceb.com/privacy-policy">Privacy Policy</a>
    </li>
    <li>
      <a href="https://ceb.com/terms-and-conditions">Terms &amp; Conditions</a>
    </li>
  </StyledInformationList>
);

const StyledInformationList = styled.ul`
  li {
    margin: 0.5em 0;
    &:first-child {
      margin-top: 0;
    }
  }
`;
