export function removeClosedTab(tab) {
  const recentlyClosedTabs = JSON.parse(
    sessionStorage.getItem('RECENTLY_CLOSED_TABS') || '[]',
  );
  sessionStorage.setItem(
    'RECENTLY_CLOSED_TABS',
    JSON.stringify([
      ...recentlyClosedTabs.filter(
        closedTab => JSON.stringify(closedTab) !== JSON.stringify(tab),
      ),
    ]),
  );
}

export function addClosedTab(tab) {
  const recentlyClosedTabs = JSON.parse(
    sessionStorage.getItem('RECENTLY_CLOSED_TABS') || '[]',
  );

  sessionStorage.setItem(
    'RECENTLY_CLOSED_TABS',
    JSON.stringify([
      ...recentlyClosedTabs.filter(
        closedTab => JSON.stringify(closedTab) !== JSON.stringify(tab),
      ),
      tab,
    ]),
  );
}
