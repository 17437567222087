import React, { useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import GET_USER from 'graphql/getUser';
import { Tooltip } from 'components/common/Tooltip';
import { ScrollToEnd } from './ScrollToEnd';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { AppContext } from 'components/app-context';

export const NewTandC = ({ page, termsDate }) => {
  const context = useContext(AppContext);
  const [scrolledToBottom, setScrolledToBottom] = React.useState(false);

  const [acceptMutation] = useMutation(
    gql`
      mutation acceptTerms($termsDate: IsoDate) {
        updateUser(termsConditionsLastAcceptance: $termsDate) {
          termsConditionsLastAcceptance
        }
      }
    `,
  );

  const onAccept = React.useCallback(async () => {
    const { data } = await acceptMutation({
      refetchQueries: [{ query: GET_USER }],
      variables: { termsDate },
    });

    if (data?.updateUser?.termsConditionsLastAcceptance) {
      context.setShowTerms(false);
    }
  }, [acceptMutation, termsDate, context]);

  return (
    <StyledTandCContainer className="container">
      <h2>Please accept the CEB Terms &amp; Conditions</h2>
      <p>
        Our Terms &amp; Conditions have recently changed. Please scroll to
        review the updated text below and then click "Accept" to continue.
      </p>
      <ScrollToEnd page={page} setScrolledToBottom={setScrolledToBottom} />
      <p>
        By clicking the "Accept" button, you agree to CEB's{' '}
        <a
          href="https://www.ceb.com/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms &amp; Conditions
        </a>{' '}
        and{' '}
        <a
          href="https://www.ceb.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <div className="button-row">
        {!scrolledToBottom ? (
          <Tooltip title="Please scroll to end of Terms &amp; Conditions before accepting">
            <button disabled>Accept</button>
          </Tooltip>
        ) : (
          <button onClick={onAccept}>Accept</button>
        )}
        <a href="https://www.ceb.com">I do not accept</a>.
      </div>
    </StyledTandCContainer>
  );
};

const StyledTandCContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
  max-height: 100%;
  .tooltip-container .tooltip {
    min-width: 150% !important;
    bottom: 70% !important;
    margin-left: 0 !important;
    padding: 0 10px !important;
  }

  .button-row .tooltip-target {
    display: inline-block;
    margin-right: 24px;
    button {
      margin-right: 0;
    }
  }

  h2 {
    font-size: 22px;
    border-bottom: 2px solid ${CEB_COLOR('MERCURY')};
    padding-bottom: 20px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;
    a {
      text-decoration: underline;
    }
  }

  button {
    background: ${CEB_COLOR('DANUBE')};
    border-radius: 3px;
    color: white;
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-right: 24px;
    padding: 12px 24px;
    &:disabled {
      background: ${CEB_COLOR('BLUEY_GRAY')};
      cursor: default;
    }
  }

  .button-row {
    a {
      font-family: 'BasisGrotesque', sans-serif;
    }
  }
`;
