import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { useBookmarkVariables } from 'hooks/useBookmark';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function BookmarkHeader() {
  const bookmarkVariables = useBookmarkVariables();

  return (
    <StyledBookmarkHeader>
      <h2>Bookmark current page?</h2>
      <Link to={bookmarkVariables.path}>{bookmarkVariables.title}</Link>
    </StyledBookmarkHeader>
  );
}

const StyledBookmarkHeader = styled.header`
  font-family: 'Zilla Slab', serif;
  h2 {
    font-size: 19px;
    margin-bottom: 6px;
  }
  a {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  + label {
    border: 1px solid ${CEB_COLOR('QUILL_GRAY')};
    display: inline-block;
    font-family: 'Zilla Slab', serif;
    font-size: 17px;
    margin-bottom: 20px;
    padding: 15px 10px;
    span {
      display: block;
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    div {
      margin-bottom: 0;
    }
    input {
      font-family: 'Zilla Slab', serif;
      font-size: 14px;
      font-weight: bold;
      padding: 6px 8px 5px 8px;
      &:placeholder {
        font-weight: normal;
      }
    }
  }
`;
