import React from 'react';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';

export const CaseMeta = ({ cite, date, courtName, fontSize = 15 }) => (
  <StyledCaseMeta fontSize={fontSize}>
    <dt>Cite:</dt>
    <dd>{cite}</dd>
    <dt>Date:</dt>
    <dd>{date}</dd>
    <dt>Court:</dt>
    <dd>{courtName}</dd>
  </StyledCaseMeta>
);

const StyledCaseMeta = styled.dl.attrs(({ fontSize }) => ({ fontSize }))`
  font-size: ${({ fontSize }) => fontSize}px;
  margin-bottom: 8px;
  dt,
  dd {
    margin-right: 1em;
    margin-bottom: 1em;
  }
  dt {
    display: none;
  }

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    dd {
      display: inline;
      margin-right: 0;
      &:after {
        font-size: 0.33em;
        content: '●';
        display: inline-block;
        margin: 0 2em;
        vertical-align: 0.5em;
      }
      &:last-child:after {
        content: '';
        display: none;
      }
    }
  }
`;
