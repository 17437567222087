import React from 'react';
import colorForAttitude from 'util/colorForAttitude';
import { caseCitatorUrl } from 'util/caseUrl';
import { TrackedLink } from 'components/common/TrackedLink';
import styled from 'styled-components';

export const CitingCasesLink = ({
  case: { judicataId, citationCount = 0, treatmentAttitudeName },
}) => {
  const color = colorForAttitude(treatmentAttitudeName);
  const citatorUrl = caseCitatorUrl(judicataId);
  return (
    <StyledCitatorLink to={citatorUrl} className="citator-link" color={color}>
      {citationCount} citing cases
    </StyledCitatorLink>
  );
};

const StyledCitatorLink = styled(TrackedLink).attrs(({ color }) => ({ color }))`
  color: ${({ color }) => color};
  display: block;
  font-family: 'Zilla Slab', serif;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 4px 0 0 4px;
  white-space: nowrap;
`;
