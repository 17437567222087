import { gql } from '@apollo/client';

const Statute = gql`
  fragment Statute on Statute {
    name
    codes {
      name
      judicataId
    }
  }
`;

const Code = gql`
  fragment Code on Code {
    name
    judicataId
    tableOfContents {
      title
      depth
      index
      sectionBegin
      sectionEnd
    }
  }
`;

const CodeSection = gql`
  fragment CodeSection on CodeSection {
    title
    abbreviatedSectionTitle
    content
    history
    citationCount
    californiaCitationFormat: citationTemplate(format: CALIFORNIA) {
      format
      text
      typeName
    }
    standardCitationFormat: citationTemplate(format: CALIFORNIA) {
      format
      text
      typeName
    }
    parentTableOfContents {
      title
      depth
      index
      sectionBegin
      sectionEnd
    }
    judicataNumericId
    searchHighlightTerms
  }
`;

const CodeHeading = gql`
  fragment CodeHeading on CodeHeading {
    title
    sectionBegin
    sectionEnd
    codeSections {
      abbreviatedSectionTitle
      content
      history
    }
  }
`;

const CAConstitution = gql`
  fragment CAConstitution on Code {
    name
    judicataId
    tableOfContents {
      title
      depth
      index
      sectionBegin
      sectionEnd
      specifier # if this is null, it's the preamble, which we handle specially
    }
  }
`;

const StatuteFragments = {
  Statute,
  Code,
  CodeSection,
  CodeHeading,
  CAConstitution,
};

export default StatuteFragments;
