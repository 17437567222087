import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import styled from 'styled-components';

export default function NotFound({ staticContext }) {
  if (staticContext) staticContext.status = 404;
  return (
    <StyledNotFound>
      <div>
        <header>
          <h1>404</h1>
          <h2>Page not found</h2>
        </header>
        <div>
          <p>
            We're sorry but the page you are looking for does not exist on our
            server.
          </p>
          <p>
            <Link to="/">Return home</Link>
          </p>
        </div>
      </div>
    </StyledNotFound>
  );
}

const StyledNotFound = styled.section`
  flex: 1 1 auto;

  div {
    background: white;
    max-width: 960px;
    margin: 0 auto;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
    font-family: 'BasisGrotesque', sans-serif;
    header {
      padding: 90px 140px 40px;
    }
    h1 {
      color: #99a2b8;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 178px;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 32px;
    }
    h2 {
      font-weight: 900;
      font-size: 72px;
    }
    div {
      background-color: #fafafb;
      box-shadow: none;
      padding: 68px 140px 72px;
    }
    p {
      font-size: 18px;
    }
  }
`;
