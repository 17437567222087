import React from 'react';
import { TabContext } from 'components/app-context/TabContextProvider';
import { CaseHeader } from 'components/cases/case/CaseHeader';
import { LoadingMessage } from 'components/common/LoadingMessage';
import {
  ContentRendererContext,
  TabbableLocationContext,
} from './PreviewPanel';
import { useLocation } from 'react-router';
import {
  StyledPreviewPanelHeader,
  StyledStatutesHeader,
  StatutePreviewHeader,
  ConstitutionPreviewHeader,
} from './StatutePreviewHeader';

export function PreviewPanelHeader() {
  const { data, loading } = React.useContext(ContentRendererContext);
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);

  const { dispatch } = React.useContext(TabContext);

  const {
    state: { tabState },
  } = useLocation();

  const { current: tab } = tabState;

  return (
    <StyledPreviewPanelHeader>
      {loading ? (
        <LoadingMessage />
      ) : (
        <>
          {data?.case && <CaseHeader case={data.case} hideActions />}
          {(data?.code || data?.caConstitutionSection) && (
            <StyledStatutesHeader>
              {!params.article && (
                <StatutePreviewHeader params={params} data={data} />
              )}
              {params.article && <ConstitutionPreviewHeader data={data} />}
            </StyledStatutesHeader>
          )}
        </>
      )}
      <header>
        <a href={tab.uri} target="_blank" rel="noopener noreferrer">
          <i className="fas fa-external-link-alt" />
        </a>
        <button onClick={() => dispatch({ type: 'MINIMIZE_TAB', tab })}>
          <i className="fas fa-compress-alt" />
        </button>
        <button onClick={() => dispatch({ type: 'REMOVE_TAB', tab })}>
          <i className="fas fa-times" />
        </button>
      </header>
    </StyledPreviewPanelHeader>
  );
}
