import React from 'react';
import { constURL } from 'util/statuteURL';
import {
  StyledConstitutionArticleSectionSidebar,
  StyledStyledConstitutionArticleSectionContent,
} from 'components/statutes/constitution/ArticleSection';
import { useArticleSectionData } from 'components/statutes/constitution/useArticleSectionData';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { WithSidebar } from 'components/layouts/WithSidebar';
import toElement from 'util/toElement';
import { WithFooter } from 'components/layouts/WithFooter';
import { ArticleHeader } from 'components/statutes/constitution/ArticleHeader';
import { SectionContent } from 'components/statutes/constitution/SectionContent';
import {
  ContentRendererContext,
  TabbableLocationContext,
} from '../preview-tabs/PreviewPanel';
import { HISTORY_MAP, HISTORY_TYPE_MAP } from 'components/history-panel';
import useHistory from 'hooks/useHistory';
import { useLocation } from 'react-router';

export function ArticleSectionPreview() {
  const { data } = React.useContext(ContentRendererContext);
  const {
    match: { params },
  } = React.useContext(TabbableLocationContext);
  const { location: referrer } = useLocation();

  const { mutateHistory, recentHistoryLoading } = useHistory(referrer);

  const { historyTitle } = useArticleSectionData(data);

  React.useEffect(() => {
    if (!historyTitle || recentHistoryLoading) return;

    mutateHistory({
      variables: {
        text: historyTitle,
        type: HISTORY_TYPE_MAP.STATUTE,
        action: 'VIEW',
        subtype: HISTORY_MAP.VIEW_CONSTITUTION,
      },
    });
  }, [historyTitle, recentHistoryLoading]);

  return (
    <WithSidebar collapsible defaultCollapsed>
      <StyledConstitutionArticleSectionSidebar>
        <header>
          <h4>
            <button>
              <i className={'fas fa-list-ul'} />
            </button>
            <Link to={constURL()}>California Constitution</Link>
          </h4>
          <h3>
            <Link to={constURL(params.article)}>
              {data.caConstitutionSection.parentTableOfContents[1].title}
            </Link>
          </h3>
        </header>
        <ol>
          <li>{data.caConstitutionSection.title.split(',')[1]}</li>
        </ol>
      </StyledConstitutionArticleSectionSidebar>
      <WithFooter className="article-section-content" flex>
        <StyledStyledConstitutionArticleSectionContent>
          <ArticleHeader
            title={data.caConstitutionSection.parentTableOfContents[1].title}
            subtitle={data.caConstitutionSection.title.split(',')[1]}
          />
          <SectionContent
            article={params.article}
            section={{
              title: undefined,
              element: toElement(data.caConstitutionSection.content),
              history: data.caConstitutionSection.history,
            }}
          />
        </StyledStyledConstitutionArticleSectionContent>
      </WithFooter>
    </WithSidebar>
  );
}
