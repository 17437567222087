import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export const PillButton = ({
  children,
  meta,
  color,
  innerBorderColor,
  ...props
}) => (
  <StyledPillButton {...props} color={color}>
    {children}
    <span>{meta}</span>
    <i className="fas fa-caret-down" />
  </StyledPillButton>
);

export const StyledPillButton = styled.button.attrs(({ color }) => ({ color }))`
  background-color: ${({ color }) => color};
  border-radius: 1.6em;
  border: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: inherit;
  font-weight: bold;
  letter-spacing: 0.02em;
  padding: 0.75em 0.7em 0.55em;
  padding-right: 2.4em;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  &:visited,
  &:active {
    color: white;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px ${CEB_COLOR_RGBA('DANUBE', 0.6)};
  }
  > span {
    color: rgba(255, 255, 255, 0.6);
    margin-left: 0.25em;
    display: inline-block;
  }
  > i {
    position: absolute;
    right: 0.75em;
    top: 0;
    height: 1.7em;
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    padding-left: 0.4em;
    padding-top: 0.6em;
  }
`;
