import { gql } from '@apollo/client';

import StatuteFragments from 'graphql/fragments/statutes';

const GET_CODE = gql`
  query GetCode($jurisdictionId: String!, $codeJudicataId: String!) {
    statute(jurisdictionId: $jurisdictionId) {
      ...Statute
    }
    code(jurisdictionId: $jurisdictionId, judicataId: $codeJudicataId) {
      ...Code
    }
  }
  ${StatuteFragments.Statute}
  ${StatuteFragments.Code}
`;

export default GET_CODE;
