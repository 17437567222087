import React from 'react';
import PropTypes from 'prop-types';
import useContentScroll from 'hooks/useContentScroll';
import { CaseContentDocument } from './CaseContentDocument';
import { NavOutline } from 'components/common/nav-outline';
import { WithSidebar } from 'components/layouts/WithSidebar';

export const CaseContent = props => {
  const [sections, setSections] = React.useState([]);
  const [onScroll, currentSectionIndex] = useContentScroll(sections, 0, -64);

  return (
    <WithSidebar collapsible defaultCollapsed={props.defaultCollapsed}>
      <NavOutline
        className="case-outline"
        sections={sections}
        current={currentSectionIndex}
        icon="list-ul"
        title="Case Outline"
      />
      <CaseContentDocument
        onUpdateSections={setSections}
        onScroll={onScroll}
        case={props.case}
        query={props.query}
      />
    </WithSidebar>
  );
};

CaseContent.propTypes = {
  case: PropTypes.object,
  hash: PropTypes.string,
  query: PropTypes.string,
};
