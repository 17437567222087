import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import PropTypes from 'prop-types';
import { Section } from './Section';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';
import { SidebarContext } from 'components/layouts/WithSidebar';
import { RestorableScroll } from 'components/layouts/RestorableScroll';

export const NavOutline = ({
  sections,
  current,
  title,
  icon,
  href,
  onScrollTo,
}) => {
  const { toggleSidebar } = React.useContext(SidebarContext);
  return (
    <StyledNavOutline>
      <header>
        <h3>
          {icon ? (
            <button onClick={toggleSidebar}>
              <i className={`fas fa-${icon}`} />
            </button>
          ) : null}
          {href ? <Link to={href}>{title}</Link> : title}
        </h3>
      </header>
      <RestorableScroll as="ol" tabIndex={0} aria-label={title}>
        {sections.map((props, i) => (
          <Section
            {...props}
            key={i}
            current={current === i}
            onScrollTo={onScrollTo}
          />
        ))}
      </RestorableScroll>
    </StyledNavOutline>
  );
};

const StyledNavOutline = styled.div`
  background-color: ${CEB_COLOR('CARARRA')};
  border-right: solid 1px ${CEB_COLOR('QUILL_GRAY')};

  @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 360px;
    max-width: 360px;
    height: 100%;

    > header {
      flex: 0 1 auto;
    }
    > ol {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  > header {
    padding: 16px 22px 12px;
    h3 {
      font-family: 'Zilla Slab', serif;
      font-size: 19px;
      button {
        font-size: 18px;
        margin-right: 0.5em;
      }
    }
  }

  > ol {
    list-style: none;
    margin: 0;
    overflow: auto;
    outline: 0;
    ol {
      list-style: none;
      margin: 0;
    }
    li {
      margin: 0;
      padding: 0;
    }
  }
  a,
  a:visited {
    color: inherit;
  }
  @media only print {
    display: none;
  }
`;

NavOutline.propTypes = {
  sections: PropTypes.array,
  current: PropTypes.number,
  icon: PropTypes.string,
  title: PropTypes.string,
};
