import React from 'react';
import { useLocation } from 'react-router-dom';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import get from 'lodash/fp/get';
import { CitingCasesLink } from './CitingCasesLink';
import { CaseMeta } from './CaseMeta';
import { CasePillButton } from '../case-pill-button';
import { useAssetAccess } from 'components/asset-access';
import { HeaderActions } from 'components/common/HeaderActions';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import caseUrl from 'util/caseUrl';

export const CaseHeader = ({ hideActions, ...props }) => {
  const { hasCitatorAccess } = useAssetAccess();
  const location = useLocation();

  const scrollContent = React.useCallback(() => {
    const content = document.querySelector('.case-content-with-footer');
    if (content) {
      content.scrollTop = 0;
    }
  });

  return (
    <StyledCaseHeader>
      {hasCitatorAccess ? (
        <div className="case-dropdown-container">
          <CasePillButton {...props} />
          {!props.hideCitingLink ? <CitingCasesLink {...props} /> : null}
        </div>
      ) : null}
      <div className="case-name-container">
        <Link
          to={
            props.hideCitingLink
              ? '.'
              : caseUrl(props.case.judicataId) + location.search
          }
          onClick={scrollContent}
        >
          <h1>{get('name')(props.case)}</h1>
        </Link>
        <CaseMeta {...props.case} fontSize={16} />
      </div>
      {hideActions ? null : <HeaderActions />}
    </StyledCaseHeader>
  );
};

const StyledCaseHeader = styled.header`
  background-color: ${CEB_COLOR('DESERT_STORM')};
  border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 14px 24px 12px;
  display: flex;
  flex: 0 0 auto;
  @media only print {
    display: none;
  }
  .case-name-container {
    flex: 1 1 auto;
  }

  .case-dropdown-container {
    flex: 0 1 auto;
  }

  .case-dropdown-container {
    padding-top: 4px;
    font-size: 13px;
    padding-right: 13px;
    position: relative;
  }

  h1 {
    color: ${CEB_COLOR('BLACK')};
    font-family: 'Zilla Slab', serif;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.36;
    margin-bottom: 2px;
  }
`;
