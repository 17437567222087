import { gql } from '@apollo/client';
import StatuteFragments from './fragments/statutes';

const GET_CODE_HEADING = gql`
  query GetCodeHeading(
    $jurisdictionId: String!
    $codeJudicataId: String!
    $judicataIdBegin: String!
    $judicataIdEnd: String!
  ) {
    code(jurisdictionId: $jurisdictionId, judicataId: $codeJudicataId) {
      ...Code
    }
    codeSection(
      jurisdictionId: $jurisdictionId
      codeJudicataId: $codeJudicataId
      judicataId: $judicataIdBegin
    ) {
      ...CodeSection
    }
    codeHeading(
      jurisdictionId: $jurisdictionId
      codeJudicataId: $codeJudicataId
      judicataIdBegin: $judicataIdBegin
      judicataIdEnd: $judicataIdEnd
    ) {
      ...CodeHeading
    }
  }
  ${StatuteFragments.Code}
  ${StatuteFragments.CodeSection}
  ${StatuteFragments.CodeHeading}
`;

export default GET_CODE_HEADING;
