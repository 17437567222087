import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { IntercomProvider } from 'react-use-intercom';
import Loading from 'components/common/Loading';
import { BrowserRouter as Router } from 'react-router-dom';
import { PageTracking } from './PageTracking';
import ScrollToTop from './ScrollToTop';
import AppContextProvider from 'components/app-context';
import Routes from 'components/routes';
import TermsAndConditions from './terms-and-conditions';
import { HelmetProvider } from 'react-helmet-async';
import { StyledGlobalApp } from './StyledGlobalApp';
import { CEB_PORTAL_INTERCOM_APP_ID } from 'app-constants';
import UpdateIntercomUser from './UpdateIntercomUser';
import { ScrollCacheProvider } from './ScrollCache';
import { RecoilRoot } from 'recoil';

export const App = ({ client }) => (
  <RecoilRoot>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <IntercomProvider
          shouldInitialize={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
          appId={CEB_PORTAL_INTERCOM_APP_ID}
          autoBoot={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
          autoBootProps={{ hideDefaultLauncher: true }}
        >
          <Loading>
            <Router>
              <ScrollToTop />
              <AppContextProvider>
                <UpdateIntercomUser />
                <ScrollCacheProvider>
                  <PageTracking />
                  <Loading>
                    <Routes />
                    <TermsAndConditions />
                  </Loading>
                </ScrollCacheProvider>
              </AppContextProvider>
            </Router>
          </Loading>
        </IntercomProvider>
        <StyledGlobalApp />
      </ApolloProvider>
    </HelmetProvider>
  </RecoilRoot>
);
