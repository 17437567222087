import React from 'react';
import { ContentfulContent } from 'components/common/ContentfulContent';
import styled from 'styled-components';

export const ScrollToEnd = props => {
  const scrollPanel = React.useRef();
  const contentPanel = React.useRef();
  const onScroll = React.useCallback(e => {
    if (
      contentPanel.current.offsetHeight - scrollPanel.current.scrollTop <
      scrollPanel.current.offsetHeight + 10
    ) {
      props.setScrolledToBottom(true);
    }
  });
  return (
    <StyledScrollToEnd
      className="scrollPanel"
      onScroll={onScroll}
      ref={scrollPanel}
    >
      <div className="content" ref={contentPanel}>
        <h3>{props.page.title}</h3>
        <ContentfulContent content={props.page.content} />
      </div>
    </StyledScrollToEnd>
  );
};

const StyledScrollToEnd = styled.div`
  &.scrollPanel {
    max-height: 350px;
    border: 1px solid lightgray;
    overflow: auto;
    margin-bottom: 22px;
  }
  .content {
    padding: 22px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  p {
    margin: 12px 0;
  }
  ol {
    margin-left: 1.5em;
  }
`;
