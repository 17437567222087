import React from 'react';
import { useSuspendableQuery } from 'hooks/useSuspendableQuery';
import { WithSidebar } from 'components/layouts/WithSidebar';
import Loading from 'components/common/Loading';
import { BookmarkPage } from './BookmarkPage';
import GET_BOOKMARK_FOLDERS from './graphql/getBookmarkFolders';
import { BookmarkFolder } from './BookmarkFolder';
import { FolderSelect } from './FolderSelect';
import styled from 'styled-components';
import colorForAttitude from 'util/colorForAttitude';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyleConstant } from 'util/getStyleConstant';

export const BookmarkPanelContext = React.createContext();

function Bookmarks() {
  const { folderId, setFolderId } = React.useContext(BookmarkPanelContext);

  const {
    data: { bookmarkFolders },
  } = useSuspendableQuery(GET_BOOKMARK_FOLDERS);
  const [filtered, setFiltered] = React.useState([]);

  React.useEffect(() => {
    if (folderId === undefined && bookmarkFolders?.length) {
      setFolderId(bookmarkFolders[bookmarkFolders.length - 1]?.id);
    }
  }, [bookmarkFolders]);

  return (
    <StyledBookmarksWithSidebar>
      <StyledBookmarkPanelSidebar>
        <header>
          <i className="fa fa-bookmark" /> Bookmarks
        </header>
        {bookmarkFolders.length > 0 && (
          <>
            <FolderSelect
              value={folderId}
              folders={bookmarkFolders}
              onChange={setFolderId}
              contenteditable
            />

            {folderId && (
              <BookmarkFolder folderId={folderId} onChange={setFiltered} />
            )}
          </>
        )}
      </StyledBookmarkPanelSidebar>
      <StyledBookmarkPanelContent className="content">
        <BookmarkPage
          folderId={folderId}
          folders={bookmarkFolders}
          bookmarks={filtered}
        />
      </StyledBookmarkPanelContent>
    </StyledBookmarksWithSidebar>
  );
}

const StyledBookmarksWithSidebar = styled(WithSidebar)`
  .sidebar {
    overflow: visible;
  }
`;

const StyledBookmarkPanelSidebar = styled.div`
  background-color: #29364f;
  box-sizing: border-box;
  color: white;
  padding: 12px;
  position: relative;
  text-align: left;
  @media only screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    width: 200px;
  }

  header {
    i {
      color: ${colorForAttitude('CAUTION')};
      margin-right: 3px;
    }
    font-weight: bold;
  }
`;

const StyledBookmarkPanelContent = styled.div`
  box-sizing: border-box;
  color: black;
  padding: 24px 26px;
  text-align: left;
  white-space: normal;
  @media only screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    width: 478px;
  }
`;

export function BookmarkPanel({ close }) {
  const bookmarkPanel = React.useRef();
  const initialRender = React.useRef(true);
  const mostRecentBookmarkFolderId = sessionStorage.getItem(
    'mostRecentBookmarkFolderId',
  );

  const [folderId, setFolderId] = React.useState(
    mostRecentBookmarkFolderId && mostRecentBookmarkFolderId !== 'undefined'
      ? mostRecentBookmarkFolderId
      : undefined,
  );

  const clickListener = React.useCallback(
    e => {
      const isDescendent = bookmarkPanel.current.contains(e.target);
      if (e.target && !isDescendent && close && !initialRender.current)
        close(e);
      initialRender.current = false;
    },
    [close],
  );

  React.useLayoutEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  React.useEffect(() => {
    if (folderId !== undefined)
      sessionStorage.setItem('mostRecentBookmarkFolderId', folderId);
  }, [folderId]);

  return (
    <BookmarkPanelContext.Provider
      value={{
        close,
        setFolderId,
        folderId,
      }}
    >
      <StyledBookmarkPanel className="panel" ref={bookmarkPanel}>
        <Loading>
          <Bookmarks />
        </Loading>
      </StyledBookmarkPanel>
    </BookmarkPanelContext.Provider>
  );
}

const StyledBookmarkPanel = styled.div`
  background-color: white;
  border-radius: 5px;
  border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 14px;
  max-height: 50vh;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 51px;
  z-index: 100;
  @media only screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
    height: 480px;
    width: 680px;
  }
`;
