import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';

const mapWithIndex = map.convert({ cap: false });

export const getCurrentSection = ({ scrollTop, topOffset = 64 }) =>
  flow(
    mapWithIndex((section, index) => {
      const scrolledTo = scrollTop - section.element.offsetTop >= -topOffset;
      const scrolledSection = {
        ...section,
        index,
        scrolledTo,
      };
      return scrolledSection;
    }),
    filter('scrolledTo'),
    last,
  );
